// Edit Journey
.EditJourney {
  width: 410px;

  &-timeSettings {
    display: flex;
    align-items: center;
  }

  &-expiryTime {
    width: 25%;
  }

  &-timeUnit {
    width: 72%;
    margin-left: auto;
  }

  &-timeSettings .t-timeUnit {
    padding-top: 28px;
  }

  &-description {
    font-family: Roboto;
    font-size: 12px;
    letter-spacing: 0.6px;
    color: #888888;
    margin-top: 0;
    margin-bottom: 20px;
  }
}

// Journey view
@import 'src/client/components/src/Common/variables.scss';

.JourneyView {
  position: absolute;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  left: 0;

  &-statistics {
    margin-left: 54px;
    display: flex;
    flex-direction: column;

    &--chart-container {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      width: 100%;
    }
  }

  &-page {
    display: flex;
    height: 100%;
    padding: 0 !important;
  }

  &-journeyPane {
    flex: 1;
    display: flex;
    flex-direction: column;
    background: var($colors, background);
  }

  &-stepStatisticsHeader {
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 16px;
  }

  &-statisticsSpinnerContainer {
    height: 420px;
    display: flex;
    align-items: center;
  }
}

.highcharts-tooltip.JourneyView-stepStatisticsChart {
  margin-top: 22px;
}

.StatisticsHeader {
  &-name {
    font-weight: bold;
    color: #715ed0;
    margin-left: 8px;
  }

  &-goalName {
    font-weight: bold;
    color: #ffa638;
    margin-left: 12px;
  }

  &-totalProfiles {
    color: #0052b6;
    margin-left: 12px;
    margin-right: 16px;
  }

  &-expiredProfiles {
    color: #b7000a;
    margin-left: 12px;
  }

  &-conversions {
    margin-top: 16px;
    margin-left: 28px;

    &--pill {
      font-size: 12px;
      line-height: 24px;
      border-radius: 16px;
      padding: 0 8px;
      margin-right: 16px;
    }
  }

  &-resultNumber {
    font-size: 16px;
    font-weight: 500;
  }
}

.JourneyModal {
  width: 560px;

  &-validationContent {
    max-height: 480px;
    overflow: auto;
  }
}

.StepStat-container {
  max-width: 960px;
}

.StepStat-pageTitle {
  margin-left: 54px;
}

.DeleteModal {
  p {
    margin: 0.5rem 0;
  }
  &-list {
    list-style: revert !important;
    margin: 0 8px;
    padding: 8px 16px;

    &--header {
      padding-top: 0.5rem;
      font-weight: 500;
    }
  }
}

.TagsTemplates {
  min-height: 100%;

  &-right {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    .Page {
      flex: 1;
    }
  }
}

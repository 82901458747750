@import 'variables.less';

ul.dynatree-container a:hover
{
	background-color: #ddd;
	color: #fff;
	border: none;
}

.green-icon {
    color: @r42-themeColor;
}

.group-folders {
    .nav.nav-stacked {
        .group-selections {
            list-style: none;
        }
        a {
            padding-left: 10px;
        }
        li.selections {
            a{
                padding-left: 13px;
            }
        }
        li {
            a {
                padding: 2px 8px;
            }
            a.folder-full-group {
                span {
                    padding: 0px;
                    margin-left: -5px
                }
            }
            a.folder-empty-group {
                padding-left: 11px;
            }
            span {
                &:hover {
                    color: #000;
                    background: #FFF;
                    .r42-folder-full-closed.green-icon {
                        cursor: pointer;
                        color: @r42-themeSupportColor;
                    }
                }
            }
        }
    }
}

.RoundedLabel {
  display: inline-block;
  min-width: 40px;
  padding: 10px 0;
  line-height: 20px;
  border-radius: 100%;
  font-size: 14px;
  text-align: center;
  box-shadow: 0 0 5px 0 #c7c7c7;
  background: white;
}

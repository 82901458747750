.r42-ui {
	.errorList li {
		padding: 5px 5px;
		
		.errorInfo {
			
		}
		
		.errorContext {
			position: relative;
			
			.badge {
				position: absolute;
				right: 0;
			}
		}
		
		.errorDetail {
			padding: 10px;
			padding-left: 40px;
			
			tr {
				margin-bottom: 5px;
			}
		}
	}
}

@import 'src/client/components/src/Common/variables.scss';

.LeftPanel {
  position: relative;
  // width: 280px;
  // max-width: 480px;
  top: 0;
  left: 0;
  bottom: 0;
  box-shadow: 0 2px 4px 0 #cbd3e1;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  transition: width 300ms ease-in-out;

  @media (min-width: 1440px) {
    // width: 350px;
  }

  @media (min-width: 1600px) {
    // width: 360px;
    // max-width: 520px;
  }

  &.LeftPanel--expanded {
    // width: 520px;

    & .LeftPanel-togglePanelIconWrap {
      transform: rotate(180deg);
    }
  }

  & > button.LeftPanel-togglePanelButton {
    position: absolute;
    left: 100%;
    top: 28px;
    width: 24px;
    height: 24px;
    margin-left: -12px;
    border-radius: 50%;
    background: #fff;
    color: #0054b0;
    border-color: transparent;
    box-shadow: 1px 2px 5px 0 rgba(190, 190, 190, 0.5);
  }

  &-togglePanelIconWrap {
    display: flex;
    justify-content: center;
    transition: transform 300ms ease-in-out 300ms;
  }

  & > .Tree {
    flex: 1;
    height: auto;
    margin-left: -16px;
  }
}

/**
 FolderIcon Component
 */

.FolderIcon {
  float: left;
  width: 20px;
  margin-right: 7px;
  margin-top: 2px;
}

.FolderIcon--small {
  transform: scale(0.7);
  position: relative;
  left: 0;
  margin-bottom: 2px;
  margin-left: -3px;
}

.Table {
  .Tabs-panel > &:first-child,
  .Modal-body > &:first-child {
    border-top: none;
  }

  &-cell {
    padding: 0.5rem;
  }

  &-cell--header {
    font-weight: 500;
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;
  }

  &-cell--selection {
    width: 2.5rem;
  }
}

.Table-permission {
  width: 70px;
}

div.Table-cell {
  display: inline-flex;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
  white-space: normal;
  text-align: left;

  & .Link {
    display: inline;
  }
}

.EditableList {
  display: flex;
  flex-direction: column;
}

.EditableList-row {
  border-bottom: 0.5px solid #c5c5c5;
  min-height: 50px;
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.EditableList-headerRow {
  padding-right: 78px;
}

.EditableList-headerItem {
  font-weight: 500;
  color: #333333;
  flex: 1;
}

.EditableList-row--beingEdited {
  padding: 12px 0 12px 8px;
}

.EditableList-row--footer {
  font-weight: 300;
  text-align: left;
  border: none;
  color: #292929;
}

.EditableList-row--footer:hover {
  color: #0054b0;
  transition: 0.3s;
}

//overriding legacy css
.EditableList-error.EditableList-error {
  position: absolute;
  font-size: 1.1rem;
}

.EditableList-field {
  flex: 1;
}

.EditableList-field .FieldWrapper-errorOrHintContainer {
  display: none;
}

.PropertiesFormTable {
  margin-top: 48px;
  margin-bottom: 0 !important;
}

.Table--withStickyColumns th.Table-stickyCell,
td.Table-cell.Table-stickyCell {
  position: sticky;
  left: 0;
  background: #fff;
}

.Table-row:hover .Table-stickyCell {
  background-color: #fafafa;
}

.Table-row:hover .Table-cell--header.Table-stickyCell {
  background-color: #fff;
}

.browserTypeCondition {
	.browserVersion {
		.inline-block {
			display: inline-block;
		}
	}

	.browserVersion, .deviceType {
		margin-bottom: 10px;
	}

	.add-browser, .add-device {
		width: 220px;
	}

	.and-separator {
		margin-bottom: 20px;
		overflow: hidden;
		text-align: center;
	}

	.and-separator:before,
	.and-separator:after {
		background-color: #ccc;
		content: "";
		display: inline-block;
		height: 1px;
		position: relative;
		vertical-align: middle;
		width: 50%;
	}

	.and-separator:before {
		right: 0.5em;
		margin-left: -50%;
	}

	.and-separator:after {
		left: 0.5em;
		margin-right: -50%;
	}



}

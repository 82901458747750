.Block {
  height: 20px;
  width: 20px;
  background-color: #ff7b32;
  display: inline-block;
  border-radius: 2px;

  &--success {
    background-color: #3ac970;
  }

  &:not(:nth-child(1)) {
    margin-left: 2px;
  }
}

.inline-div {
  display: inline-block;
}

.tags-include-subpages {
  margin-left: 25px;
  margin-top: 10px;
}

.location {
  word-wrap: break-word;
}

.entry-link {
    font-weight: bold;
}

.svg-icon--rulesList {
    vertical-align: middle;
    width: 25px;
}

.and-separator {
    font-weight: bold;

}
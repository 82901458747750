@import 'src/client/components/src/Common/variables.scss';

.EventConnectorTransformationsPage-events {
  margin-top: 32px;
}

.EventConnectorTransformationsPage-addEventButtonContainer {
  margin-top: 32px;
}

.EventConnectorTransformationsPage-addEventButtonContainer .Button.EventConnectorTransformationsPage-addEventButton {
  background-color: #fff;
  border: 1px solid var($colors, link);
}

.EventConnectorTransformationsPage-transformerIsNotValid {
  margin-bottom: 0;
}

.EventConnectorTransformationsPage-eventPicker {
  padding: 16px;
}

.EventConnectorTransformationsPage-skipButton {
  float: right;
}

.EventConnectorTransformationsPage-deleteButtonContainer--hidden,
.value-mapping-delete-button--hidden {
  visibility: hidden;
}

@import 'src/client/components/src/Common/variables.scss';

.Heading {
  &-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
  }

  &-icon {
    vertical-align: middle;
  }

  &-titleContainer {
    margin-top: 8px;
  }

  &-link {
    font-size: 12px;
    font-weight: 400;
    color: #888888 !important;
    cursor: pointer;
    text-decoration: underline;
  }

  h1.Heading-title {
    font-size: 24px;
  }

  h2.Heading-title {
    font-size: 20px;
  }

  h3.Heading-title {
    font-size: 18px;
  }

  h4.Heading-title {
    font-size: 16px;
  }

  h5.Heading-title {
    font-size: 14px;
  }
}

/// For old angular pages

.Heading-title--Xl.Heading-title--Xl {
  font-size: var($fontSize, xl);
  font-weight: 500;
}

.Heading-title--L.Heading-title--L {
  font-size: var($fontSize, l);
  font-weight: 500;
}

.Heading-title--M.Heading-title--M {
  font-size: var($fontSize, m);
  font-weight: 500;
}

.Heading-title--S.Heading-title--S {
  font-size: var($fontSize, s);
  font-weight: 500;
}

.Heading-title--Xs.Heading-title--Xs {
  font-size: var($fontSize, xs);
  font-weight: 500;
}

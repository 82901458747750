@import 'src/client/components/src/Common/variables.scss';

.RulesList {
  width: calc(100% + 20px);
  margin-left: -20px;

  &--normalWidth {
    width: 100%;
    margin-left: 0;
  }

  &-conditionBadge {
    border-radius: 16px;
    box-shadow: 0 2px 6px 0 rgba(194, 194, 194, 0.5);
    background-color: var($colors, indicationBackground);
    font-weight: bold;
    padding: 5px 10px;
    display: flex;
    justify-content: space-around;
    z-index: 2;
    color: var($colors, indicationForeground);
    width: 50px;
    text-align: center;
    margin-bottom: 24px;
  }

  &-badgeContainer {
    height: 68px;
    position: relative;
    flex: 1;
    align-items: center;
    display: flex;
    z-index: 0;
  }

  &-badgeContainer--center {
    .RulesList-conditionBadge {
      margin: 0 auto;
    }

    .RulesList-separator {
      width: 100%;
      position: absolute;
      border-bottom: 2px solid var($colors, indicationForeground);
      top: 34px;
    }
  }

  &-badgeContainer--left {
    padding-left: 40px;
    height: 30px;
  }

  .RuleCardEdit-container {
    display: flex;
    position: relative;
  }

  .RuleCardEdit-picker {
    width: 100%;
  }

  .RuleCardEdit-line {
    width: 20px;
    display: flex;
    flex-direction: column;
  }

  .RuleCardEdit-line--first .RuleCardEdit-lineBottom {
    border-left: 2px solid var($colors, indicationForeground);
  }

  .RuleCardEdit-line--middle {
    border-left: 2px solid var($colors, indicationForeground);
  }

  .RuleCardEdit-ORBlock {
    height: 100%;
    border-left: 2px solid var($colors, indicationForeground);
  }

  .RuleCardEdit-line--middle .RuleCardEdit-AndBlock {
    border-top: 0;
    border-bottom: 0;
    border-left: 0;
  }

  .RuleCardEdit-line--onlyOneCard .RuleCardEdit-lineBottom {
    border-left: 0;
    border-top: 0;
  }

  .RuleCardEdit-line--last .RuleCardEdit-lineTop {
    border-left: 2px solid var($colors, indicationForeground);
  }

  .RuleCardEdit-lineTop {
    flex: 1;
  }

  .RuleCardEdit-lineBottom {
    flex: 1;
    border-top: 2px solid var($colors, indicationForeground);
  }
}

@media only screen and (max-width: 1024px) {
  .RulesList {
    max-width: 960px;
  }
}

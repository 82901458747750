.countrymatchcondition {
	.search-form > .search-countries.search-pane {
		border: 0px;
	}
	
	.countryList {
		margin-top: 20px;
		
		li .flag {
			vertical-align: middle;
			margin-right: 5px;
		}
	}
	
	.control-group-countries {
		min-height: 190px;
	}
	
	/*.suggestions {
		background: white;
		margin-top: -35px;
		margin-left: 330px;
		width: 390px;
		position: absolute;
	
		ul {
			height: 200px;
			overflow-y: auto;
		}
		
		li:first-child {
			background-color: rgb(245, 245, 245);
		}
	}*/
}

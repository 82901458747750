@import 'src/less/variables.less';

/* Prevent the text contents of draggable elements from being selectable. */

[draggable="true"] {
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    cursor: move;
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
}

.drag-start {
    opacity: 0.3;
}
.drag-end {
    opacity: 1;
}
.droppable-target {
    background-color: @r42-lightgrey-journey;
    height: 18px;
    margin: 10px 0 10px 0;
    border: 1px #fff solid;
    padding: 15px 0 15px 0;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    color: #666666;
    opacity: 0.8;

    &.extra-drop {
        display: none;
    }
}

.droppable-theme-green.droppable-target-active {
    color: white;
    border: 0;
    min-height: 40px;
    background-color: @r42-themeColor-opacity;
    margin: 5px;
    padding: 20px 0px 0px 0px;
    &.droppable-target-over {
        background-color: @r42-themeColor !important;
        height: 40px;
    }
}
.droppable-theme-gray.droppable-target-active, .droppable-target-active {
    background-color: rgba(195, 196, 197, 0.16);
    color: @r42-blue-higlight-opacity;
    width: 100%;
    &.droppable-target-over {
        background-color: @r42-lightgrey !important;
        color: white;
        border: 1.8px @r42-lightgrey dashed;
    }
    &.extra-drop {
        display: block;
    }
}

//Draggable dynamic element
.r42-draggable-styled {
    background: @r42-darkgrey;
    opacity: 0.8;
    cursor: move;
    padding: 15px;
    color: white;
}

.draggable-icon-border {
    border: 1px dashed;
    margin-right: 8px;
    margin-left: 8px;
}

.ConnectorCardGrid {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  overflow-y: auto;
  margin-right: -2px;
  padding-right: 2px;

  &-search {
    margin: 36px 0;
  }
}

$yellow: #ffa638;
$purple: #9f38ff;
$green: #36ba67;
$red: #d52c20;
$blue: #0054b0;
$lightBlue: #f6fbff;
$grey888: #888888;
$border: #eaeaea;
$lightgrey: #fafafa;
$white: #ffffff;

.Models {
  &-form1 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;

    @media screen and (min-width: 1200px) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &-rules {
    margin: 24px 0;
  }

  &-form2 {
    margin-top: 24px;
    margin-bottom: 48px;
    display: inline-flex;
    flex-direction: column;
    gap: 20px;
  }
  &-inputProps {
    display: flex;
    flex-wrap: wrap;

    &--field {
      flex: 0 1 auto;
      min-width: 100%;

      @media screen and (min-width: 1200px) {
        min-width: 50%;
      }
    }
  }
  &-additionalProperties {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    list-style-position: inside;

    @media screen and (min-width: 1200px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

.ModelActionPicker {
  width: 420px;
  height: auto;
}

.PredictionForm {
  &-actions {
    padding: 0;
    padding-top: 36px;
  }

  &-date .rdtPicker {
    position: fixed;
  }
}

.PredictionTablePanel {
  padding: 0;
  margin: 24px 0;
}

.ModelTablePanel {
  padding: 0;
}

.ModelTable {
  height: calc(100vh - 180px);
  overflow-y: scroll;
}

.PredictionTable,
.ModelTable {
  width: 100%;

  & > table {
    font-size: 14px;
    border-collapse: collapse;
    width: 100%;
  }

  & > table thead th {
    padding: 20px 0;
    position: sticky;
    top: 0;
    background: $white;
    z-index: 2;

    &:first-child {
      padding-left: 24px;
    }
    &:last-child {
      padding-right: 24px;
    }
  }

  & > table tbody td {
    padding: 12px 0;
    &:first-child {
      padding-left: 24px;
    }
    &:last-child {
      padding-right: 24px;
    }
  }

  &-head {
    font-weight: 500;
    padding: 0 0 8px 0;
    font-size: 14px;
    border-bottom: 1px solid $border;
    user-select: none;
    position: relative;
  }

  &-cell {
    &--textEnd {
      text-align: right;
    }
  }

  &-row {
    border-bottom: 1px solid $border;

    &:last-child {
      border-bottom: none;
    }
  }

  &-statusCircle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 8px;
    display: inline-block;
    &--deploying,
    &--retracting,
    &--training {
      background: $yellow;
      animation: blinker 1s linear infinite;
    }
    &--deleting {
      background: $red;
      animation: blinker 1s linear infinite;
    }
  }

  &-status {
    padding: 6px 8px 6px 8px;
    border-radius: 14px;
    line-height: 14px;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    &--trained,
    &--success {
      color: $green;
      font-weight: 500;
    }
    &--deploying,
    &--submitted_for_training,
    &--submitted_for_retraction,
    &--training {
      color: $yellow;
    }
    &--created {
      color: $purple;
    }
    &--submitted {
      color: $grey888;
    }
    &--deployed {
      color: $blue;
      font-weight: 500;
    }
    &--deleting,
    &--error {
      color: $red;
    }
  }
}

.PredictionView {
  background-color: $white;
  border-radius: 4px;
  padding: 16px 32px;
  padding-left: 48px;
  margin-bottom: 20px;

  &-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 4px;
    padding-left: 20px;
    background: $lightgrey;
    border-radius: 4px;

    &--nested {
      background: $lightBlue;
    }

    &--nested:first-child::before {
      content: '';
      position: absolute;
      width: 20px;
      border-top: 1px solid $grey888;
      top: 12px;
      left: -20px;
    }

    & > p {
      margin: 0;
      word-break: break-word;
      padding-right: 20px;
    }
  }

  &-head {
    margin-bottom: 20px;
    border-bottom: 1px solid $grey888;
    padding: 4px 0px 4px 0px;

    &--name {
      padding-bottom: 20px;
      color: $grey888;

      & > b {
        color: black;
      }
    }
  }

  &-head > &-row {
    background: $white;

    &:nth-child(2) {
      font-weight: 500;
    }
  }

  &-and {
    position: relative;

    &--label {
      display: inline-block;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      padding: 2px 8px;
      color: #ffa638;
      font-weight: bold;
      font-size: 10px;
      background: #fafafa;
      margin-left: -20px;
      margin-top: 2px;
    }

    &--label::before {
      content: '';
      border-left: 1px solid $grey888;
      width: 2px;
      height: 100%;
      position: absolute;
      left: -20px;
      bottom: -12px;
    }
  }

  &-or {
    &--label {
      display: block;
      padding: 8px 30px;
      color: $blue;
      font-weight: bold;
      font-size: 10px;
      background: $lightBlue;
      margin: 0;
    }
  }
}

.ModelView {
  &-label {
    color: $grey888;

    b {
      color: #000000;
    }
  }
  &-settings {
    display: flex;
    &--values {
      margin-top: 16px;
      display: flex;
      flex-direction: column;
    }
    &--check {
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      margin-left: 100px;
    }
  }
}

.DeployModel-numberInputField {
  flex: 3;
  margin-right: 8px;
}

.DeployModel-selectField {
  flex: 3;
  margin-left: 8px;
}

.DeployModel-actions {
  margin-top: 12px;
}

.DeployModel-row {
  & > .FieldWrapper-fieldWrap {
    display: flex;
  }

  & > .FieldWrapper-fieldWrap > .FieldWrapper {
    margin-top: 0;
  }
}

.ModelStats {
  &-panel {
    margin-bottom: 24px;

    table {
      width: 100%;
      margin-top: 16px;
    }

    table,
    th,
    td {
      border: 1px solid;
      padding: 4px;
    }
  }
}

.AuditLog {
  &-statusCircle {
    position: relative;
    top: 15px;
    right: 6px;
    z-index: 10;
    height: 15px;
    width: 15px;
    border-radius: 50%;

    &--trained,
    &--success {
      background: $green;
    }
    &--deploying,
    &--submitted_for_training,
    &--submitted_for_retraction,
    &--training {
      background: $yellow;
    }
    &--created {
      background: $purple;
    }
    &--submitted {
      background: $grey888;
    }
    &--deployed {
      background: $blue;
    }
    &--deleting,
    &--error {
      background: $red;
    }
  }

  &-status {
    padding: 6px 8px 6px 8px;
    border-radius: 14px;
    line-height: 14px;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    &--trained,
    &--success {
      color: $green;
      font-weight: 500;
    }
    &--deploying,
    &--submitted_for_training,
    &--submitted_for_retraction,
    &--training {
      color: $yellow;
    }
    &--created {
      color: $purple;
    }
    &--submitted {
      color: $grey888;
    }
    &--deployed {
      color: $blue;
      font-weight: 500;
    }
    &--deleting,
    &--error {
      color: $red;
    }
  }
}

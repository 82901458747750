.DateRangeForm-fields {
  display: flex;
  margin-bottom: 32px;
}

.DateRangeForm-fields .FieldWrapper + .FieldWrapper {
  margin-top: 0;
}

.DateRangeForm-fields .FieldWrapper-errorOrHintContainer {
  position: absolute;
}

.DateRangeForm-fields > * + * {
  margin-left: 32px;
}

.DateRangeForm-actionButtons {
  display: flex;
  margin-top: 28px;
  align-items: center;
}

.DateRangeForm-actionButtons button + button {
  margin-left: 16px;
}

.DateRangeForm-resetButton {
  height: 30px;
}

.DateRangeForm-resetButton .TooltipTriggerArea,
.DateRangeForm-resetButton .Button-icon {
  font-size: 0;
}

.Googlebutton {
  font-size: 14px !important;
  font-weight: 500;
  color: #ffffff;
  background-color: #4285f4;
  padding-right: 12px;

  &:disabled {
    background-color: #00000040;
    color: #00000080;
  }

  & > .Icon {
    margin-right: 8px;
    margin-left: -4px;
  }
}

/**
 * Include the same global variables as the other styles
 */
@import 'variables.less';
/**
 * Include Twitter Bootstrap
 */
@import '../../node_modules/bootstrap/less/bootstrap.less';
/**
 * Include codemirror CSS
 */
@import 'codemirror.less';
@import 'flags.less';
/**
 * Include custom UI Bootstrap CSS
 */
@import 'uib-timepicker.less';

/** Override bootstrap default configuration**/

label {
  font-weight: normal;
}

h3 .small,
.h3 .small {
  font-size: 80%;
  color: #999;
}

textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input {
  border: 1px solid #ccc;
  height: 20px;
  padding: 4px 6px;

}

textarea,
input,
label,
span,
div {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
  box-sizing: border-box;
}

//Support old version css bootstrap that we need in our theme
.r42-ui {

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 10px 0;
    font-family: inherit;
  }

  h2, h3 {
    margin: 0;
  }

  select {
    width: 220px;
    background-color: #fff;
    border: 1px solid #ccc;
  }

  select,
  input[type="file"] {
    height: 30px;
    line-height: 30px;
  }

  img {
    height: auto;
    max-width: 100%;
  }

  .mainContent {
    min-height: 100%;
    display: flex;


    .row {
      margin-left: 0;
      margin-right: 0;
    }

    > div {
      flex-grow: 1;
      max-width: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  .alert {
    padding: 8px 35px 8px 14px;
  }

  //Support Input sizes
  .input-mini {
    width: 60px
  }

  .input-small {
    width: 90px
  }

  .input-regular {
    width: 120px
  }

  .input-medium {
    width: 150px
  }

  .input-large {
    width: 207px
  }

  .input-xlarge {
    width: 270px
  }

  .input-xxlarge {
    width: 530px
  }

  //btn override
  .btn-mini {
    padding: 0 6px;
    font-size: 10.5px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
  }

  .btn-large {
    padding: 11px 19px;
    font-size: 17.5px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
  }

  .btn-small {
    padding: 2px 10px;
    font-size: 11.9px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
  }

  .control-group {
    margin-bottom: 10px;
  }

  .controls {
    margin-left: 160px;
  }

  .form-horizontal {
    .control-label {
      float: left;
    }

  .control-group {
      margin-bottom: 20px;

      &:after,
      &:before {
        display: table;
        line-height: 0;
        content: "";
        clear: both;
      }
    }

    .controls {
      margin-left: 180px;
    }

    .api-permissions-enabled-edit {
      .controls {
        margin-left: 192px;
      }
    }

    .api-permissions-add-list {
      .controls {
        margin-left: 187px;
      }
    }

    .api-permissions-edit-list {
      .controls {
        margin-left: 207px;
      }
    }

    &.cookie-permission {
      .span9 {
        width: 700px;
      }

      [class*="span"] {
        float: left;
        min-height: 1px;
        margin-left: 20px
      }
    }
  }

  //Override tooltip styling
  .tooltip {
    .tooltip-inner {
      background-color: rgba(0, 0, 0, 0.7);
      color: rgb(255, 255, 255);
      font-weight: 500;
    }

    .tooltip-arrow {
      border-right-color: rgba(0, 0, 0, 0.7);
    }

  }

  &.login .form-control {
    width: 94.5%;
    border-radius: 2px !important;
  }

  .form-actions {
    padding: 19px 20px 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    *zoom: 1;

    &:before,
    &:after {
      display: table;
      line-height: 0;
      content: ""
    }

    &:after {
      clear: both
    }
  }

  .table {

    thead tr th,
    tbody tr td {
      padding: 10px;
      border-top: 1px dotted #ddd;
    }

    thead tr th {
      border-bottom: 0;
    }

    tbody tr .cell-icon {
      padding-left: 2px;
      padding-right: 2px;
      text-align: center;
      vertical-align: middle;
    }
  }
}
@import 'src/client/components/src/Common/variables.scss';

.Spinner {
  color: #33b062;
  font-size: 10px;
  margin: 20px auto 63px auto;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  animation-delay: -0.16s;

  &-icon {
    text-align: center;
    position: relative;
    top: 40%;
  }

  &,
  &:after,
  &:before {
    border-radius: 50%;
    width: 1.4em;
    height: 1.4em;
    animation: SpinnerLoad 1.8s infinite ease-in-out;
    animation-fill-mode: both;
  }

  &:after,
  &:before {
    content: '';
    position: absolute;
    top: 0;
  }

  &:after {
    left: 2.1em;
  }

  &:before {
    left: -2.1em;
    animation-delay: -0.32s;
  }
}

@keyframes SpinnerLoad {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

.blankOverlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 1;
}

.blankOverlay--lightGray {
  background: var($colors, background);
}

.blankOverlay--withCenteredContent {
  display: flex;
  align-items: center;
}

*:focus {outline:none;}

.r42-ui label:not(.FieldWrapper-label), .r42-ui input, .r42-ui select, .r42-ui textarea:not(.TextAreaElement) {
	font-size: @r42-forms-font-size;
	font-family: Roboto, sans-serif;
	line-height: 20px;
	margin: 0;
	border-radius: 0;
}

.r42-ui ul {
    list-style: none;
}

.r42-ui input[readonly] {
	cursor: text;
}

// Remove the validation error descriptions on focus of the element
.r42-ui input:focus ~ .error-block {
	display: none;
}
.r42-ui textarea:focus ~ .error-block {
	display: none;
}
.r42-ui select:focus ~ .error-block {
	display: none;
}
//FIXME: Because of data-valid directive not updating the errors we have to add the :not(.ignore-error)

.r42-ui .error:not(.ignore-error)  {
	font-weight: normal;

	input, select, textarea {
		&:not(:focus) {
			border-color: @r42-red-validation-text;
		}
	}
    /* When there is an error like isRequired Add the red color to the placeholders
     * NOTE: Do NOT group these rules
    */
    input {

        &::-webkit-input-placeholder {
            color: @r42-red-validation-text;
        }
        &:-moz-placeholder {
            /* FF 4-18 */
            color: @r42-red-validation-text;
        }
        &::-moz-placeholder {
            /* FF 19+ */
            color: @r42-red-validation-text;
        }
        &:-ms-input-placeholder {
            /* IE 10+ */
            color: @r42-red-validation-text;
        }

        &::placeholder {
            color: @r42-red-validation-text;
        }

    }

	label, .error-block, .editable.view .editable-container > * {
		color: @r42-red-validation-text;
	}

	td {
		&.error-block {
			display: table-cell;
		}
	}

	.error-block small {
		font-size: inherit;
	}
}

.r42-ui .box-title-logo {
	padding-left: 0;
}

.r42-ui .alert {
	margin-top: 10px;
	margin-bottom: 10px;
	font-size: 15px;
	box-shadow: 5px 3px 5px @r42-grey-background-journey;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	border-radius: 2px;

	a, .link {
		color: @r42-blue-journey;
	}

	&.centered {
		text-align: center;
	}

	&.alert-error {
		color: @r42-red-error-notification-text;
		background-color: @r42-red-error-notification-background;
		border: 1px solid @r42-red-error-notification-border;

		li {
			background: none !important;
		}

		.indent-right {
			padding-top: 5px;
			padding-left: 22px;
		}
	}

	&.alert-info {
		color: @r42-blue-info-notification-text;
		background-color: @r42-blue-info-notification-background;
		border: 1px solid @r42-blue-info-notification-border;
	}

	&.alert-success {
		color: @r42-green-success-notification-text;
		background-color: @r42-green-success-notification-background;
		border: 1px solid @r42-green-success-notification-border;
	}

	&.alert-warning {
		color: @r42-yellow-warning-notification-text;
		background-color: @r42-yellow-warning-notification-background;
		border: 1px solid @r42-yellow-warning-notification-border;
	}
}


.r42-ui .btn {
	font-weight: 200;
    font-size: 13px;
	background: none;

	&:hover {
		background: @r42-lightgrey;
	}
}

.r42-ui
.box-title {

	&.no-border {
		border: 0px;
	}

	.logo {
		width: 60px;
		height: 60px;
	}

	img {
		padding: 0px 10px 0px 5px;
	}

	h3 {
		padding-right: 5px;
	}

	.title {
		font-size: 20px;
		font-weight: 500;
		padding-left: 5px;
		vertical-align: middle;
	}

    .subtitle {
        padding-left: 5px;
    }

	.label-default {
		font-size: 15px;
	}
}

.r42-ui #left {
	margin: 0;
	padding: 0 0 10px 0;
	width: 280px;
	overflow: auto;
	overflow-x: hidden;
	background-color: white;
	border-right: 1px solid @r42-panel-border;
}

.r42-ui #main {
	margin: 0;
	padding: 0 0 0px 0;
	overflow: auto;
	overflow-x: visible;
}
.r42-ui #main.pane-min {
    padding-bottom: 0;
}

.r42-ui .form-wizard.wizard-vertical .form-actions {
	padding-left: 20px;
}

.r42-ui .form-wizard.wizard-vertical .wizard-steps {
	margin-left: 10px;
}
.r42-ui .form-wizard.wizard-vertical .form-content .control-group {
	margin-left: 10px;
	padding-left: 0;
}

.r42-ui .form-horizontal .form-group .control-label small {
    display: block;
    color: #999;
}

// Fix for chrome 38 (RP-1082) with click problems
h1, h2, h3, h4, h5, h6 {
	text-rendering: auto;
}

.r42-ui #navigation img#logo {
    width: auto\9;
    height: auto;
    max-width: 100%;
    vertical-align: middle;
    border: 0;
    -ms-interpolation-mode: bicubic
}

.extra-config-container {

	background-color: @r42-lightgrey-journey;
	border-radius: 2px;
	padding: 22px;

	> div {
		margin-bottom: 20px;
	}

	.config-entry {

		.title {
			margin-top: 15px;
			font-weight: 500;
		}

		.content {
			padding: 2px 0 0 30px;
			margin: 5px 0 0 0;
		}
	}

	li {
		word-break: break-all;
	}
}

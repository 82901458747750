@charset "UTF-8";

/*!
 *
 *  Project:  GLYPHICONS
 *  Author:   Jan Kovarik - www.glyphicons.com
 *  Twitter:  @jankovarik
 *
 */

html,
html .halflings {
  -webkit-font-smoothing: antialiased !important;
}

@font-face {
  font-family: 'Glyphicons Halflings';
  src: url(../font/glyphicons-halflings-regular.eot);
  src:
    url(../font/glyphicons-halflings-regular.eot) format('embedded-opentype'),
    url(../font/glyphicons-halflings-regular.woff) format('woff'),
    url(../font/glyphicons-halflings-regular.ttf) format('truetype'),
    url(/home/pecodanajlovski/Projects/Relay42/webui/ui/build/assetsimg/glyphicons-halflings-regular.svg) format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='glyphicon-'],
[class*=' glyphicon-'] {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* sprites.less reset */
  display: inline;
  width: auto;
  height: auto;
  line-height: 15px;
  vertical-align: middle;
  background-image: none;
  background-position: 0% 0%;
  background-repeat: repeat;
  margin-top: 0;
}

[class^='glyphicon-']:before,
[class*=' glyphicon-']:before {
  text-decoration: inherit;
  display: inline-block;
  speak: none;
}

a [class^='glyphicon-'],
a [class*=' glyphicon-'] {
  display: inline-block;
}

li [class^='glyphicon-'],
li [class*=' glyphicon-'],
.nav li [class^='glyphicon-'],
.nav li [class*=' glyphicon-'] {
  display: inline-block;
  width: 1.25em;
  text-align: center;
}

.glyphicon-asterisk:before {
  content: '*';
}

.glyphicon-plus:before {
  content: '+';
}

.glyphicon-euro:before,
.glyphicon-eur:before {
  content: '\20AC';
}

.glyphicon-minus:before {
  content: '\2212';
}

.glyphicon-cloud:before {
  content: '\2601';
}

.glyphicon-envelope:before {
  content: '\2709';
}

.glyphicon-pencil:before {
  content: '\270F';
}

.glyphicon-glass:before {
  content: '\E001';
}

.glyphicon-music:before {
  content: '\E002';
}

.glyphicon-search:before {
  content: '\E003';
}

.glyphicon-heart:before {
  content: '\E005';
}

.glyphicon-star:before {
  content: '\E006';
}

.glyphicon-star-empty:before {
  content: '\E007';
}

.glyphicon-user:before {
  content: '\E008';
}

.glyphicon-film:before {
  content: '\E009';
}

.glyphicon-th-large:before {
  content: '\E010';
}

.glyphicon-th:before {
  content: '\E011';
}

.glyphicon-th-list:before {
  content: '\E012';
}

.glyphicon-ok:before {
  content: '\E013';
}

.glyphicon-remove:before {
  content: '\E014';
}

.glyphicon-zoom-in:before {
  content: '\E015';
}

.glyphicon-zoom-out:before {
  content: '\E016';
}

.glyphicon-off:before {
  content: '\E017';
}

.glyphicon-signal:before {
  content: '\E018';
}

.glyphicon-cog:before {
  content: '\E019';
}

.glyphicon-trash:before {
  content: '\E020';
}

.glyphicon-home:before {
  content: '\E021';
}

.glyphicon-file:before {
  content: '\E022';
}

.glyphicon-time:before {
  content: '\E023';
}

.glyphicon-road:before {
  content: '\E024';
}

.glyphicon-download-alt:before {
  content: '\E025';
}

.glyphicon-download:before {
  content: '\E026';
}

.glyphicon-upload:before {
  content: '\E027';
}

.glyphicon-inbox:before {
  content: '\E028';
}

.glyphicon-play-circle:before {
  content: '\E029';
}

.glyphicon-repeat:before {
  content: '\E030';
}

.glyphicon-refresh:before {
  content: '\E031';
}

.glyphicon-list-alt:before {
  content: '\E032';
}

.glyphicon-lock:before {
  content: '\E033';
}

.glyphicon-flag:before {
  content: '\E034';
}

.glyphicon-headphones:before {
  content: '\E035';
}

.glyphicon-volume-off:before {
  content: '\E036';
}

.glyphicon-volume-down:before {
  content: '\E037';
}

.glyphicon-volume-up:before {
  content: '\E038';
}

.glyphicon-qrcode:before {
  content: '\E039';
}

.glyphicon-barcode:before {
  content: '\E040';
}

.glyphicon-tag:before {
  content: '\E041';
}

.glyphicon-tags:before {
  content: '\E042';
}

.glyphicon-book:before {
  content: '\E043';
}

.glyphicon-bookmark:before {
  content: '\E044';
}

.glyphicon-print:before {
  content: '\E045';
}

.glyphicon-camera:before {
  content: '\E046';
}

.glyphicon-font:before {
  content: '\E047';
}

.glyphicon-bold:before {
  content: '\E048';
}

.glyphicon-italic:before {
  content: '\E049';
}

.glyphicon-text-height:before {
  content: '\E050';
}

.glyphicon-text-width:before {
  content: '\E051';
}

.glyphicon-align-left:before {
  content: '\E052';
}

.glyphicon-align-center:before {
  content: '\E053';
}

.glyphicon-align-right:before {
  content: '\E054';
}

.glyphicon-align-justify:before {
  content: '\E055';
}

.glyphicon-list:before {
  content: '\E056';
}

.glyphicon-indent-left:before {
  content: '\E057';
}

.glyphicon-indent-right:before {
  content: '\E058';
}

.glyphicon-facetime-video:before {
  content: '\E059';
}

.glyphicon-picture:before {
  content: '\E060';
}

.glyphicon-map-marker:before {
  content: '\E062';
}

.glyphicon-adjust:before {
  content: '\E063';
}

.glyphicon-tint:before {
  content: '\E064';
}

.glyphicon-edit:before {
  content: '\E065';
}

.glyphicon-share:before {
  content: '\E066';
}

.glyphicon-check:before {
  content: '\E067';
}

.glyphicon-move:before {
  content: '\E068';
}

.glyphicon-step-backward:before {
  content: '\E069';
}

.glyphicon-fast-backward:before {
  content: '\E070';
}

.glyphicon-backward:before {
  content: '\E071';
}

.glyphicon-play:before {
  content: '\E072';
}

.glyphicon-pause:before {
  content: '\E073';
}

.glyphicon-stop:before {
  content: '\E074';
}

.glyphicon-forward:before {
  content: '\E075';
}

.glyphicon-fast-forward:before {
  content: '\E076';
}

.glyphicon-step-forward:before {
  content: '\E077';
}

.glyphicon-eject:before {
  content: '\E078';
}

.glyphicon-chevron-left:before {
  content: '\E079';
}

.glyphicon-chevron-right:before {
  content: '\E080';
}

.glyphicon-plus-sign:before {
  content: '\E081';
}

.glyphicon-minus-sign:before {
  content: '\E082';
}

.glyphicon-remove-sign:before {
  content: '\E083';
}

.glyphicon-ok-sign:before {
  content: '\E084';
}

.glyphicon-question-sign:before {
  content: '\E085';
}

.glyphicon-info-sign:before {
  content: '\E086';
}

.glyphicon-screenshot:before {
  content: '\E087';
}

.glyphicon-remove-circle:before {
  content: '\E088';
}

.glyphicon-ok-circle:before {
  content: '\E089';
}

.glyphicon-ban-circle:before {
  content: '\E090';
}

.glyphicon-arrow-left:before {
  content: '\E091';
}

.glyphicon-arrow-right:before {
  content: '\E092';
}

.glyphicon-arrow-up:before {
  content: '\E093';
}

.glyphicon-arrow-down:before {
  content: '\E094';
}

.glyphicon-share-alt:before {
  content: '\E095';
}

.glyphicon-resize-full:before {
  content: '\E096';
}

.glyphicon-resize-small:before {
  content: '\E097';
}

.glyphicon-exclamation-sign:before {
  content: '\E101';
}

.glyphicon-gift:before {
  content: '\E102';
}

.glyphicon-leaf:before {
  content: '\E103';
}

.glyphicon-fire:before {
  content: '\E104';
}

.glyphicon-eye-open:before {
  content: '\E105';
}

.glyphicon-eye-close:before {
  content: '\E106';
}

.glyphicon-warning-sign:before {
  content: '\E107';
}

.glyphicon-plane:before {
  content: '\E108';
}

.glyphicon-calendar:before {
  content: '\E109';
}

.glyphicon-random:before {
  content: '\E110';
}

.glyphicon-comment:before {
  content: '\E111';
}

.glyphicon-magnet:before {
  content: '\E112';
}

.glyphicon-chevron-up:before {
  content: '\E113';
}

.glyphicon-chevron-down:before {
  content: '\E114';
}

.glyphicon-retweet:before {
  content: '\E115';
}

.glyphicon-shopping-cart:before {
  content: '\E116';
}

.glyphicon-folder-close:before {
  content: '\E117';
}

.glyphicon-folder-open:before {
  content: '\E118';
}

.glyphicon-resize-vertical:before {
  content: '\E119';
}

.glyphicon-resize-horizontal:before {
  content: '\E120';
}

.glyphicon-hdd:before {
  content: '\E121';
}

.glyphicon-bullhorn:before {
  content: '\E122';
}

.glyphicon-bell:before {
  content: '\E123';
}

.glyphicon-certificate:before {
  content: '\E124';
}

.glyphicon-thumbs-up:before {
  content: '\E125';
}

.glyphicon-thumbs-down:before {
  content: '\E126';
}

.glyphicon-hand-right:before {
  content: '\E127';
}

.glyphicon-hand-left:before {
  content: '\E128';
}

.glyphicon-hand-up:before {
  content: '\E129';
}

.glyphicon-hand-down:before {
  content: '\E130';
}

.glyphicon-circle-arrow-right:before {
  content: '\E131';
}

.glyphicon-circle-arrow-left:before {
  content: '\E132';
}

.glyphicon-circle-arrow-up:before {
  content: '\E133';
}

.glyphicon-circle-arrow-down:before {
  content: '\E134';
}

.glyphicon-globe:before {
  content: '\E135';
}

.glyphicon-wrench:before {
  content: '\E136';
}

.glyphicon-tasks:before {
  content: '\E137';
}

.glyphicon-filter:before {
  content: '\E138';
}

.glyphicon-briefcase:before {
  content: '\E139';
}

.glyphicon-fullscreen:before {
  content: '\E140';
}

.glyphicon-dashboard:before {
  content: '\E141';
}

.glyphicon-paperclip:before {
  content: '\E142';
}

.glyphicon-heart-empty:before {
  content: '\E143';
}

.glyphicon-link:before {
  content: '\E144';
}

.glyphicon-phone:before {
  content: '\E145';
}

.glyphicon-pushpin:before {
  content: '\E146';
}

.glyphicon-usd:before {
  content: '\E148';
}

.glyphicon-gbp:before {
  content: '\E149';
}

.glyphicon-sort:before {
  content: '\E150';
}

.glyphicon-sort-by-alphabet:before {
  content: '\E151';
}

.glyphicon-sort-by-alphabet-alt:before {
  content: '\E152';
}

.glyphicon-sort-by-order:before {
  content: '\E153';
}

.glyphicon-sort-by-order-alt:before {
  content: '\E154';
}

.glyphicon-sort-by-attributes:before {
  content: '\E155';
}

.glyphicon-sort-by-attributes-alt:before {
  content: '\E156';
}

.glyphicon-unchecked:before {
  content: '\E157';
}

.glyphicon-expand:before {
  content: '\E158';
}

.glyphicon-collapse-down:before {
  content: '\E159';
}

.glyphicon-collapse-up:before {
  content: '\E160';
}

.glyphicon-log-in:before {
  content: '\E161';
}

.glyphicon-flash:before {
  content: '\E162';
}

.glyphicon-log-out:before {
  content: '\E163';
}

.glyphicon-new-window:before {
  content: '\E164';
}

.glyphicon-record:before {
  content: '\E165';
}

.glyphicon-save:before {
  content: '\E166';
}

.glyphicon-open:before {
  content: '\E167';
}

.glyphicon-saved:before {
  content: '\E168';
}

.glyphicon-import:before {
  content: '\E169';
}

.glyphicon-export:before {
  content: '\E170';
}

.glyphicon-send:before {
  content: '\E171';
}

.glyphicon-floppy-disk:before {
  content: '\E172';
}

.glyphicon-floppy-saved:before {
  content: '\E173';
}

.glyphicon-floppy-remove:before {
  content: '\E174';
}

.glyphicon-floppy-save:before {
  content: '\E175';
}

.glyphicon-floppy-open:before {
  content: '\E176';
}

.glyphicon-credit-card:before {
  content: '\E177';
}

.glyphicon-transfer:before {
  content: '\E178';
}

.glyphicon-cutlery:before {
  content: '\E179';
}

.glyphicon-header:before {
  content: '\E180';
}

.glyphicon-compressed:before {
  content: '\E181';
}

.glyphicon-earphone:before {
  content: '\E182';
}

.glyphicon-phone-alt:before {
  content: '\E183';
}

.glyphicon-tower:before {
  content: '\E184';
}

.glyphicon-stats:before {
  content: '\E185';
}

.glyphicon-sd-video:before {
  content: '\E186';
}

.glyphicon-hd-video:before {
  content: '\E187';
}

.glyphicon-subtitles:before {
  content: '\E188';
}

.glyphicon-sound-stereo:before {
  content: '\E189';
}

.glyphicon-sound-dolby:before {
  content: '\E190';
}

.glyphicon-sound-5-1:before {
  content: '\E191';
}

.glyphicon-sound-6-1:before {
  content: '\E192';
}

.glyphicon-sound-7-1:before {
  content: '\E193';
}

.glyphicon-copyright-mark:before {
  content: '\E194';
}

.glyphicon-registration-mark:before {
  content: '\E195';
}

.glyphicon-cloud-download:before {
  content: '\E197';
}

.glyphicon-cloud-upload:before {
  content: '\E198';
}

.glyphicon-tree-conifer:before {
  content: '\E199';
}

.glyphicon-tree-deciduous:before {
  content: '\E200';
}

.glyphicon-cd:before {
  content: '\E201';
}

.glyphicon-save-file:before {
  content: '\E202';
}

.glyphicon-open-file:before {
  content: '\E203';
}

.glyphicon-level-up:before {
  content: '\E204';
}

.glyphicon-copy:before {
  content: '\E205';
}

.glyphicon-paste:before {
  content: '\E206';
}

.glyphicon-alert:before {
  content: '\E209';
}

.glyphicon-equalizer:before {
  content: '\E210';
}

.glyphicon-king:before {
  content: '\E211';
}

.glyphicon-queen:before {
  content: '\E212';
}

.glyphicon-pawn:before {
  content: '\E213';
}

.glyphicon-bishop:before {
  content: '\E214';
}

.glyphicon-knight:before {
  content: '\E215';
}

.glyphicon-baby-formula:before {
  content: '\E216';
}

.glyphicon-tent:before {
  content: '\26FA';
}

.glyphicon-blackboard:before {
  content: '\E218';
}

.glyphicon-bed:before {
  content: '\E219';
}

.glyphicon-apple:before {
  content: '\F8FF';
}

.glyphicon-erase:before {
  content: '\E221';
}

.glyphicon-hourglass:before {
  content: '\231B';
}

.glyphicon-lamp:before {
  content: '\E223';
}

.glyphicon-duplicate:before {
  content: '\E224';
}

.glyphicon-piggy-bank:before {
  content: '\E225';
}

.glyphicon-scissors:before {
  content: '\E226';
}

.glyphicon-bitcoin:before {
  content: '\E227';
}

.glyphicon-btc:before {
  content: '\E227';
}

.glyphicon-xbt:before {
  content: '\E227';
}

.glyphicon-yen:before {
  content: '\A5';
}

.glyphicon-jpy:before {
  content: '\A5';
}

.glyphicon-ruble:before {
  content: '\20BD';
}

.glyphicon-rub:before {
  content: '\20BD';
}

.glyphicon-scale:before {
  content: '\E230';
}

.glyphicon-ice-lolly:before {
  content: '\E231';
}

.glyphicon-ice-lolly-tasted:before {
  content: '\E232';
}

.glyphicon-education:before {
  content: '\E233';
}

.glyphicon-option-horizontal:before {
  content: '\E234';
}

.glyphicon-option-vertical:before {
  content: '\E235';
}

.glyphicon-menu-hamburger:before {
  content: '\E236';
}

.glyphicon-modal-window:before {
  content: '\E237';
}

.glyphicon-oil:before {
  content: '\E238';
}

.glyphicon-grain:before {
  content: '\E239';
}

.glyphicon-sunglasses:before {
  content: '\E240';
}

.glyphicon-text-size:before {
  content: '\E241';
}

.glyphicon-text-color:before {
  content: '\E242';
}

.glyphicon-text-background:before {
  content: '\E243';
}

.glyphicon-object-align-top:before {
  content: '\E244';
}

.glyphicon-object-align-bottom:before {
  content: '\E245';
}

.glyphicon-object-align-horizontal:before {
  content: '\E246';
}

.glyphicon-object-align-left:before {
  content: '\E247';
}

.glyphicon-object-align-vertical:before {
  content: '\E248';
}

.glyphicon-object-align-right:before {
  content: '\E249';
}

.glyphicon-triangle-right:before {
  content: '\E250';
}

.glyphicon-triangle-left:before {
  content: '\E251';
}

.glyphicon-triangle-bottom:before {
  content: '\E252';
}

.glyphicon-triangle-top:before {
  content: '\E253';
}

.glyphicon-console:before {
  content: '\E254';
}

.glyphicon-superscript:before {
  content: '\E255';
}

.glyphicon-subscript:before {
  content: '\E256';
}

.glyphicon-menu-left:before {
  content: '\E257';
}

.glyphicon-menu-right:before {
  content: '\E258';
}

.glyphicon-menu-down:before {
  content: '\E259';
}

.glyphicon-menu-up:before {
  content: '\E260';
}

@font-face {
  font-family: pictonic;
  src: url(../font/pictonic.eot);
  src:
    url(../font/pictonic.eot) format('embedded-opentype'),
    url(../font/pictonic.ttf) format('truetype'),
    url(../font/pictonic.woff) format('woff'),
    url(/home/pecodanajlovski/Projects/Relay42/webui/ui/build/assetsimg/pictonic.svg) format('svg');
  font-weight: normal;
  font-style: normal;
}

.pictonic {
  font-family: 'pictonic';
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased !important;
  -moz-font-smoothing: antialiased !important;
  font-smoothing: antialiased !important;
  line-height: 1em;
}

a.pictonic,
span.pictonic,
small.pictonic {
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
}

[class^='pictonic-'],
[class*=' pictonic-'] {
  font-family: 'pictonic';
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased !important;
  -moz-font-smoothing: antialiased !important;
  font-smoothing: antialiased !important;
  line-height: 1em;
}

a[class^='pictonic-'],
a[class*=' pictonic-'],
span[class^='pictonic-'],
span[class*=' pictonic-'],
small[class^='pictonic-'],
small[class*=' pictonic-'],
i[class^='pictonic-'],
i[class*=' pictonic-'] {
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
}

.pictonic-brw-explorer:before {
  content: '\E001';
}

.theme-r42green ul > li.blue {
  background: #368ee0;
}

.theme-r42green .label-blue {
  background: #368ee0;
}

.theme-r42green .btn.btn-blue {
  background: #368ee0;
  color: white;
}

.theme-r42green ul > li.orange {
  background: #f8a31f;
}

.theme-r42green .label-orange {
  background: #f8a31f;
}

.theme-r42green .btn.btn-orange {
  background: #f8a31f;
  color: white;
}

.theme-r42green ul > li.red {
  background: #e51400;
}

.theme-r42green .label-red {
  background: #e51400;
}

.theme-r42green .btn.btn-red {
  background: #e51400;
  color: white;
}

.theme-r42green ul > li.green {
  background: #339933;
}

.theme-r42green .label-green {
  background: #339933;
}

.theme-r42green .btn.btn-green {
  background: #339933;
  color: white;
}

.theme-r42green ul > li.lime {
  background: #8cbf26;
}

.theme-r42green .label-lime {
  background: #8cbf26;
}

.theme-r42green .btn.btn-lime {
  background: #8cbf26;
  color: white;
}

.theme-r42green ul > li.teal {
  background: #00aba9;
}

.theme-r42green .label-teal {
  background: #00aba9;
}

.theme-r42green .btn.btn-teal {
  background: #00aba9;
  color: white;
}

.theme-r42green ul > li.purple {
  background: #ff0097;
}

.theme-r42green .label-purple {
  background: #ff0097;
}

.theme-r42green .btn.btn-purple {
  background: #ff0097;
  color: white;
}

.theme-r42green ul > li.brown {
  background: #a05000;
}

.theme-r42green .label-brown {
  background: #a05000;
}

.theme-r42green .btn.btn-brown {
  background: #a05000;
  color: white;
}

.theme-r42green ul > li.pink {
  background: #e671b8;
}

.theme-r42green .label-pink {
  background: #e671b8;
}

.theme-r42green .btn.btn-pink {
  background: #e671b8;
  color: white;
}

.theme-r42green ul > li.magenta {
  background: #a200ff;
}

.theme-r42green .label-magenta {
  background: #a200ff;
}

.theme-r42green .btn.btn-magenta {
  background: #a200ff;
  color: white;
}

.theme-r42green ul > li.grey {
  background: #333333;
}

.theme-r42green .label-grey {
  background: #333333;
}

.theme-r42green .btn.btn-grey {
  background: #333333;
  color: white;
}

.theme-r42green ul > li.darkblue {
  background: #204e81;
}

.theme-r42green .label-darkblue {
  background: #204e81;
}

.theme-r42green .btn.btn-darkblue {
  background: #204e81;
  color: white;
}

.theme-r42green ul > li.lightred {
  background: #e63a3a;
}

.theme-r42green .label-lightred {
  background: #e63a3a;
}

.theme-r42green .btn.btn-lightred {
  background: #e63a3a;
  color: white;
}

.theme-r42green ul > li.lightgrey {
  background: #666666;
}

.theme-r42green .label-lightgrey {
  background: #666666;
}

.theme-r42green .btn.btn-lightgrey {
  background: #666666;
  color: white;
}

.theme-r42green ul > li.satblue {
  background: #2c5e7b;
}

.theme-r42green .label-satblue {
  background: #2c5e7b;
}

.theme-r42green .btn.btn-satblue {
  background: #2c5e7b;
  color: white;
}

.theme-r42green ul > li.satgreen {
  background: #56af45;
}

.theme-r42green .label-satgreen {
  background: #56af45;
}

.theme-r42green .btn.btn-satgreen {
  background: #56af45;
  color: white;
}

.theme-r42green ul > li.grey {
  background: #333333;
}

.theme-r42green .label-grey {
  background: #333333;
}

.theme-r42green .btn.btn-grey {
  background: #333333;
  color: white;
}

.dropdown-menu {
  border: 2px solid #666;
  background: #fff;
  margin-top: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.dropdown-menu:before {
  border: 0;
}

.dropdown-menu.typeahead > li.active > a {
  background: #ddd;
  color: #333;
  filter: none;
}

.dropdown-menu.typeahead > li.active:focus > a,
.dropdown-menu.typeahead > li.active:hover > a {
  background: #368ee0;
  color: #fff;
  filter: none;
}

.dropdown-menu > li:focus > a,
.dropdown-menu > li:hover > a {
  background: #ddd;
  color: #333;
  filter: none;
}

.dropdown-menu > li > a {
  padding: 6px 15px;
  font-size: 13px;
  filter: none;
}

.dropdown-menu .dropdown-submenu:hover > a {
  background: #eee;
  color: #333;
}

.dropdown-menu .dropdown-submenu.active:hover:after {
  border-left-color: #666;
}

.dropdown-menu .dropdown-submenu > a:active,
.dropdown-menu .dropdown-submenu > a:focus {
  background: #eee;
  color: #333;
}

.dropdown-menu .dropdown-submenu > a:after {
  border-left-color: #666;
}

.dropdown-menu .dropdown-submenu .dropdown-menu {
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
}

.dropdown-menu .dropdown-submenu .dropdown-menu .active > a {
  background: #ddd;
  color: #333;
}

.modal form {
  margin-bottom: 0;
}

.input-append input,
.input-append select,
.input-append .uneditable-input {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
}

.tooltip {
  z-index: 99999;
}

.tab-content {
  overflow: visible;
}

.uneditable-input {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
}

.btn-group.text {
  font-size: 13px;
}

.btn-group .dropdown,
.btn-group .pagination {
  font-size: 13px;
}

.btn-group .pagination {
  margin: 0;
}

.btn-group .pagination ul {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.table-bordered {
  border-top: 0;
}

.table-bordered.table-force-topborder {
  border-top: 1px solid #ddd;
}

.table-bordered.dataTable {
  border-top: 1px solid #ddd;
}

.table-bordered.dataTable.dataTable-noheader {
  border-top: 0;
}

.table-bordered,
.table-bordered thead:first-child tr:first-child > th:first-child,
.table-bordered tbody:first-child tr:first-child > td:first-child,
.table-bordered tbody:first-child tr:first-child > th:first-child,
.table-bordered thead:first-child tr:first-child > th:last-child,
.table-bordered tbody:first-child tr:first-child > td:last-child,
.table-bordered tbody:first-child tr:first-child > th:last-child,
.table-bordered thead:last-child tr:last-child > th:first-child,
.table-bordered tbody:last-child tr:last-child > td:first-child,
.table-bordered tbody:last-child tr:last-child > th:first-child,
.table-bordered tfoot:last-child tr:last-child > td:first-child,
.table-bordered tfoot:last-child tr:last-child > th:first-child,
.table-bordered thead:last-child tr:last-child > th:last-child,
.table-bordered tbody:last-child tr:last-child > td:last-child,
.table-bordered tbody:last-child tr:last-child > th:last-child,
.table-bordered tfoot:last-child tr:last-child > td:last-child,
.table-bordered tfoot:last-child tr:last-child > th:last-child {
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
}

.input-prepend .add-on:first-child,
.input-prepend .btn:first-child,
.input-append .add-on:last-child,
.input-append .btn:last-child,
.input-append .btn-group:last-child > .dropdown-toggle,
.input-prepend.input-append .add-on:last-child,
.input-prepend.input-append .btn:last-child,
.input-prepend.input-append .add-on:first-child,
.input-prepend.input-append .btn:first-child,
.btn-group > .btn:first-child,
.btn-group > .btn:last-child,
.btn-group > .dropdown-toggle {
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
}

.input-prepend .btn-group .btn:first-child,
.input-append .btn-group .btn:first-child {
  border: 1px solid #ccc;
  padding: 4px 8px;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
}

.input-append .add-on:last-child,
.input-append .btn:last-child,
.input-append .btn-group:last-child > .dropdown-toggle,
.input-prepend .btn:first-child {
  border: 1px solid #ccc;
  padding: 4px 8px;
}

.input-prepend .add-on:first-child {
  border: 1px solid #ccc;
  padding: 4px 6px;
}

.label {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  text-shadow: none;
}

input:not(.InputElement),
textarea:not(.TextAreaElement) {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.modal {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
}

.modal .modal-footer {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.tooltip .tooltip-inner {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
}

.btn-group.open .dropdown-toggle {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.btn-group.open .dropdown-toggle.btn-primary {
  background: #368ee0;
}

.btn-group.open .dropdown-toggle.btn-danger {
  background: #e51400;
}

.btn-group.open .dropdown-toggle.btn-inverse {
  background: #333;
}

.btn-group.open .dropdown-toggle.btn-success {
  background: #339933;
}

.btn-group.open .dropdown-toggle.btn-warning {
  background: #f8a31f;
}

.btn-group.open .dropdown-toggle.btn-info {
  background: #368ee0;
}

textarea,
input[type='text'],
input[type='password'],
input[type='datetime'],
input[type='datetime-local'],
input[type='date'],
input[type='month'],
input[type='time'],
input[type='week'],
input[type='number'],
input[type='email'],
input[type='url'],
input[type='search'],
input[type='tel'],
input[type='color'],
.uneditable-input {
  background: #f9f9f9;
}

.form-horizontal .control-label {
  text-align: left;
  width: 140px;
}

.form-horizontal .controls {
  margin-left: 160px;
}

.dropdown-menu.dropdown-primary {
  border-color: #368ee0;
}

.dropdown-menu.dropdown-primary > li > a:hover {
  background: #368ee0;
  color: #fff;
}

.dropdown-menu.dropdown-danger {
  border-color: #e51400;
}

.dropdown-menu.dropdown-danger > li > a:hover {
  background: #e51400;
  color: #fff;
}

.dropdown-menu.dropdown-warning {
  border-color: #fab750;
}

.dropdown-menu.dropdown-warning > li > a:hover {
  background: #f8a31f;
  color: #fff;
}

.dropdown-menu.dropdown-success {
  border-color: #339933;
}

.dropdown-menu.dropdown-success > li > a:hover {
  background: #339933;
  color: #fff;
}

.dropdown-menu.dropdown-inverse {
  border-color: #484848;
}

.dropdown-menu.dropdown-inverse > li > a:hover {
  background: #484848;
  color: #fff;
}

.btn {
  background: #eee;
  border: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  text-shadow: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  color: #444;
  padding: 5px 9px;
  filter: none;
}

.btn.btn-large {
  padding: 10px 16px;
}

.btn.btn-small {
  padding: 3px 7px;
}

.btn.btn-mini {
  padding: 1px 4px;
}

.btn.btn-text-left {
  text-align: left;
}

.btn i {
  font-size: 14px;
}

.btn:hover {
  background: #ccc;
  text-shadow: none;
}

.btn:active {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.btn:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.btn.btn-primary {
  background: #368ee0;
  color: #fff;
  text-shadow: none;
  filter: none;
}

.btn.btn-primary:hover {
  background: #1e74c5;
}

.btn.btn-info {
  background: #a4ccf1;
  text-shadow: none;
  filter: none;
}

.btn.btn-info:hover {
  background: #62a7e7;
  color: #333;
}

.btn.btn-warning {
  background: #fab750;
  text-shadow: none;
  filter: none;
}

.btn.btn-warning:hover {
  background: #f8a31f;
  color: #333;
}

.btn.btn-danger {
  background: #ff4433;
  text-shadow: none;
  color: #fff;
  filter: none;
}

.btn.btn-danger:hover {
  background: #e51400;
}

.btn.btn-success {
  background: #40bf40;
  text-shadow: none;
  color: #fff;
  filter: none;
}

.btn.btn-success:hover {
  background: #339933;
}

.btn.btn-inverse {
  background: #484848;
  text-shadow: none;
  color: #fff;
  filter: none;
}

.btn.btn-inverse:hover {
  background: #111;
}

.nav.nav-pills > li > a,
.nav.nav-stacked > li > a,
.nav.nav-list > li > a {
  color: #171717;
}

.nav.nav-pills > li > a:hover,
.nav.nav-stacked > li > a:hover,
.nav.nav-list > li > a:hover {
  background: none;
}

.nav.nav-pills > li.active > a,
.nav.nav-stacked > li.active > a,
.nav.nav-list > li.active > a {
  color: inherit;
  background: inherit;
  font-weight: 500;
}

.nav.nav-pills > li.active > a:hover,
.nav.nav-stacked > li.active > a:hover,
.nav.nav-list > li.active > a:hover {
  color: inherit;
  background: inherit;
}

.nav.nav-pills > li:hover,
.nav.nav-stacked > li:hover,
.nav.nav-list > li:hover {
  font-weight: 500;
}

.popover {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  padding: 0;
}

.accordion .accordion-group {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
}

.accordion .accordion-group .accordion-heading .accordion-toggle {
  background: #f6f6f6;
}

.alert {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  border: 0;
}

.progress {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
}

.pagination-large ul > li:first-child > a,
.pagination-large ul > li:first-child > span,
.pagination-large ul > li:last-child > a,
.pagination-large ul > li:last-child > span,
.pagination ul > li:first-child > a,
.pagination ul > li:first-child > span,
.pagination-mini ul > li:first-child > a,
.pagination-small ul > li:first-child > a,
.pagination-mini ul > li:first-child > span,
.pagination-small ul > li:first-child > span,
.pagination ul > li:last-child > a,
.pagination ul > li:last-child > span,
.pagination-mini ul > li:last-child > a,
.pagination-small ul > li:last-child > a,
.pagination-mini ul > li:last-child > span,
.pagination-small ul > li:last-child > span {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
}

.table.table-condensed tr th,
.table.table-condensed tr td {
  padding: 4px 5px;
}

.table tr th,
.table tr td {
  padding: 10px;
  border-top: 1px dotted #ddd;
}

.form-actions {
  background: 0;
  border: 0;
}

@media (max-width: 1250px) {
  #main .page-header .minitiles {
    display: none;
  }
}

@media (max-width: 1200px) {
  .stats-overview > li {
    padding: 5px 10px !important;
  }

  .form-horizontal.form-column [class*='span'] {
    border-top: 1px solid #ddd;
    border-left: 0 !important;
    width: 100% !important;
  }

  .form-horizontal.form-column [class*='span']:first-child {
    border-top: 0;
  }

  .form-horizontal.form-column .form-actions {
    border-top: 0 !important;
  }
}

@media (max-width: 1100px) {
  .stats-overview > li {
    padding: 5px 10px !important;
    border-right: 1px dotted #ddd !important;
  }

  .stats-overview > li .name {
    font-size: 11px !important;
  }

  .stats-overview > li .value {
    font-size: 13px !important;
  }
}

@media (max-width: 1170px) {
  .icon-nav {
    display: none !important;
  }
}

@media (max-width: 1024px) {
  #main .page-header .stats {
    display: none;
  }

  .search-form > .search-pane > input {
    width: 135px !important;
  }

  .hidden-1024 {
    display: none !important;
  }

  .form-wizard .single-step .title {
    padding: 1px 5px !important;
  }

  .form-wizard .single-step .description {
    font-size: 12px !important;
    margin-left: 0 !important;
  }
}

@media (max-width: 890px) {
  #navigation .user .dropdown > a {
    padding: 7px 6px 6px 6px !important;
  }

  #navigation .main-nav > li > a {
    font-size: 14px;
    padding: 10px 8px !important;
  }
}

@media (max-width: 767px) {
  body {
    padding: 0 !important;
  }

  .pricing-tables .pricing.highlighted {
    margin-top: 30px !important;
  }

  .hidden-768 {
    display: none !important;
  }

  .navbar-fixed-top,
  .navbar-fixed-bottom,
  .navbar-static-top {
    margin: 0 !important;
  }

  .container-fluid,
  .container {
    padding: 0 20px;
  }

  #navigation #brand {
    padding-right: 0 !important;
  }

  #navigation .main-nav {
    display: none;
  }

  #navigation .toggle-nav {
    display: none !important;
  }

  .form-wizard.wizard-vertical .wizard-steps {
    width: 160px !important;
  }

  .form-wizard.wizard-vertical .form-content {
    margin-left: 160px !important;
  }

  .form-wizard.wizard-vertical .form-actions {
    padding-left: 180px !important;
  }
}

@media (max-width: 480px) {
  .table-mail .table-fixed-medium {
    width: 120px !important;
  }

  .mobile-sidebar-toggle {
    display: block !important;
  }

  .form-wizard.wizard-vertical .wizard-steps {
    width: 40px !important;
  }

  .form-wizard.wizard-vertical .form-content {
    margin-left: 40px !important;
  }

  .form-wizard.wizard-vertical .form-actions {
    padding-left: 60px !important;
  }

  .form-wizard.wizard-vertical .wizard-steps .description {
    display: none !important;
  }

  .tabs.tabs-inline.tabs-top > li > a {
    padding: 8px 10px !important;
  }

  .tabs.tabs-inline.tabs-left {
    position: static !important;
    background: none !important;
    width: auto !important;
  }

  .tabs.tabs-inline.tabs-left > li {
    float: left;
    margin-right: 1px;
  }

  .tabs.tabs-inline.tabs-left > li > a {
    border-top: 0 !important;
    border-bottom: 0 !important;
    padding: 8px 10px !important;
  }

  .tab-content.tab-content-inline {
    margin-left: 0 !important;
    border-left: 0 !important;
  }

  .hidden-480 {
    display: none !important;
  }

  .visible-480 {
    display: inline-block !important;
  }

  .form-horizontal .controls {
    margin-left: 0 !important;
  }

  .calendar .fc-button.fc-button-next .fc-button-content,
  .calendar .fc-button.fc-button-prev .fc-button-content {
    padding: 0 4px !important;
    margin-top: 3px;
  }

  .calendar .fc-button .fc-button-content {
    font-size: 12px !important;
    margin-right: 2px !important;
  }

  .calendar .fc-header .fc-header-left {
    width: 0 !important;
    display: none;
  }

  .calendar .fc-header .fc-header-right .fc-button {
    position: static !important;
    top: auto !important;
  }

  .calendar .fc-header .fc-header-title h2 {
    font-size: 14px !important;
  }

  body.login .wrapper {
    width: 300px;
    top: 0;
  }

  .user-list {
    display: none !important;
  }

  .messages.withlist {
    margin-right: 0 !important;
  }
}

@media (max-width: 350px) {
  .hidden-350 {
    display: none !important;
  }

  .calendar .fc-header-right {
    display: none !important;
  }

  #main .box .box-title h3 {
    font-size: 16px !important;
  }

  #main .box .box-title .actions .btn i {
    font-size: 12px !important;
  }

  .table .table-fixed-medium {
    width: 100px !important;
  }
}

#content {
  position: relative;
  min-height: 100%;
}

#content.nav-fixed {
  padding-top: 40px;
}

#content.nav-fixed #left {
  top: 40px;
}

#content.container-fluid {
  padding: 0;
}

#content.container-fluid.nav-fixed {
  padding-top: 40px;
}

#main {
  *zoom: 1;
  background: #fff;
  margin-left: 200px;
  overflow: visible;
  padding-bottom: 30px;
}

#main:after {
  content: '';
  display: table;
  clear: both;
}

#main .page-header {
  border: 0;
  margin: 15px 0 15px 0;
  padding: 0 3px;
  *zoom: 1;
}

#main .page-header:after {
  content: '';
  display: table;
  clear: both;
}

#main .page-header .pull-left h1 {
  margin: 20px 0;
}

#main .page-header .pull-left h6 {
  color: #777;
  font-weight: normal;
  margin: 0;
}

#main .breadcrumbs {
  *zoom: 1;
  background: #f4f4f4;
}

#main .breadcrumbs:after {
  content: '';
  display: table;
  clear: both;
}

#main .breadcrumbs ul {
  list-style-type: none;
  margin: 0;
  padding: 2px 5px;
  *zoom: 1;
  float: left;
}

#main .breadcrumbs ul:after {
  content: '';
  display: table;
  clear: both;
}

#main .breadcrumbs ul > li {
  float: left;
  color: #666;
}

#main .breadcrumbs ul > li > a {
  color: #666;
  display: block;
  padding: 2px 6px;
  font-size: 12px;
  float: left;
}

#main .breadcrumbs ul > li i {
  float: left;
  font-size: 11px;
  margin: 7px 0 6px 0;
}

#main .breadcrumbs .close-bread {
  float: right;
}

#main .breadcrumbs .close-bread > a {
  color: #999;
  display: block;
  font-size: 14px;
  margin: 4px 8px;
}

#main .breadcrumbs .close-bread > a:hover {
  text-decoration: none;
}

#main .ui-state-highlight {
  background: red;
  height: 50px;
}

#main #vmap {
  width: 100%;
  height: 300px;
}

#left {
  width: 200px;
  left: 0;
  bottom: 0;
  background: #eee;
  overflow-x: visible;
}

#left.mobile-show {
  position: fixed;
  z-index: 1000;
}

#left.full {
  bottom: auto;
  height: auto;
}

#left.sidebar-fixed {
  position: fixed;
  top: 40px;
  overflow-x: hidden;
}

#left.sidebar-fixed .ui-resizable-handle {
  right: 0;
}

#left .subnav {
  margin-top: 15px;
}

#left .subnav:hover .subnav-title .toggle-subnav i {
  display: block;
}

#left .subnav .subnav-content {
  *zoom: 1;
  padding: 10px 20px;
}

#left .subnav .subnav-content:after {
  content: '';
  display: table;
  clear: both;
}

#left .subnav .subnav-content.less {
  padding: 10px 13px;
}

#left .subnav .subnav-title {
  *zoom: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#left .subnav .subnav-title:after {
  content: '';
  display: table;
  clear: both;
}

#left .subnav .subnav-title span {
  margin-left: 20px;
  font-size: 18px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#left .subnav .subnav-title .toggle-subnav {
  color: #444;
  padding: 1px 6px;
  text-decoration: none;
  display: block;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

#left .subnav .subnav-title .toggle-subnav i {
  position: absolute;
  font-size: 14px;
  left: 10px;
  top: 4px;
  display: none;
}

#left .subnav .subnav-title .toggle-subnav:hover {
  color: #222;
}

#left .subnav .subnav-menu {
  list-style-type: none;
  margin: 5px 0;
  padding: 0;
}

#left .subnav .subnav-menu > li {
  text-overflow: ellipsis;
  white-space: nowrap;
}

#left .subnav .subnav-menu > li.dropdown > a {
  position: relative;
}

#left .subnav .subnav-menu > li.dropdown > a:after {
  position: absolute;
  right: 5px;
  top: 10px;
  width: 5px;
  border-color: transparent;
  border-left-color: #999;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  content: ' ';
}

#left .subnav .subnav-menu > li.dropdown.open > a {
  background: #ccc;
  color: #333;
}

#left .subnav .subnav-menu > li.dropdown.open .dropdown-menu {
  left: 100%;
  top: 0;
  border-color: #aaa;
  border-left: 0;
  margin: 0;
}

#left .subnav .subnav-menu > li.dropdown.open .dropdown-menu .dropdown-submenu .dropdown-menu {
  border-left: 2px solid #999;
}

#left .subnav .subnav-menu > li.dropdown.open .dropdown-menu .dropdown-submenu:hover > a,
#left .subnav .subnav-menu > li.dropdown.open .dropdown-menu .dropdown-submenu:focus > a {
  background: #ccc;
  color: #333;
}

#left .subnav .subnav-menu > li.dropdown.open .dropdown-menu .dropdown-submenu:hover > a:after,
#left .subnav .subnav-menu > li.dropdown.open .dropdown-menu .dropdown-submenu:focus > a:after {
  border-left-color: #999;
}

#left .subnav .subnav-menu > li > a {
  color: #555;
  display: block;
  padding: 5px 0 5px 27px;
  text-decoration: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

#left .subnav .subnav-menu > li > a:hover {
  background: #ddd;
  color: #222;
}

#left .subnav .subnav-menu > li.active > a {
  background: #ccc;
  color: #222;
  font-weight: 500;
}

#left .subnav .subnav-menu > li > .alert {
  text-overflow: ellipsis;
  overflow: hidden;
}

.mobile-sidebar-toggle {
  float: left;
  display: block;
  color: #fff;
  padding: 11px 10px 9px 10px;
  margin-right: 5px;
  display: none;
}

.mobile-sidebar-toggle:hover {
  background: #1e74c5;
  text-decoration: none;
  color: #fff;
}

.theme-settings > li {
  padding: 0;
  margin-top: 10px;
}

.theme-settings > li:first-child {
  margin-top: 5px;
}

.theme-settings > li span {
  color: #666;
  display: block;
  padding-left: 15px;
}

.theme-settings > li .version-toggle > a,
.theme-settings > li .topbar-toggle > a,
.theme-settings > li .sidebar-toggle > a {
  display: block;
  padding: 5px 25px;
  color: #444;
  text-decoration: none;
}

.theme-settings > li .version-toggle > a.active,
.theme-settings > li .topbar-toggle > a.active,
.theme-settings > li .sidebar-toggle > a.active {
  position: relative;
}

.theme-settings > li .version-toggle > a.active:before,
.theme-settings > li .topbar-toggle > a.active:before,
.theme-settings > li .sidebar-toggle > a.active:before {
  position: absolute;
  left: 10px;
  top: 4px;
  content: '\F111';
  font-family: FontAwesome;
  font-size: 8px;
}

.theme-settings > li .version-toggle > a:hover,
.theme-settings > li .topbar-toggle > a:hover,
.theme-settings > li .sidebar-toggle > a:hover {
  background: #ddd;
}

.theme-colors {
  margin: 0;
  padding: 0 0 5px 0;
  *zoom: 1;
}

.theme-colors:after {
  content: '';
  display: table;
  clear: both;
}

.theme-colors li {
  *zoom: 1;
}

.theme-colors li:after {
  content: '';
  display: table;
  clear: both;
}

.theme-colors li.subtitle {
  margin: 10px 0 5px 15px;
  display: block;
  float: none;
  color: #666;
  clear: both;
}

.theme-colors li span {
  cursor: pointer;
  display: block;
  width: 30px;
  float: left;
  height: 30px;
  margin: 8px 0 0 8px;
}

.theme-colors li span.r42green {
  background: #33b062;
}

#navigation {
  background: #368ee0;
  position: relative;
  z-index: 100;
}

#navigation.navbar-fixed-top {
  position: fixed;
  z-index: 1030;
}

#navigation .dropdown-menu {
  border-top: 0;
  border-color: #368ee0;
}

#navigation .dropdown-menu > li.dropdown-submenu .dropdown-menu {
  border-top: 2px solid #368ee0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  margin-left: 0;
}

#navigation .dropdown-menu > li.dropdown-submenu:hover > a,
#navigation .dropdown-menu > li.dropdown-submenu:focus > a {
  background: #368ee0;
  color: #fff;
}

#navigation .dropdown-menu > li.dropdown-submenu:hover > a:after,
#navigation .dropdown-menu > li.dropdown-submenu:focus > a:after {
  border-left-color: #fff;
}

#navigation .dropdown-menu > li > a:hover {
  background: #368ee0;
  color: #fff;
  filter: none;
}

#navigation .toggle-nav {
  float: left;
  color: #fff;
  display: block;
  padding: 10px;
  font-size: 14px;
  text-decoration: none;
}

#navigation .toggle-nav:hover {
  background-color: #1b67af;
}

#navigation #brand {
  float: left;
  color: #fff;
  font-size: 20px;
  margin-top: 9px;
  padding-right: 69px;
  padding-left: 35px;
  padding-bottom: 2px;
  background: url(/home/pecodanajlovski/Projects/Relay42/webui/ui/build/assetsimg/logo.png) no-repeat;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2) {
  #navigation #brand {
    background: url(/home/pecodanajlovski/Projects/Relay42/webui/ui/build/assetsimg/logo@2x.png) no-repeat;
    background-size: 22px 22px;
  }
}

#navigation #brand:hover {
  text-decoration: none;
}

#navigation .toggle-mobile {
  color: #fff;
  background: #1b67af;
  display: block;
  padding: 11px 10px 9px 10px;
  text-decoration: none;
  float: right;
}

#navigation .toggle-mobile i {
  font-size: 18px;
}

#navigation .mobile-nav {
  display: none;
}

#navigation .mobile-nav > li > ul {
  display: none;
}

#navigation .mobile-nav.open {
  display: block;
  list-style: none;
  padding: 0 20px 20px 20px;
  margin: 0;
  background: #368ee0;
}

#navigation .mobile-nav.open > li {
  display: block;
  margin-top: 1px;
}

#navigation .mobile-nav.open > li:first-child {
  margin-top: 0;
}

#navigation .mobile-nav.open > li.active > a {
  background: #144d82;
  color: #fff;
}

#navigation .mobile-nav.open > li.active > ul {
  background: #144d82;
}

#navigation .mobile-nav.open > li > a {
  display: block;
  background: #1b67af;
  padding: 8px 12px;
  color: #fff;
  text-decoration: none;
}

#navigation .mobile-nav.open > li > a i {
  float: right;
  margin-top: 3px;
}

#navigation .mobile-nav.open > li > ul {
  background: #1b67af;
  list-style-type: none;
  margin: 0;
  padding: 0 0 10px 0;
}

#navigation .mobile-nav.open > li > ul.open {
  display: block;
}

#navigation .mobile-nav.open > li > ul > li a {
  padding: 4px 35px;
  display: block;
  color: #fff;
  text-decoration: none;
}

#navigation .mobile-nav.open > li > ul > li ul {
  list-style-type: none;
}

#navigation .user .icon-nav {
  float: left;
  font-family: 'Open Sans', sans-serif !important;
}

#navigation .user .icon-nav > li {
  margin-right: 3px;
  float: left;
}

#navigation .user .icon-nav > li .message-ul {
  width: 280px;
  padding: 0;
}

#navigation .user .icon-nav > li .message-ul.feed > li > a {
  padding: 10px 20px;
}

#navigation .user .icon-nav > li .message-ul.feed > li > a .message {
  color: #333;
}

#navigation .user .icon-nav > li .message-ul.feed > li > a .message .label {
  padding: 2px;
  margin-right: 5px;
}

#navigation .user .icon-nav > li .message-ul > li > a {
  overflow: hidden;
  *zoom: 1;
  padding: 10px;
}

#navigation .user .icon-nav > li .message-ul > li > a:hover {
  background: #f3f3f3;
  color: #333;
}

#navigation .user .icon-nav > li .message-ul > li > a:hover .details .message {
  color: #444;
}

#navigation .user .icon-nav > li .message-ul > li > a:hover .count {
  color: #ccc;
}

#navigation .user .icon-nav > li .message-ul > li > a:hover .count span {
  color: #333;
}

#navigation .user .icon-nav > li .message-ul > li > a img {
  float: left;
  width: 40px;
}

#navigation .user .icon-nav > li .message-ul > li > a .details {
  float: left;
  margin-left: 10px;
  max-width: 210px;
  overflow: hidden;
}

#navigation .user .icon-nav > li .message-ul > li > a .details .name {
  font-size: 15px;
}

#navigation .user .icon-nav > li .message-ul > li > a .details .message {
  margin-top: 2px;
  color: #777;
}

#navigation .user .icon-nav > li .message-ul > li > a .count {
  float: right;
  color: #e3e3e3;
  font-size: 30px;
  position: relative;
  margin-top: 2px;
}

#navigation .user .icon-nav > li .message-ul > li > a .count span {
  font-size: 13px;
  color: #666;
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  top: 5px;
}

#navigation .user .icon-nav > li .message-ul > li > a.more-messages {
  background: #e6e6e6;
  text-align: center;
  padding: 8px 15px;
}

#navigation .user .icon-nav > li .message-ul > li > a.more-messages:hover {
  background: #ddd;
  color: #333;
}

#navigation .user .icon-nav > li > a {
  padding: 11px 10px 9px 10px;
  display: block;
  color: #fff;
  position: relative;
}

#navigation .user .icon-nav > li > a .label {
  position: absolute;
  top: 5px;
  right: 3px;
  font-size: 11px;
  font-weight: 400;
  padding: 0 3px;
}

#navigation .user .icon-nav > li > a:hover {
  background: #1b67af;
  text-decoration: none;
}

#navigation .user .icon-nav > li > a i {
  color: #fff;
  font-size: 16px;
}

#navigation .user .dropdown {
  float: left;
}

#navigation .user .dropdown.open {
  background: #1b67af;
}

#navigation .user .dropdown > a {
  display: block;
  color: #fff;
  padding: 7px 12px 6px 12px;
  text-decoration: none;
}

#navigation .user .dropdown > a:hover {
  background: #1b67af;
}

#navigation .user .dropdown > a img {
  margin-left: 10px;
}

#navigation .main-nav {
  float: left;
}

#navigation .main-nav > li {
  float: left;
  position: relative;
  margin: 0;
}

#navigation .main-nav > li.active > a {
  background: #fff;
  text-decoration: none;
  color: #2a2a2a;
}

#navigation .main-nav > li.active > a .caret {
  border-top-color: #333;
}

#navigation .main-nav > li.active > a:hover {
  background: #fff;
}

#navigation .main-nav > li.open > a {
  background: #1e74c5;
  text-decoration: none;
}

#navigation .main-nav > li.open.active > a {
  background: #fff;
}

#navigation .main-nav > li.open.active > .dropdown-menu {
  left: -2px;
}

#navigation .main-nav > li.open.active > .dropdown-menu > .active > a {
  background: #ddd;
  color: #333;
  filter: none;
}

#navigation .main-nav > li.open.active > .dropdown-menu > .active > a:after {
  border-left-color: #666;
}

#navigation .main-nav > li > a {
  display: block;
  padding: 10px 15px;
  color: #fff;
}

#navigation .main-nav > li > a:hover {
  text-decoration: none;
  background: #1e74c5;
}

#navigation .main-nav > li > a .caret {
  border-top-color: #fff;
  margin: 9px 0 0 2px;
}

#navigation .user {
  display: flex;
  align-items: center;
  float: right;
  height: 40px;
}

#navigation .user > a {
  color: #fff;
}

.highlight-toolbar {
  *zoom: 1;
  padding: 0 10px;
}

.highlight-toolbar:after {
  content: '';
  display: table;
  clear: both;
}

.highlight-toolbar .dropdown .btn .caret {
  margin-left: 5px;
}

.sortable-box .box-title:hover {
  cursor: move;
}

.box.box-color .box-title {
  background: #368ee0;
}

.box.box-color .box-title h3 {
  color: #fff;
}

.box.box-color .box-title .tabs > li.active > a {
  background: #fff;
  color: #368ee0;
}

.box.box-color .box-title .tabs > li > a {
  color: #fff;
}

.box.box-color .box-title .tabs > li > a:hover {
  color: #368ee0;
}

.box.box-color .box-title .actions > a {
  color: #fff;
}

.box.box-color .box-title .actions > a:hover {
  background: #fff;
  color: #368ee0;
}

.box.box-bordered .table {
  margin-bottom: 0;
}

.box.box-bordered .table.dataTable-fixedcolumn.DTFC_Cloned {
  border-right: 2px solid #ddd;
}

.box.box-bordered .table.dataTable-fixedcolumn.DTFC_Cloned th {
  border-right: 2px solid #333;
}

.box.box-bordered .table.dataTable-fixedcolumn.DTFC_Cloned td {
  text-align: center;
}

.box.box-bordered .table.table-bordered {
  border-right: 0;
}

.box.box-bordered .table.table-bordered th:first-child,
.box.box-bordered .table.table-bordered td:first-child {
  border-left: 0;
}

.box.box-bordered .table.table-bordered th:last-child,
.box.box-bordered .table.table-bordered td:last-child {
  border-right: 0;
}

.box.box-bordered .form-bordered {
  margin-bottom: 0;
}

.box.box-bordered .slimScrollDiv {
  border-bottom: 2px solid #ddd;
}

.box.box-bordered.box-color .slimScrollDiv {
  border-bottom: 2px solid #368ee0;
}

.box.box-bordered.box-color .box-title {
  border-color: #368ee0;
}

.box.box-bordered.box-color .box-content {
  border-color: #368ee0;
}

.box.box-bordered .box-title {
  border: 2px solid #ddd;
}

.box.box-bordered .box-content {
  border: 2px solid #ddd;
  border-top: 0;
}

.box .box-title {
  *zoom: 1;
  border-bottom: 1px solid #ddd;
  padding: 7px 0 7px 10px;
  margin-top: 20px;
}

.box .box-title:after {
  content: '';
  display: table;
  clear: both;
}

.box .box-title h3 {
  float: left;
  margin: 3px 0 3px 0;
  line-height: 24px;
  font-weight: 400;
  color: #444;
  font-size: 20px;
}

.box .box-title h3 i {
  margin: 0 10px;
}

.box .box-title .tabs {
  float: right;
  *zoom: 1;
  margin: 5px 10px 0 0;
  padding: 0;
  list-style-type: none;
}

.box .box-title .tabs:after {
  content: '';
  display: table;
  clear: both;
}

.box .box-title .tabs.tabs-left {
  float: left;
}

.box .box-title .tabs > li {
  float: left;
  margin-right: 5px;
}

.box .box-title .tabs > li:last-child {
  margin-right: 0;
}

.box .box-title .tabs > li > a {
  padding: 4px 8px;
}

.box .box-title .tabs > li > a:hover {
  text-decoration: none;
  background: #eee;
}

.box .box-title .tabs > li.active > a {
  background: #368ee0;
  color: #fff;
}

.box .box-title .actions {
  margin-top: 0px;
  float: right;
  margin-right: 10px;
}

.box .box-title .actions .content-move {
  cursor: move;
}

.box .box-content {
  *zoom: 1;
  padding: 20px;
  background: #fff;
}

.box .box-content:after {
  content: '';
  display: table;
  clear: both;
}

.custom-checkbox {
  position: relative;
  padding-right: 30px !important;
}

.custom-checkbox i {
  position: absolute;
  right: 10px;
  top: 9px;
}

.check-demo-col {
  width: 200px;
  float: left;
}

.check-line {
  position: relative;
  margin-bottom: 10px;
}

.check-line .inline {
  position: absolute;
  top: -1px;
  left: 30px;
}

.search-form {
  height: 35px;
  margin: 0;
}

.search-form > .search-pane {
  margin: 5px;
  height: 28px;
  background: #fff;
  border: 1px solid #ccc;
}

.search-form > .search-pane > input {
  background: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border: 0;
  float: left;
  font-size: 12px;
  margin-bottom: 0;
  margin-top: 1px;
  width: 145px;
}

.search-form > .search-pane > input:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.search-form > .search-pane button[type='submit'] {
  float: right;
  background: #368ee0;
  color: #fff;
  border: 0;
  margin: 2px 2px 0px 0;
  line-height: 19px;
  min-height: 24px;
}

.form-messages {
  margin-bottom: 0;
}

.form-vertical .control-label small {
  margin-left: 20px;
}

.form-vertical.form-bordered .control-group {
  border-bottom: 1px solid #ddd;
  padding: 10px 20px;
}

.form-striped .control-group:nth-child(odd) {
  background: #f6f6f6;
}

.form-striped .control-group {
  margin-bottom: 0;
}

.form-horizontal .help-block .progress {
  margin-bottom: 0;
}

.form-horizontal.form-striped .control-group {
  padding: 15px 20px;
  border-bottom: 1px solid #ddd;
}

.form-horizontal.form-column {
  *zoom: 1;
}

.form-horizontal.form-column:after {
  content: '';
  display: table;
  clear: both;
}

.form-horizontal.form-column .form-actions {
  border-top: 1px solid #ddd;
  margin-top: 0;
}

.form-horizontal.form-column [class*='span'] {
  margin: 0;
  border-left: 1px solid #ddd;
}

.form-horizontal.form-column [class*='span'] .control-group .control-label {
  width: 15%;
}

.form-horizontal.form-column [class*='span']:first-child {
  border-left: 0;
}

.form-horizontal.form-column .span12 {
  width: 100%;
  border-left: 0 !important;
}

.form-horizontal.form-column .span11 {
  width: 91.666%;
}

.form-horizontal.form-column .span10 {
  width: 83.333%;
}

.form-horizontal.form-column .span9 {
  width: 75%;
}

.form-horizontal.form-column .span8 {
  width: 66.666%;
}

.form-horizontal.form-column .span7 {
  width: 58.333%;
}

.form-horizontal.form-column .span6 {
  width: 50%;
}

.form-horizontal.form-column .span5 {
  width: 41.666%;
}

.form-horizontal.form-column .span4 {
  width: 33.333%;
}

.form-horizontal.form-column .span3 {
  width: 25%;
}

.form-horizontal.form-column .span2 {
  width: 16.666%;
}

.form-horizontal.form-column .span1 {
  width: 8.333%;
}

.form-horizontal .control-group .control-label.right {
  text-align: right;
}

.form-horizontal .control-group .control-label small {
  display: block;
  color: #999;
}

.form-horizontal.form-bordered .control-group {
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #ddd;
  background: #f6f6f6;
}

.form-horizontal.form-bordered .control-group:last-child {
  border-bottom: 0;
}

.form-horizontal.form-bordered .control-group .controls {
  padding: 10px;
  background: #fff;
  border-left: 1px solid #ddd;
  *zoom: 1;
}

.form-horizontal.form-bordered .control-group .controls:after {
  content: '';
  display: table;
  clear: both;
}

.form-horizontal.form-bordered .control-group .control-label {
  margin: 0;
  padding: 15px 10px 5px 10px;
  color: #555;
}

.form-horizontal.form-bordered .control-group .control-label small {
  display: block;
  font-size: 12px;
  color: #888;
  font-weight: normal;
}

.form-button {
  display: block;
  *zoom: 1;
  margin-top: 5px;
}

.form-button:after {
  content: '';
  display: table;
  clear: both;
}

.form-button a {
  float: left;
}

.form-button span {
  margin-left: 5px;
  font-size: 14px;
  margin-top: 5px;
  display: block;
  float: left;
}

.form-button span i {
  margin-right: 5px;
}

[class*='span'] .form-actions {
  margin-bottom: 0;
}

.messages {
  padding: 0;
  list-style-type: none;
  margin: 0 20px 20px 20px;
}

.messages.withlist {
  margin-right: 210px;
}

.messages > li {
  margin-top: 20px;
}

.messages > li.insert {
  position: relative;
  border: 1px solid #ddd;
  margin-right: 10px;
}

.messages > li.insert #message-form {
  background: #f9f9f9;
  margin-bottom: 0;
}

.messages > li.insert .text {
  margin-right: 50px;
}

.messages > li.insert .text input[type='text'] {
  border: 0;
  margin-bottom: 0;
  display: block;
}

.messages > li.insert .submit {
  position: absolute;
  right: 0;
  top: 0;
}

.messages > li.insert .submit button {
  border: 0;
  background: #368ee0;
  color: #fff;
  margin: 2px;
  padding: 3px 4px;
}

.messages > li.insert .submit button[disabled='disabled'] {
  background: #ccc;
}

.messages > li .image {
  float: left;
  width: 50px;
}

.messages > li .message {
  position: relative;
  background: #eee;
  margin-right: 20px;
  padding: 10px;
  margin-left: 60px;
}

.messages > li .message .name {
  font-weight: 500;
}

.messages > li .message p {
  color: #444;
}

.messages > li .message .time {
  color: #555;
  font-size: 11px;
}

.messages > li .message .caret {
  position: absolute;
  left: -7px;
  top: 10px;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-right: 7px solid #eee;
  border-left: 0;
}

.messages > li.right .image {
  float: right;
}

.messages > li.right .name {
  color: #0a2640;
}

.messages > li.right .message {
  background: #d1e5f8;
  margin-left: 20px;
  margin-right: 0;
  margin-right: 60px;
}

.messages > li.right .message p {
  color: #11406c;
}

.messages > li.right .message .time {
  color: #11406c;
}

.messages > li.right .message .caret {
  left: auto;
  right: -7px;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 7px solid #d1e5f8;
  border-right: 0;
}

.messages > li.typing {
  display: none;
}

.messages > li.typing.active {
  display: block;
}

.user-list {
  position: absolute;
  right: 0;
  top: 0;
  width: 183px;
  margin-right: 17px;
}

.user-list h4 {
  background: #368ee0;
  color: #fff;
  padding: 5px 8px;
  font-weight: 400;
}

.user-list > ul > li {
  *zoom: 1;
  margin: 5px 0;
}

.user-list > ul > li:after {
  content: '';
  display: table;
  clear: both;
}

.user-list > ul > li:first-child {
  margin-top: 0;
}

.user-list > ul > li.invite {
  margin-top: 15px;
}

.user-list > ul > li .image {
  max-width: 35px;
  min-width: 10px;
  float: left;
}

.user-list > ul > li .image img {
  max-width: 35px;
  min-width: 10px;
}

.user-list > ul > li .username {
  float: left;
  margin-left: 10px;
  line-height: 35px;
}

.statistic-big .top {
  *zoom: 1;
}

.statistic-big .top:after {
  content: '';
  display: table;
  clear: both;
}

.statistic-big .top .left {
  float: left;
}

.statistic-big .top .right {
  float: right;
  font-size: 24px;
  line-height: 31px;
}

.statistic-big .top .right .icon-circle-arrow-up {
  color: #339933;
}

.statistic-big .top .right .icon-circle-arrow-right {
  color: #f8a31f;
}

.statistic-big .top .right .icon-circle-arrow-down {
  color: #e63a3a;
}

.statistic-big .bottom {
  margin-top: 30px;
}

.statistic-big .stats-overview {
  text-align: center;
}

.statistic-big .stats-overview > li {
  display: inline-block;
  text-align: center;
  padding: 5px 15px;
  border-right: 1px solid #ddd;
}

.statistic-big .stats-overview > li:last-child {
  border-right: 0;
}

.statistic-big .stats-overview > li .name {
  display: block;
}

.statistic-big .stats-overview > li .value {
  font-size: 16px;
  font-weight: 500;
  display: block;
  margin-top: 5px;
}

.flot-tooltip {
  position: absolute;
  display: none;
  background: #000;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8;
  color: #fff;
  padding: 5px;
}

.flot-tooltip .tooltip-arrow {
  position: absolute;
  border-color: transparent;
  border-style: solid;
  margin-left: -5px;
  bottom: -5px;
  left: 15px;
  border-top-color: #000000;
  border-width: 5px 5px 0;
}

.pagestats {
  text-align: center;
}

.pagestats > li {
  display: inline-block;
}

.pagestats.style-3 .spark {
  float: none;
  margin: 0;
}

.pagestats.style-3 .bottom {
  margin-top: 10px;
  text-align: center;
  font-size: 20px;
}

.pagestats.style-3 .bottom .name {
  font-size: 14px;
}

.pagestats.style-4 .spark {
  float: none;
  margin: 0;
}

.pagestats.style-4 .bottom {
  margin-top: 10px;
  font-size: 18px;
}

.pagestats.style-4 .name {
  font-size: 13px;
}

.pagestats.style-2 {
  margin-top: 5px;
}

.pagestats.style-2 .left {
  font-size: 20px;
}

.pagestats.style-2 .left .name {
  font-size: 13px;
  margin: 0;
}

.pagestats .name {
  font-size: 20px;
  display: block;
  margin-left: 5px;
  margin-bottom: 5px;
}

.pagestats.bar span {
  font-size: 11px;
  display: block;
}

.pagestats .left {
  float: left;
  margin-left: 15px;
  font-size: 16px;
  margin-top: 3px;
}

.pagestats .spark {
  float: left;
  margin-left: 15px;
}

.table.table-hover tr:hover > td {
  background: #f8f8f8;
}

.table .with-checkbox {
  width: 10px;
}

.table .with-checkbox input {
  margin-left: 4px;
  margin-top: 0;
}

.table.dataTable {
  margin-bottom: 10px;
  clear: both;
}

.table.dataTable.dataTable-columnfilter .filter_column input {
  margin-bottom: 0;
  width: 100%;
  padding: 4px 0;
  text-indent: 8px;
}

.table.dataTable.dataTable-columnfilter .thefilter th {
  background: #e1e1e1;
}

.table.dataTable .sorting_asc {
  background: url(../img/sorting-asc.png) no-repeat right center;
}

.table.dataTable .sorting_desc {
  background: url(../img/sorting-desc.png) no-repeat right center;
}

.table.dataTable .sorting {
  background: url(../img/sorting.png) no-repeat right center;
}

.table.dataTable .sorting,
.table.dataTable .sorting_desc,
.table.dataTable .sorting_asc {
  padding-right: 30px;
  white-space: nowrap;
}

.table.dataTable.dataTable-scroll-x {
  border-collapse: separate;
  max-width: none;
}

.table.dataTable.dataTable-scroll-x td {
  white-space: nowrap;
}

.table.dataTable th:focus {
  outline: none;
}

.table.dataTable.dataTable-reorder th:hover {
  cursor: move;
}

.table.table-colored-header thead th,
.table.table-colored-header thead td {
  background: #368ee0;
  color: #fff;
}

.table.table-nohead thead {
  display: none;
}

.table.table-nohead thead th,
.table.table-nohead thead td {
  display: none;
}

.table.table-nohead tr:first-child td {
  border: 0;
}

.table tr td .label {
  margin-right: 3px;
}

.table tr td.icons {
  white-space: nowrap;
}

.table tr th {
  background: #eee;
}

.table.table-user tr.alpha td.alpha-val span {
  background: #368ee0;
  color: #fff;
  display: block;
  padding: 10px 0;
  text-align: center;
  font-size: 18px;
}

.table.table-user tr.alpha:hover td {
  background: white;
  cursor: pointer;
}

.table.table-user tr:first-child td {
  border: 0;
}

.table.table-user tr td {
  border-top: 1px dotted #ccc;
}

.table.table-user tr td.img {
  width: 40px;
}

.table.table-user tr td.img img {
  width: 40px;
}

.table.table-user tr td.user {
  text-align: left;
  vertical-align: middle;
}

.table.table-user tr td.icon {
  width: 20px;
  vertical-align: middle;
}

.table.table-user tr td.icon .online {
  font-size: 10px;
  color: #4fa828;
}

.table-mail tbody tr > td {
  cursor: pointer;
}

.table-mail tbody tr.warning > td {
  background-color: #deecfa;
}

.table-mail .table-checkbox {
  width: 15px;
}

.table-mail .table-icon {
  width: 20px;
}

.table-mail .table-icon .sel-star {
  color: #aaa;
  text-decoration: none;
}

.table-mail .table-icon .sel-star:hover {
  color: #f8a31f;
}

.table-mail .table-icon .sel-star.active {
  color: #f8a31f;
}

.table-mail .table-date {
  width: 100px;
}

.table-mail .table-fixed-medium {
  width: 200px;
}

.tabs.tabs-inline {
  *zoom: 1;
}

.tabs.tabs-inline:after {
  content: '';
  display: table;
  clear: both;
}

.tabs.tabs-inline.tabs-top > li {
  float: left;
  margin-right: 1px;
}

.tabs.tabs-inline.tabs-top > li.active > a {
  background: #fff;
  color: #333;
}

.tabs.tabs-inline.tabs-top > li.active > a:hover {
  background: #fff;
}

.tabs.tabs-inline.tabs-top > li > a {
  color: #666;
  background: #ddd;
  padding: 10px 20px;
  text-decoration: none;
  display: block;
}

.tabs.tabs-inline.tabs-top > li > a:hover {
  background: #ddd;
  color: #333;
}

.tabs.tabs-inline.tabs-left {
  position: absolute;
  background: #eee;
  width: 150px;
}

.tabs.tabs-inline.tabs-left > li:first-child > a {
  border-top: 0;
}

.tabs.tabs-inline.tabs-left > li:last-child > a {
  border-bottom: 1px solid #bbb;
}

.tabs.tabs-inline.tabs-left > li.write > a {
  margin: 10px;
  background: #e63a3a;
  color: #fff;
}

.tabs.tabs-inline.tabs-left > li.write > a:hover {
  background: #d21b1b;
  color: #fff;
}

.tabs.tabs-inline.tabs-left > li.active > a {
  background: #fff;
  color: #333;
  margin-right: -1px;
}

.tabs.tabs-inline.tabs-left > li.active > a:hover {
  background: #fff;
}

.tabs.tabs-inline.tabs-left > li > a {
  display: block;
  padding: 10px 20px;
  color: #666;
  background: #ddd;
  text-decoration: none;
  border-top: 1px solid #bbb;
}

.tabs.tabs-inline.tabs-left > li > a:hover {
  background: #ddd;
  color: #333;
}

.tab-content.padding {
  padding: 20px;
}

.tab-content.tab-content-inline {
  border-left: 1px solid #bbb;
  margin-left: 150px;
}

.tab-content.tab-content-inline.tab-content-bottom {
  border-left: 0;
  margin-left: 0;
}

.stats {
  float: left;
}

.stats > li {
  *zoom: 1;
  float: left;
  padding: 10px 15px;
  margin-right: 15px;
}

.stats > li:after {
  content: '';
  display: table;
  clear: both;
}

.stats > li:last-child {
  margin-right: 0;
}

.stats > li i {
  font-size: 36px;
  color: #fff;
  float: left;
  margin: 0;
}

.stats > li .details {
  *zoom: 1;
  margin-left: 55px;
}

.stats > li .details:after {
  content: '';
  display: table;
  clear: both;
}

.stats > li .details span {
  color: #fff;
  display: block;
  font-size: 12px;
}

.stats > li .details span.big {
  font-size: 16px;
}

.tiles {
  list-style-type: none;
  margin: 25px 0;
  padding: 0;
}

.tiles .has-chart > a span {
  font-size: 16px;
  padding: 10px 20px;
}

.tiles .has-chart-2 > a span {
  font-size: 16px;
  padding-top: 0;
  display: inline-block;
}

.tiles .has-chart-2 > a span .spark-me {
  padding-top: 40px;
}

.tiles .has-chart-2 > a span .spark-me canvas {
  margin-top: -10px;
}

.tiles.tiles-small .label {
  padding: 3px 5px;
}

.tiles.tiles-small > li:hover:before {
  width: 70px;
  height: 70px;
}

.tiles.tiles-small > li > a {
  width: 80px;
  height: 80px;
}

.tiles.tiles-small > li > a span {
  font-size: 30px;
  padding-top: 20px;
}

.tiles .label {
  padding: 5px 8px;
}

.tiles.tiles-center {
  text-align: center;
  width: 100%;
}

.tiles.tiles-center > li {
  display: inline-block;
  text-align: center;
  float: none;
}

.tiles.tiles-center > li > a .name {
  text-align: center;
}

.tiles.nomargin {
  margin: 0;
}

.tiles > li {
  float: left;
  margin: 8px 0 0 8px;
  position: relative;
}

.tiles > li .label {
  position: absolute;
  top: -5px;
  left: -5px;
  z-index: 99;
}

.tiles > li:hover:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 110px;
  height: 110px;
  background: none;
  pointer-events: none;
  border: 5px solid rgba(0, 0, 0, 0.5);
  z-index: 99;
}

.tiles > li.long:hover:before {
  width: 235px;
}

.tiles > li.long > a {
  width: 245px;
}

.tiles > li.long > a span {
  font-size: 64px;
  padding-top: 25px;
}

.tiles > li.high:hover:before {
  height: 235px;
}

.tiles > li.high > a {
  height: 245px;
}

.tiles > li.high > a span {
  padding-top: 75px;
  font-size: 96px;
}

.tiles > li.high > a span.count > i {
  margin-right: -25px;
}

.tiles > li.image > a img {
  width: 120px;
  height: 120px;
}

.tiles > li.image > a .name {
  background: #368ee0;
  color: #fff;
}

.tiles > li > a {
  width: 120px;
  height: 120px;
  display: block;
  color: #fff;
  text-decoration: none;
  position: relative;
  text-align: center;
}

.tiles > li > a span {
  padding-top: 35px;
  font-size: 48px;
  display: block;
}

.tiles > li > a span.nopadding {
  padding: 0;
}

.tiles > li > a span.count > i {
  margin-right: -10px;
}

.tiles > li > a span h5 {
  font-size: 18px !important;
  margin: 0;
  text-align: left;
  padding: 10px 10px 5px 10px;
}

.tiles > li > a span p {
  font-size: 13px !important;
  padding: 0;
  text-align: left;
  padding: 0 20px;
}

.tiles > li > a .name {
  font-size: 13px !important;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: left;
  padding: 3px 10px;
  float: left;
}

.tiles > li > a .name .right {
  font-size: 13px;
  float: right;
  padding: 0;
}

.minitiles {
  float: left;
}

.minitiles > li {
  float: left;
  margin-right: 15px;
}

.minitiles > li > a {
  display: block;
  height: 61px;
  width: 61px;
  color: #fff;
  text-decoration: none;
  position: relative;
  text-align: center;
}

.minitiles > li > a i {
  font-size: 36px;
  line-height: 61px;
}

.minitiles > li > a:hover:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 55px;
  height: 55px;
  background: none;
  pointer-events: none;
  border: 3px solid rgba(0, 0, 0, 0.5);
  z-index: 99;
}

body.error {
  background: #368ee0;
}

body.error .wrapper {
  width: 300px;
  height: 230px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -165px -150px;
}

body.error .wrapper .code {
  color: #fff;
  font-size: 90px;
  text-align: center;
}

body.error .wrapper .code span {
  margin-right: 15px;
}

body.error .wrapper .desc {
  color: #fff;
  font-size: 14px;
  margin: 20px 0;
}

body.error .wrapper .input-append {
  width: 300px;
  background: #fff;
  padding: 2px;
}

body.error .wrapper .input-append input {
  border: 0;
  width: 260px;
  background: #fff;
}

body.error .wrapper .input-append .btn {
  border: 0;
  background: #368ee0;
  color: #fff;
}

body.error .wrapper .input-append .btn:hover {
  background: #1b67af;
}

.grids .row-fluid {
  margin-top: 10px;
  margin-bottom: 20px;
}

.grids .row-fluid [class*='span'] {
  background: #ccc;
  text-align: center;
  line-height: 30px;
}

.grids .row-fluid [class*='span']:hover {
  background: #aaa;
}

.the-icons {
  list-style-type: none;
}

.the-icons li {
  margin: 5px 0;
}

#updateInterval {
  margin-bottom: 0;
}

.login {
  background: #368ee0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login .wrapper h1 > a {
  color: #fff;
  display: block;
  font-weight: 300;
  font-size: 48px;
  text-align: center;
  padding: 2px 0;
  text-decoration: none;
}

.login .wrapper h1 > a img {
  margin-right: 20px;
  margin-top: -8px;
}

.login .wrapper .login-body {
  background: #fff;
}

.login .wrapper .login-body form {
  padding: 0 30px 30px;
}

.login .wrapper .login-body h2 {
  font-weight: 300;
  color: #333;
  font-size: 23px;
  padding: 20px 30px 5px 30px;
}

.login .wrapper .login-body div.login-info {
  color: #333;
  font-size: 14px;
  padding: 5px 30px 10px 30px;
}

.login .wrapper .login-body .email input,
.login .wrapper .login-body .pw input {
  padding: 8px 12px;
  height: 35px;
}

.login .wrapper .login-body .submit {
  margin-top: 15px;
  *zoom: 1;
}

.login .wrapper .login-body .submit:after {
  content: '';
  display: table;
  clear: both;
}

.login .wrapper .login-body .submit input {
  float: right;
}

.login .wrapper .login-body .submit .remember {
  float: left;
  margin-top: 5px;
  margin-left: 1px;
}

.login .wrapper .login-body .submit .remember [class*='icheckbox'],
.login .wrapper .login-body .submit .remember label {
  float: left;
}

.login .wrapper .login-body .submit .remember label {
  margin-left: 10px;
}

.login .wrapper .login-body .forget {
  margin-top: 50px;
}

.login .wrapper .login-body .forget a {
  background: #eee;
  padding: 10px 0;
  text-align: center;
  display: block;
  text-decoration: none;
  color: #555;
}

.login .wrapper .login-body .forget a:hover {
  background: #ddd;
}

.login .wrapper .login-body .forget a span {
  line-height: 30px;
  margin-bottom: 10px;
  position: relative;
}

.login-footer {
  text-align: center;
  margin-top: 24px;
  color: #e3e3e3;
}

.login-footer > a {
  padding-left: 4px;
  text-decoration: underline;
  font-weight: 500;
  color: #fff;
}

.calendar {
  margin-top: 10px;
}

.calendar .fc-header .fc-header-title h2 {
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 0;
  line-height: 25px;
}

.calendar .fc-header .fc-header-right .fc-button {
  position: relative;
  top: -45px;
}

.calendar .fc-content .fc-event {
  font-size: 12px;
  border: 0 !important;
}

.calendar .fc-content .fc-event .fc-event-inner {
  background: #368ee0;
  border: 0 !important;
  margin: 0;
}

.calendar .fc-content .fc-event .fc-event-inner .fc-event-title {
  padding: 2px 5px;
}

.calendar .fc-content .fc-event .fc-event-inner .fc-event-time {
  padding: 2px 2px 2px 5px;
}

.calendar .fc-button {
  background: none;
  border: 0 !important;
  margin-right: 5px;
}

.calendar .fc-button.fc-state-active {
  background: #368ee0;
  color: #fff;
}

.calendar .fc-button.fc-state-active:hover {
  background: #368ee0;
  color: #fff;
}

.calendar .fc-button:hover {
  background: #ddd;
}

.calendar .fc-button.fc-button-next,
.calendar .fc-button.fc-button-prev {
  margin-left: 10px;
}

.calendar .fc-button.fc-button-next .fc-button-content,
.calendar .fc-button.fc-button-prev .fc-button-content {
  padding: 0 9px;
}

.calendar .fc-button.fc-button-next .fc-button-content i,
.calendar .fc-button.fc-button-prev .fc-button-content i {
  color: #666;
}

.calendar .fc-button.fc-button-next:hover .fc-button-content i,
.calendar .fc-button.fc-button-prev:hover .fc-button-content i {
  color: #333;
}

.calendar .fc-button.fc-button-prev {
  margin-right: 10px;
  margin-left: 0;
}

.calendar .fc-button .fc-button-inner {
  background: none;
  border: 0 !important;
}

.flot {
  width: 100%;
  height: 300px;
}

.flot.small {
  height: 150px;
}

.flot.medium {
  height: 200px;
}

.chzn-container .chzn-drop {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.chzn-container .chzn-drop .chzn-results > li.highlighted {
  background: #368ee0;
}

.chzn-container.chzn-container-single .chzn-search input {
  background: none;
}

.chzn-container.chzn-container-single .chzn-single {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  background: #fff;
  padding-top: 3px;
  padding-bottom: 3px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.chzn-container.chzn-container-single .chzn-single div b {
  background-position-y: 2px;
}

.chzn-container.chzn-container-multi .chzn-choices {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background: #fff;
}

.chzn-container.chzn-container-multi .chzn-choices .search-choice {
  background: #eee;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
}

.chzn-container.open .chzn-drop {
  left: 0px;
}

.chzn-container .chzn-results .active-result:hover {
  background-color: #33b062;
  color: #fff;
}

table.DTCR_clonedTable {
  background-color: white;
  z-index: 202;
  cursor: move;
}

div.DTCR_pointer {
  width: 1px;
  background-color: #368ee0;
  z-index: 201;
}

div.DTCR_pointer:before {
  content: '\F063';
  font-family: 'FontAwesome';
  position: absolute;
  top: -25px;
  left: -5px;
  color: #368ee0;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * ColVis styles
 */

.ColVis {
  float: right;
  margin-bottom: 1em;
}

.ColVis_Button {
  position: relative;
  float: left;
  margin-right: 3px;
  padding: 3px 5px;
  height: 30px;
  background-color: #fff;
  border: 1px solid #d0d0d0;
  cursor: pointer;
  *cursor: hand;
}

button.ColVis_Button::-moz-focus-inner {
  border: none !important;
  padding: 0;
}

.ColVis_text_hover {
  border: 1px solid #999;
  background-color: #f0f0f0;
}

div.ColVis_collectionBackground {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  z-index: 1100;
}

div.ColVis_collection {
  position: relative;
  width: 150px;
  background-color: #f3f3f3;
  padding: 3px;
  border: 1px solid #ccc;
  z-index: 1102;
}

div.ColVis_collection button.ColVis_Button {
  background-color: white;
  width: 100%;
  float: none;
  margin-bottom: 2px;
}

div.ColVis_collection button.ColVis_Group {
  background: #dadada;
  border: 1px solid #bababa;
}

div.ColVis_catcher {
  position: absolute;
  z-index: 1101;
}

.disabled {
  color: #999;
}

button.ColVis_Button {
  text-align: left;
}

div.ColVis_collection button.ColVis_Button:hover {
  border: 1px solid #999;
  background-color: #f0f0f0;
}

div.ColVis_collection button.ColVis_Group:hover {
  background: #bababa;
  border: 1px solid #424242;
}

span.ColVis_radio {
  display: inline-block;
  width: 20px;
}

.dataTables_wrapper .ColVis {
  margin: 10px 10px 10px 0;
}

.dataTables_wrapper .ColVis .ColVis_Button {
  background: #eee;
  padding: 3px 8px;
  border: 0;
}

.dataTables_wrapper .ColVis .ColVis_Button i {
  margin-left: 5px;
}

.dataTables_wrapper .ColVis .ColVis_Button:hover {
  background: #ddd;
}

.dataTables_wrapper .dataTables_length {
  float: left;
  min-width: 200px;
  margin: 10px 10px 5px 10px;
}

.dataTables_wrapper .dataTables_length .input-mini {
  float: left;
}

.dataTables_wrapper .dataTables_length label > span {
  float: left;
  margin: 4px 5px;
}

.dataTables_wrapper .DTTT_container {
  margin: 10px 5px 10px 0;
}

.dataTables_wrapper .DTTT_container .DTTT_button {
  background: #eee;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  border: 0;
  margin-right: 5px;
  color: #333;
}

.dataTables_wrapper .DTTT_container .DTTT_button:hover {
  background: #ddd;
}

.dataTables_wrapper .dataTables_filter {
  float: right;
  margin: 10px 10px 5px 5px;
}

.dataTables_wrapper .dataTables_filter input {
  margin-bottom: 0;
}

.dataTables_wrapper .dataTables_info {
  float: left;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.dataTables_wrapper .dataTables_info span {
  font-weight: 500;
}

.dataTables_wrapper .dataTables_paginate {
  float: right;
  margin-right: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.dataTables_wrapper .dataTables_paginate > a {
  margin-right: 5px;
  background: #eee;
  padding: 3px 8px;
  color: #333;
  text-decoration: none;
  cursor: pointer;
}

.dataTables_wrapper .dataTables_paginate > a:hover {
  background: #ddd;
}

.dataTables_wrapper .dataTables_paginate > span > a {
  padding: 3px 8px;
  margin-right: 5px;
  background: #eee;
  color: #333;
  text-decoration: none;
  cursor: pointer;
}

.dataTables_wrapper .dataTables_paginate > span > a.paginate_active {
  background: #368ee0;
  color: #fff;
}

.dataTables_scroll {
  clear: both;
}

.dataTables_scrollBody {
  *margin-top: -1px;
  -webkit-overflow-scrolling: touch;
}

.DTFC_Cloned {
  background: #eee;
  border-right: 1px solid #ddd;
}

div.DTS tbody th,
div.DTS tbody td {
  white-space: nowrap;
}

div.DTS tbody tr.even {
  background-color: white;
}

.alert.alert-nomargin {
  margin: 0;
}

.DTFC_LeftHeadWrapper {
  border-right: 1px solid #ddd;
}

div.DTS div.DTS_Loading {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  height: 20px;
  margin-top: -20px;
  margin-left: -100px;
  z-index: 1;
  border: 1px solid #999;
  padding: 20px 0;
  text-align: center;
  background-color: white;
  background-color: rgba(255, 255, 255, 0.5);
}

div.DTS div.dataTables_scrollHead {
  background-color: white;
}

div.DTS div.dataTables_scrollBody {
  z-index: 2;
}

.datepicker.datepicker-dropdown {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
}

.datepicker.datepicker-dropdown:before,
.datepicker.datepicker-dropdown:after {
  border: 0;
}

.datepicker.datepicker-dropdown .datepicker-days td,
.datepicker.datepicker-dropdown .datepicker-days th,
.datepicker.datepicker-dropdown .datepicker-days span,
.datepicker.datepicker-dropdown .datepicker-years td,
.datepicker.datepicker-dropdown .datepicker-years th,
.datepicker.datepicker-dropdown .datepicker-years span,
.datepicker.datepicker-dropdown .datepicker-months td,
.datepicker.datepicker-dropdown .datepicker-months th,
.datepicker.datepicker-dropdown .datepicker-months span {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
}

.datepicker.datepicker-dropdown .datepicker-days .active,
.datepicker.datepicker-dropdown .datepicker-years .active,
.datepicker.datepicker-dropdown .datepicker-months .active {
  background: #368ee0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
}

.datepicker.datepicker-dropdown .datepicker-days .active:hover,
.datepicker.datepicker-dropdown .datepicker-years .active:hover,
.datepicker.datepicker-dropdown .datepicker-months .active:hover {
  background: #1e74c5;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
}

.form-wizard {
  margin-bottom: 0;
}

.form-wizard .step .control-group {
  padding: 0 20px;
}

.form-wizard .form-actions {
  margin-top: 0;
  margin-bottom: 0;
}

.form-wizard.wizard-vertical {
  margin-bottom: 0;
}

.form-wizard.wizard-vertical .form-content {
  background: #fff;
  border-left: 1px solid #d6d6d6;
  margin-left: 220px;
  padding: 10px 10px;
  overflow: hidden;
}

.form-wizard.wizard-vertical .form-content .control-group {
  padding: 10px;
}

.form-wizard.wizard-vertical .form-content .control-group:last-child {
  margin-bottom: 0;
}

.form-wizard.wizard-vertical .step {
  background: #eee;
}

.form-wizard.wizard-vertical .wizard-steps {
  float: left;
  width: 220px;
  margin-bottom: 0;
  min-height: 200px;
}

.form-wizard.wizard-vertical .wizard-steps > li {
  float: none;
  width: 100% !important;
  *zoom: 1;
  display: block;
  background: #eee;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

.form-wizard.wizard-vertical .wizard-steps > li:after {
  content: '';
  display: table;
  clear: both;
}

.form-wizard.wizard-vertical .wizard-steps > li:first-child {
  border-top: 0;
}

.form-wizard.wizard-vertical .wizard-steps > li.active {
  background: #fff;
  position: relative;
  border-top: 1px solid #d6d6d6;
  border-bottom: 1px solid #d6d6d6;
}

.form-wizard.wizard-vertical .wizard-steps > li.active:first-child {
  border-top: 0;
}

.form-wizard.wizard-vertical .wizard-steps > li.active:after {
  position: absolute;
  content: '';
  top: 0;
  left: auto;
  bottom: 0;
  background: #ffffff;
  width: 1px;
  right: -1px;
  height: 100%;
  display: block;
}

.form-wizard.wizard-vertical .wizard-steps > li .single-step {
  *zoom: 1;
  padding: 10px;
}

.form-wizard.wizard-vertical .wizard-steps > li .single-step:after {
  content: '';
  display: table;
  clear: both;
}

.form-wizard.wizard-vertical .wizard-steps > li .title {
  float: left;
}

.form-wizard.wizard-vertical .wizard-steps > li .description {
  float: left;
  margin-left: 10px;
  margin-top: 2px;
}

.form-wizard.wizard-vertical .form-actions {
  padding-left: 240px;
  background: #fff;
  margin-bottom: 0;
  margin-top: 0;
  border-top: 1px solid #d6d6d6;
}

.form-wizard .wizard-steps {
  list-style-type: none;
  margin: 0 0 30px 0;
  padding: 0;
  *zoom: 1;
  background: #eee;
}

.form-wizard .wizard-steps:after {
  content: '';
  display: table;
  clear: both;
}

.form-wizard .wizard-steps li {
  float: left;
  text-align: center;
}

.form-wizard .wizard-steps li:last-child .single-step {
  border-right: 0;
}

.form-wizard .wizard-steps li .single-step {
  padding: 15px 0;
}

.form-wizard .wizard-steps li .single-step .title {
  text-align: center;
  font-size: 16px;
  background: #ddd;
  padding: 4px 10px;
  color: #888;
}

.form-wizard .wizard-steps li .single-step .description {
  margin-left: 5px;
  color: #aaa;
}

.form-wizard .wizard-steps li.active {
  background: #fff;
  position: relative;
}

.form-wizard .wizard-steps li.active:after {
  position: absolute;
  bottom: -1px;
  right: 0;
  left: 0;
  content: '';
  background: #ffffff;
  width: 100%;
  height: 1px;
  display: block;
}

.form-wizard .wizard-steps li.active .single-step .title {
  background: #368ee0;
  color: #fff;
}

.form-wizard .wizard-steps li.active .single-step .description {
  color: #333;
}

.form-wizard .wizard-steps.steps-2 li {
  width: 50%;
}

.form-wizard .wizard-steps.steps-3 li {
  width: 33.333%;
}

.form-wizard .wizard-steps.steps-4 li {
  width: 25%;
}

.form-wizard .wizard-steps.steps-5 li {
  width: 20%;
}

.ui-resizable-handle.ui-resizable-e {
  cursor: col-resize;
}

#ascrail2000 div {
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
}

.slimScrollBar.ui-draggable {
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
}

.select2-container .select2-choice {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  background-image: none;
  background: #fff;
}

.select2-container .select2-choice div {
  border-left: 0;
  background: none;
}

.select2-container.select2-drop-above .select2-choice {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  background-image: none;
}

.select2-container .select2-search-choice-close {
  top: 3px;
}

.select2-container .select2-choices {
  background-image: none;
}

.select2-results,
.select2-search,
.select2-with-searchbox {
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
}

.slider {
  margin: 10px 0 20px 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  height: 6px;
  position: relative;
  *zoom: 1;
}

.slider:after {
  content: '';
  display: table;
  clear: both;
}

.slider .amount {
  position: absolute;
  left: 0;
  top: -20px;
}

.slider .amount_min {
  position: absolute;
  left: 0;
  bottom: -25px;
}

.slider .amount_max {
  position: absolute;
  right: 0;
  bottom: -25px;
}

.slider .ui-slider-handle {
  border: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  background: #368ee0;
  width: 14px;
  height: 14px;
}

.ui-spinner {
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
  border-color: #ccc;
}

.ui-spinner .spinner {
  border: 0;
  margin-top: 0;
}

.ui-spinner .ui-spinner-button {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  opacity: 0.6;
}

.tagsinput#textfield_tagsinput {
  padding-bottom: 0;
}

.tagsinput#textfield_tagsinput .tag {
  background: #368ee0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  color: #fff;
  border: 0;
}

.tagsinput#textfield_tagsinput .tag a {
  color: #fff;
}

.bootstrap-timepicker-widget.dropdown-menu,
.colorpicker.dropdown-menu {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
}

.bootstrap-timepicker-widget.dropdown-menu:before,
.bootstrap-timepicker-widget.dropdown-menu:after,
.colorpicker.dropdown-menu:before,
.colorpicker.dropdown-menu:after {
  border: 0 !important;
}

html,
body {
  height: 100%;
}

body {
  background: #fff;
}

a {
  color: #368ee0;
}

a:focus {
  outline: none;
  text-decoration: none;
}

.ulreset,
#navigation .user .icon-nav,
.user-list > ul,
.statistic-big .stats-overview,
.pagestats,
.tabs,
.stats,
.minitiles,
.colors {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

#navigation .main-nav {
  list-style-type: none;
  padding: 0;
}

.nopadding {
  padding: 0 !important;
}

.row-fluid.margin-top {
  margin-top: 30px;
}

.visible-480 {
  display: none;
}

.box-sortable {
  background: #eee;
  border: 1px dotted #333;
}

.colors.small > li {
  height: 20px;
  width: 20px;
}

.colors.small > li > a,
.colors.small > li > span {
  height: 20px;
  width: 20px;
}

.colors > li {
  height: 40px;
  margin: 0 5px 5px 0;
  width: 40px;
  float: left;
}

.colors > li > a,
.colors > li > span {
  width: 40px;
  height: 40px;
  display: block;
}

.basic-margin > li,
.basic-margin > a {
  margin: 0 5px 5px 0;
}

.theme-r42green ul > li.blue {
  background: #368ee0;
}

.theme-r42green .label-blue {
  background: #368ee0;
}

.theme-r42green .btn.btn-blue {
  background: #368ee0;
  color: white;
}

.theme-r42green ul > li.orange {
  background: #f8a31f;
}

.theme-r42green .label-orange {
  background: #f8a31f;
}

.theme-r42green .btn.btn-orange {
  background: #f8a31f;
  color: white;
}

.theme-r42green ul > li.red {
  background: #e51400;
}

.theme-r42green .label-red {
  background: #e51400;
}

.theme-r42green .btn.btn-red {
  background: #e51400;
  color: white;
}

.theme-r42green ul > li.green {
  background: #339933;
}

.theme-r42green .label-green {
  background: #339933;
}

.theme-r42green .btn.btn-green {
  background: #339933;
  color: white;
}

.theme-r42green ul > li.lime {
  background: #8cbf26;
}

.theme-r42green .label-lime {
  background: #8cbf26;
}

.theme-r42green .btn.btn-lime {
  background: #8cbf26;
  color: white;
}

.theme-r42green ul > li.teal {
  background: #00aba9;
}

.theme-r42green .label-teal {
  background: #00aba9;
}

.theme-r42green .btn.btn-teal {
  background: #00aba9;
  color: white;
}

.theme-r42green ul > li.purple {
  background: #ff0097;
}

.theme-r42green .label-purple {
  background: #ff0097;
}

.theme-r42green .btn.btn-purple {
  background: #ff0097;
  color: white;
}

.theme-r42green ul > li.brown {
  background: #a05000;
}

.theme-r42green .label-brown {
  background: #a05000;
}

.theme-r42green .btn.btn-brown {
  background: #a05000;
  color: white;
}

.theme-r42green ul > li.pink {
  background: #e671b8;
}

.theme-r42green .label-pink {
  background: #e671b8;
}

.theme-r42green .btn.btn-pink {
  background: #e671b8;
  color: white;
}

.theme-r42green ul > li.magenta {
  background: #a200ff;
}

.theme-r42green .label-magenta {
  background: #a200ff;
}

.theme-r42green .btn.btn-magenta {
  background: #a200ff;
  color: white;
}

.theme-r42green ul > li.grey {
  background: #333333;
}

.theme-r42green .label-grey {
  background: #333333;
}

.theme-r42green .btn.btn-grey {
  background: #333333;
  color: white;
}

.theme-r42green ul > li.darkblue {
  background: #204e81;
}

.theme-r42green .label-darkblue {
  background: #204e81;
}

.theme-r42green .btn.btn-darkblue {
  background: #204e81;
  color: white;
}

.theme-r42green ul > li.lightred {
  background: #e63a3a;
}

.theme-r42green .label-lightred {
  background: #e63a3a;
}

.theme-r42green .btn.btn-lightred {
  background: #e63a3a;
  color: white;
}

.theme-r42green ul > li.lightgrey {
  background: #666666;
}

.theme-r42green .label-lightgrey {
  background: #666666;
}

.theme-r42green .btn.btn-lightgrey {
  background: #666666;
  color: white;
}

.theme-r42green ul > li.satblue {
  background: #2c5e7b;
}

.theme-r42green .label-satblue {
  background: #2c5e7b;
}

.theme-r42green .btn.btn-satblue {
  background: #2c5e7b;
  color: white;
}

.theme-r42green ul > li.satgreen {
  background: #56af45;
}

.theme-r42green .label-satgreen {
  background: #56af45;
}

.theme-r42green .btn.btn-satgreen {
  background: #56af45;
  color: white;
}

.theme-r42green ul > li.grey {
  background: #333333;
}

.theme-r42green .label-grey {
  background: #333333;
}

.theme-r42green .btn.btn-grey {
  background: #333333;
  color: white;
}

.font-r42green {
  color: #33b062;
}

ul > li.r42green,
.label-r42green {
  background: #33b062;
}

.progress .bar.bar-r42green {
  background: #33b062;
}

.pricing-tables .pricing.r42green > li.head {
  background: #33b062;
}

.pricing-tables .pricing.r42green > li.head .info {
  background: #39c46d;
}

.pricing-tables .pricing.r42green > li.head .name {
  background: #28884c;
}

.btn.btn-r42green {
  background: #33b062;
  color: #fff !important;
}

.btn.btn-r42green:hover {
  background: #28884c;
}

.box.box-color.r42green .box-title {
  background: #33b062 !important;
}

.box.box-color.r42green .box-title .actions > a:hover {
  color: #33b062;
}

.box.box-color.r42green .form-wizard .wizard-steps li.active .single-step .title {
  background: #33b062;
}

.box.box-color.r42green .btn.btn-primary {
  background: #33b062;
}

.box.box-bordered.box-color.r42green .box-title {
  border-color: #33b062 !important;
}

.box.box-bordered.box-color.r42green .box-content {
  border-color: #33b062 !important;
}

.box.box-bordered.box-color.r42green .slimScrollDiv {
  border-bottom: 2px solid #33b062;
}

.timeline > li .timeline-content .left .icon.r42green {
  background: #33b062;
}

.theme-r42green #navigation .dropdown-menu {
  border-color: #33b062;
}

.theme-r42green #navigation .dropdown-menu > li.dropdown-submenu .dropdown-menu {
  border-top: 2px solid #33b062;
}

.theme-r42green #navigation .dropdown-menu > li.dropdown-submenu:hover > a,
.theme-r42green #navigation .dropdown-menu > li.dropdown-submenu:focus > a {
  background: #33b062;
}

.theme-r42green #navigation .dropdown-menu > li > a:hover {
  background: #33b062;
}

.theme-r42green .datepicker.datepicker-dropdown .datepicker-days .active,
.theme-r42green .datepicker.datepicker-dropdown .datepicker-years .active,
.theme-r42green .datepicker.datepicker-dropdown .datepicker-months .active {
  background: #33b062;
}

.theme-r42green .datepicker.datepicker-dropdown .datepicker-days .active:hover,
.theme-r42green .datepicker.datepicker-dropdown .datepicker-years .active:hover,
.theme-r42green .datepicker.datepicker-dropdown .datepicker-months .active:hover {
  background: #28884c;
}

.theme-r42green .form-wizard .wizard-steps li.active .single-step .title {
  background: #33b062;
}

.theme-r42green .btn.btn-primary {
  background: #33b062;
}

.theme-r42green.login {
  background: #33b062;
}

// .theme-r42green a {
//   color: #33b062;
// }

.theme-r42green .btn {
  color: #333;
}

.theme-r42green .btn.btn-primary {
  background: #33b062;
  color: #fff;
}

.theme-r42green .btn.btn-inverse,
.theme-r42green .btn.btn-success,
.theme-r42green .btn.btn-danger {
  color: #fff;
}

.theme-r42green.locked {
  background: #33b062 !important;
}

.theme-r42green.locked .pull-left a {
  background: #227541;
}

.theme-r42green.locked .pull-left a:hover {
  background: #164d2b;
}

.theme-r42green #navigation {
  background: #33b062;
}

.theme-r42green #navigation .toggle-mobile {
  background: #227541;
}

.theme-r42green #navigation .mobile-nav.open {
  background: #33b062;
}

.theme-r42green #navigation .mobile-nav.open > li.active > a {
  background: #164d2b;
}

.theme-r42green #navigation .mobile-nav.open > li.active > ul {
  background: #164d2b;
}

.theme-r42green #navigation .mobile-nav.open > li > a {
  background: #227541;
}

.theme-r42green #navigation .mobile-nav.open > li > ul {
  background: #227541;
}

.theme-r42green #navigation .main-nav > li.active > a:hover {
  background: #fff;
}

.theme-r42green #navigation .main-nav > li.active.open > a {
  background: #fff;
}

.theme-r42green #navigation .main-nav > li.open > a {
  background: #227541;
}

.theme-r42green #navigation .main-nav > li > a:hover {
  background: #227541;
}

.theme-r42green #navigation .user .icon-nav > li > a:hover {
  background: #227541;
}

.theme-r42green #navigation .user .dropdown.open {
  background: #227541;
}

.theme-r42green #navigation .user .dropdown > a:hover {
  background: #227541;
}

.theme-r42green #navigation .dropdown-menu {
  border-color: #33b062;
}

.theme-r42green #navigation .dropdown-menu > li > a:hover {
  background: #33b062;
}

.theme-r42green .search-form .search-pane button[type='submit'] {
  background: #33b062;
}

.theme-r42green .messages > li.insert .submit button {
  background: #33b062;
}

.theme-r42green .messages > li.right .name {
  color: #05120a;
}

.theme-r42green .messages > li.right .message {
  background: #c2edd2;
}

.theme-r42green .messages > li.right .message p {
  color: #113920;
}

.theme-r42green .messages > li.right .message .time {
  color: #113920;
}

.theme-r42green .messages > li.right .message .caret {
  border-left: 7px solid #c2edd2;
}

.theme-r42green .letterbox .letter > li.active {
  background: #33b062;
}

.theme-r42green .calendar .fc-content .fc-event .fc-event-inner {
  background: #33b062;
}

.theme-r42green .calendar .fc-button.fc-state-active {
  background: #33b062;
}

.theme-r42green .calendar .fc-button.fc-state-active:hover {
  background: #33b062;
}

.theme-r42green .table.table-user tr.alpha td.alpha-val span {
  background: #33b062;
}

.theme-r42green .tagsinput#textfield_tagsinput .tag,
.theme-r42green .ms-container .multi-custom-header,
.theme-r42green .slider .ui-slider-handle,
.theme-r42green .form-wizard .wizard-steps li.active .single-step .title,
.theme-r42green .user-list h4,
.theme-r42green .table.table-colored-header thead th,
.theme-r42green .table.table-colored-header thead td,
.theme-r42green .dataTables_wrapper .dataTables_paginate > span > a.paginate_active,
.theme-r42green .elfinder-navbar .ui-state-active,
.theme-r42green .pagination.pagination-custom li.active a:hover,
.theme-r42green .pagination.pagination-custom li.active a {
  background: #33b062;
}

.theme-r42green .elfinder-navbar .ui-state-active {
  border-color: #33b062;
}

.theme-r42green #main .box .box-title .tabs > li.active > a {
  background: #33b062;
}

.theme-r42green #main .box.box-color .box-title {
  background: #33b062;
}

.theme-r42green #main .box.box-color .box-title .tabs > li.active > a {
  background: #fff;
  color: #33b062;
}

.theme-r42green #main .box.box-bordered.box-color .box-title {
  border-color: #33b062;
}

.theme-r42green #main .box.box-bordered.box-color .box-content {
  border-color: #33b062;
}

/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IiIsImZpbGUiOiJjc3MvdGhlbWUtYmFzZS5jc3MiLCJzb3VyY2VSb290IjoiIn0=*/

@import 'src/client/components/src/Common/variables.scss';

.ConnectorGenericForm {
  margin-top: 12px;
  padding-top: 20px;
  width: 100%;
  border-top: 1px solid #f4f4f4;

  &--FormBody .Table {
    margin-bottom: 0;
  }
}

.formikDropdown + button {
  margin-left: 4px;
}

.EditableIntegrationsList-warningText .EditableIntegrationsList-createConnectorLink {
  color: var($colors, link);
}

/* The container */
.FormikRadioInput-container {
  display: flex;
  position: relative;
  padding-left: 28px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  align-items: center;
  user-select: none;
}

.FormikRadioGroup .FormikRadioInput-container {
  margin-right: 16px;
}
/* Hide the browser's default radio button */
.FormikRadioInput-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

/* Create a custom radio button */
.FormikRadioInput-checkmark {
  position: absolute;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #ffffff;
  border: solid 2px #ccc;
  border-radius: 50%;
}

/* When the radio button is checked, add a blue background */
.FormikRadioInput-container input:checked ~ .FormikRadioInput-checkmark {
  border: solid 2px #0054b0;
}

.FormikRadioInput-container input:checked ~ span.FormikRadioInput-text {
  color: #0054b0;
  font-weight: 500;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.FormikRadioInput-checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.FormikRadioInput-container input:checked ~ .FormikRadioInput-checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.FormikRadioInput-container .FormikRadioInput-checkmark:after {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #0054b0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

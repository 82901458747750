.NewExport {
  &--settings {
    display: flex;
    align-items: baseline;
    margin-left: 15rem;
  }

  &-testConnection {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  }
}

.ConnectorPickerCard {
  height: 56px;
  padding: 8px;
  border: solid 1px #cccccc;
  border-radius: 2px;
  background-color: #ffffff;
  transition: 0.3s ease-in-out;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  position: relative;

  &:hover {
    box-shadow: 0 2px 4px 0 rgba(0, 84, 176, 0.5);
    border: solid 1px #0054b0;
    color: #0054b0;
  }

  &-disabled {
    pointer-events: none;
    cursor: not-allowed;
  }

  &-logo {
    max-width: 40px !important;
    height: auto;
    margin: 0 20px 0 12px;
    &--deactivated {
      -webkit-filter: grayscale(1);
      filter: grayscale(1);
    }
  }

  &-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    & > p {
      margin: 0;
    }

    &--name {
      font-size: 14px;
      line-height: 24px;
      font-weight: 500;
      letter-spacing: 0.8px;
    }

    &--number {
      font-size: 12px;
      line-height: 16px;
      color: #888888;
    }

    &--deactivated {
      & > p:first-child {
        color: #acacac !important;
        text-decoration: line-through;
      }
    }
  }
}

.ValueTransformationDraggableWrapper {
  cursor: move;
  border: 1px solid #eaeaea;
  padding: 4px 8px;
  margin-bottom: 8px;
  background-color: #fff;
  border-radius: 0.25rem;
}

.ValueTransformationDraggableWrapper--withDottedBorder {
  border: 1px dashed #0054b0;
  border-radius: 0.25rem;
}

.ValueTransformationDraggableWrapper--hidden {
  opacity: 0;
}

.ValueTransformation .Tooltip {
  top: 0;
  right: 32px;
}

.ValueTransformation .Tooltip--bottomRightPlacement:after {
  bottom: 11px;
  right: -8px;
  transform: rotate(90deg);
}

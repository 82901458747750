.CardMessage {
  display: flex;
  align-items: center;

  & > p {
    line-height: 20px;
    font-size: 12px;
    letter-spacing: 0.6px;
  }

  &-ERROR {
    color: #b7000a;
  }

  &-INFO {
    color: #0054b0;
  }

  &-WARNING {
    color: #fa8121;
  }

  &-icon {
    margin-right: 16px;
  }
}

.r42-ui {
	.modal-browserNotSupported {
		.infoStatus {
			text-align: center;
			padding-top: 15px;
			
			i {
				font-size: 32px;
			}
		}
	}	
}

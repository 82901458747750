.r42-ui {
	.sv-cookiePopup {
		width: 100%;
		min-height: 100%;
		height: 100%;
	}

	.modal.modal-huge.modal-preview {

		box-sizing: border-box;
        .modal-dialog, .modal-content {
            height: 93%;
            margin-top: 1.8%;
        }
		.modal-header {
			height: 45px;
			box-sizing: border-box;
			.close {
				z-index: 1055;
				position: absolute;
				right: 19px;
			}
		}

		.modal-body {
			height: 100%;
			overflow: hidden;
			box-sizing: border-box;
			position: absolute;
			top: 0px;
			padding-top: 52px;
			padding-bottom: 50px;
			width: 100%;
		}

		.modal-footer {
			box-sizing: border-box;
			position: absolute;
			bottom: 0px;
			height: 50px;
			width: 100%;
			padding: 9px 12px;
		}
	}

	.cookiePopup-body {
		padding-bottom: 0;
	}
}

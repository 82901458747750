@import 'src/client/components/src/Common/variables.scss';

.Table-row {
  border-bottom: 1px solid #eaeaea;
  min-height: 40px;
  position: relative;

  &--selectable {
    cursor: pointer;
  }

  &--selected {
    cursor: pointer;
  }

  &--preselected {
    cursor: pointer;
  }

  &--unselectable {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &--inactive,
  &--removed {
    color: var($colors, textInactive);
    text-decoration: line-through;
  }

  &:last-child {
    border-bottom: none;
  }
}

thead .Table-row:last-child {
  border-bottom: 1px solid #eaeaea;
}

div.Table-row {
  align-items: center;
  display: flex;
  white-space: nowrap;
}

.Table-row--detailed {
  min-height: 46px;
  align-items: center;
  display: flex;
  white-space: nowrap;
  border-bottom: 1px solid #ddd;
}

.Table-row--detailed.isActive {
  box-shadow: 0 0 2px 0 #cbd3e1;
  zoom: 1.1;
  position: relative;
  top: -1px;
  background-color: var($colors, white);
  width: 100%;
  border: none;
  border-radius: 2px;
}

.Table-row.isSelected {
  box-shadow: 0 0 2px 0 #cbd3e1;
  margin-bottom: -1px;
  padding-top: 1px;
  position: relative;
  border-radius: 2px;
  font-weight: 500;
}

.Table-row:hover {
  background-color: #fafafa;
}

div.Table-row:first-child:hover,
thead .Table-row:hover {
  background-color: #ffffff;
}

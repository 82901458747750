@import 'src/less/variables.less';

.ad-view-right {

    .r42-box-widget {

        margin-bottom: 25px;
        padding-left: 2px;
        background: #fff;

        .box-title {
            border-bottom: 1px solid #ddd;
            padding: 0;
            margin: 0;
            background: #fff;
        }

        .box-content {
            padding: 2px 5px 2px 5px;
            margin-top: 0;
            margin-bottom: 0;
            background: #fff;
        }

        .actions {

            margin-right: 0;

            .btn-r42-box-widget {
                background: #fff;
                color: #000;
                border-radius: 0;
            }
        }

        .condition-container {
            padding-left: 5px;
        }

        h3 {
            font-size: 17.5px;
            font-weight: 600;
            margin-left: 5px;
            color: @r42-darkgrey;
        }
    }


    .tagcondition-EventCondition {
        display: none;
    }

    .location {
        word-wrap: break-word;
    }

    .new-condition button {
        margin-left: 20px;
        margin-top: 15px;
    }

    .iprulecondition p {
        margin: 0px;
    }
}

//@todo: move from here to a editable
.r42-ui .icheckbox_1.checked-false {
    background-position: -24px 0;
}

.r42-ui .check-line .inline {
    top: 1px;
}

.error-modal {

    .modal-header {
        background-color: red;
        color: white;

        a {
            color: white;
        }
        small {
            display: block;
        }

    }

}

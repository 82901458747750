@import 'src/client/components/src/Common/variables.scss';

.EditAudience {
  max-width: $stackedWidth;
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
}

.EditAudience .react-select__menu,
.EditAudience .react-select__menu-list {
  max-height: 250px;
}

.EditAudience-footerSeparator {
  margin-bottom: 24px;
  margin-top: 48px;
  border-top: 1px solid #d0c9c9;
}

.EditAudience-heading {
  margin-top: 0;
  padding-top: 20px;
}

.EditAudience-actionButtons {
  max-width: 600px;
  display: flex;
  margin-left: auto;
}

.EditAudience-actionButtons button + button {
  margin-left: 16px;
}

.websitePathGroupPaths {

	ul.dynatree-container {
		.dynatree-path-has-children.dynatree-exp-c {
			.dynatree-icon {
				background-position: 0 -16px;
			}
		}
		.dynatree-path-has-children.dynatree-exp-e {
			.dynatree-icon {

			}
		}
	}
	
	.table {
		clear: none;
	}
}

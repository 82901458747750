.NumberRangeColumnFilter {
  display: flex;
  align-items: center;
  background: #fff;

  &-space {
    padding: 0 8px;
  }

  & > input {
    border: none !important;
  }

  & > input::-webkit-input-placeholder {
    font-size: 12px;
  }
}

.DateColumnFilter,
.DateColumnFilter > .DateRangePicker {
  align-items: unset;
}

.DateColumnFilter > .DateRangePicker > div > .DateRangePickerInput {
  padding: 0;
  width: 100%;
}

.DateColumnFilter > .DateRangePicker > div > .DateRangePickerInput > .DateInput {
  width: 42%;
}

.MultiSelectColumnFilter-option > .react-select__option {
  display: flex;
  align-items: center;

  & label {
    margin-left: 12px;
  }
}

.MultiSelectColumnFilter-option > .react-select__option--is-selected {
  color: #0054b0;
  background-color: #0054b010;
  font-weight: 500;
}
.MultiSelectColumnFilter-option > .react-select__option--is-selected > label {
  font-weight: 600;
}

.MultiSelectColumnFilter-dropdown--menu > .SelectElement > .react-select__control--menu-is-open {
  min-width: auto;
}

.MultiSelectColumnFilter {
  &-dropdownField {
    width: 100%;
    background: #fff;
    border: 1px solid #c5c5c5;
    border-radius: 2px !important;
    box-shadow: none;
    outline: none;
    text-align: left;

    line-height: 20px;
    display: flex;

    &--labelButton {
      border: none;
      outline: none;
      flex: 1;
      text-align: left;
      padding: 0 12px;
      min-height: 38px;
    }

    &--clearButton {
      padding: 0 9px;
    }

    &--clearButton .Button-icon {
      color: #cccccc;
    }
  }

  &-dropdown {
    position: relative;

    &--menu {
      position: absolute;
      width: 100%;
      z-index: 4;
      background: white;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
  }
}

.AudienceView {
  &-deleteAudienceButton {
    padding: 0 2px;

    & .Icon {
      display: flex;
      align-items: center;
    }
  }
}

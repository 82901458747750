$yellow: #ffa638;
$purple: #9f38ff;
$green: #36ba67;
$red: #d52c20;
$blue: #0054b0;
$grey888: #888888;
$border: #eaeaea;
$lightgrey: #fafafa;

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.SnapshotTablePanel {
  padding: 0;
}

.SnapshotTable {
  width: 100%;
  height: calc(100vh - 180px);
  overflow-y: scroll;

  &-name {
    &--disabled {
      cursor: not-allowed;
      color: $grey888;
    }
  }

  &-numericValues,
  &-nonNumericValues {
    display: inline;
    margin: 0;
  }

  &-nonNumericValues {
    list-style-position: inside;
  }

  &-statusCircle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 8px;
    display: inline-block;
    &--running {
      background: $yellow;
      animation: blinker 1s linear infinite;
    }
  }

  &-status {
    padding: 6px 8px 6px 8px;
    border-radius: 14px;
    line-height: 14px;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    &--finished {
      color: $green;
      font-weight: 500;
    }
    &--running {
      color: $yellow;
    }
    &--failed {
      color: $red;
    }
  }

  & > table {
    font-size: 14px;
    border-collapse: collapse;
    width: 100%;
  }

  & > table thead th {
    padding: 20px 0;
    position: sticky;
    top: 0;
    z-index: 2;

    &:first-child {
      padding-left: 24px;
    }
    &:last-child {
      padding-right: 24px;
    }
  }

  & > table tbody td {
    padding: 12px 0;
    &:first-child {
      padding-left: 24px;
    }
    &:last-child {
      padding-right: 24px;
    }
  }

  &-head {
    background: #fff;
    font-weight: 500;
    padding: 0 0 8px 0;
    font-size: 14px;
    user-select: none;
    position: relative;
    box-shadow: inset 0 0 0 #fff, inset 0 -1px 0 $border;
  }

  &-cell {
    &--textEnd {
      text-align: right;
    }
  }

  &-row {
    border-bottom: 1px solid $border;

    &:last-child {
      border-bottom: none;
    }
  }
}

.SnapshotPicker {
  width: 420px;
  height: auto;
}

.SnapshotForm {
  &-message {
    margin-top: 24px;
  }

  &-date .rdtPicker {
    position: fixed;
  }
}

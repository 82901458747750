@import 'src/less/variables.less';

//@todo: move from here to a editable
.r42-ui .icheckbox_1.checked-false {
    background-position: -24px 0;
}

.r42-ui .check-line .inline {
    top: 1px;
}

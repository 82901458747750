@import 'src/client/components/src/Common/variables.scss';

.RuleCardEdit {
  position: relative;

  &-inverse {
    margin-top: 16px;
    left: 0;
    padding-top: 8px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    width: 100%;
    display: flex;
    align-items: baseline;
  }

  &-waitTimeParameter + .SelectElement,
  &-timeParameter + .SelectElement {
    width: 100px;
  }

  &-timeParameter .FieldWrapper-errorOrHintContainer,
  &-waitTimeParameter .FieldWrapper-errorOrHintContainer {
    position: absolute;
  }

  &-waitTimeSettings {
    margin-top: 16px;
    margin-left: auto;
    display: flex;
    align-items: flex-start;
  }

  &-waitTimeParameter {
    margin-right: 8px;
  }

  &-timeParameter .Form-label {
    display: none;
  }

  &-timeParameter .Form-field {
    margin: 0;
  }

  &-timeParameter #timeValue {
    height: 2.5rem;
    border: 0;
    background-color: transparent;
  }

  &-actions {
    margin-left: auto;
    align-items: center;

    & > *:not(:first-child) {
      margin-left: 16px;
    }
  }

  &-AddProperty {
    font-size: 14px;
    color: #0054b0;
    margin: 0;
  }

  &-textInputErrorContainer {
    font-size: 10px;
    color: #b7000a;
    position: absolute;
    bottom: 2px;
    margin: 0;
  }

  &-deletePropertyContainer.Table-cell {
    text-align: center;
  }

  &-DeleteProperty {
    padding: 4px;
  }

  &-DeleteProperty .Button-icon {
    height: auto;
    line-height: unset;
  }

  &-DeleteProperty .Button-icon > svg {
    color: #cccccc;

    &:hover {
      color: #b7000a;
    }
  }

  &-addPropertyRow {
    border-bottom: none;
    padding-bottom: 0;
    min-height: auto;

    &--disabled {
      opacity: 0.5;

      &:hover {
        text-decoration: none;
        cursor: default;
      }
    }
  }

  &-propertyRow .Table-cell > * {
    display: block;
    width: 100%;
  }

  &-filterCell .DateFormat-Container {
    display: flex;
  }

  &-propertyNameCell,
  &-constraintCell,
  &-uniqueValuesCell,
  &-variablesCell {
    flex: 2;
  }

  &-filterCell {
    flex: 3;
  }

  @keyframes show {
    from {
      opacity: 0;
      transform: scale3d(0.75, 0.75, 0.75);
    }

    to {
      opacity: 1;
    }
  }

  &-table {
    width: 100%;
    margin-top: 16px;
    margin-bottom: 0;
    animation: 0.3s ease-in-out show;
  }

  &-table-row {
    font-size: 14px;
    min-height: 40px;
    position: relative;
    padding: 12px 0;
  }

  &-table-row:hover {
    background-color: #fafafa !important;
  }

  &-table-row--error,
  &-table-row--error:hover {
    background-color: #ff000020 !important;
  }

  &-table-row .FieldWrapper-errorOrHintContainer {
    position: absolute;
    bottom: 1px;
  }

  &-table-head {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .Checkbox-input[name='isInversed']:checked + span {
    border-color: #b7000a;
    background-color: #b7000a;
  }

  .Checkbox-input[name='isInversed']:checked ~ label {
    color: #b7000a;
  }
}

.t-timeValueField .Form-element,
.t-waitTimeValueField .Form-element {
  padding: 0;
}

.timeUnit__control,
.timeUnit__control--is-focused {
  border: 0 !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.BaseFormSection {
  margin-bottom: 56px;

  &-left {
    font-family: Roboto;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.6px;

    h4 {
      color: #444444;
      margin: 0;
    }

    p {
      margin-top: 8px;
    }

    * {
      color: #888888;
    }
  }
}

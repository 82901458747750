.r42-ui .tag-content {

    .info {
        position: relative;
        min-height: 50px;
    }

    .image-container {
        float: left;
        padding: 0px 20px 20px 20px;
        width: 120px
    }

    .image-container img {
        height: 100px;
    }

    .descriptionLabel {
        position: absolute;
    }

    .description {
        color: #777;
    }

    .editable-container-content {
        width: 136px;

    }

    .CodeMirror-hscrollbar {
        overflow-x: hidden;
    }

    div.mobile-tag-parameters.control-group > div > div:nth-child(1) > span:nth-child(1) {
        margin-right: 20px;
    }
}

.r42-ui .tag-content .editable.edit .control-group {
    margin-bottom: 25px;
    padding-top: 5px;
    padding-bottom: 5px;

}

.r42-ui .tag-content .editable.edit .control-label {
    font-weight: 400;
}

@import 'src/client/components/src/Common/variables.scss';

$triggerAccentColor: #20b25f;
$stepAccentColor: #715ed0;
$goalAccentColor: #ffa638;
$journeyAccentColor: #20b25f;
$buttonContentColor: #0054b0;
$lineBetweenNodesColor: #979797;
$lineBetweenNodesColor--experiment: #0054b0;
$errorColor: var($colors, error);

// .JourneyView-cardBase {
//   rx: 4;
// }

.JourneyView-triggerNodePlaceholder .JourneyView-nodeRibbon,
.JourneyView-triggerNode .JourneyView-nodeRibbon {
  stroke: $triggerAccentColor;
}

.JourneyView-node--hasError.JourneyView-triggerNodePlaceholder .JourneyView-nodeRibbon,
.JourneyView-node--hasError.JourneyView-triggerNode .JourneyView-nodeRibbon,
.JourneyView-node--hasError.JourneyView-stepNode .JourneyView-nodeRibbon {
  stroke: $errorColor;
}

.JourneyView-stepNode .JourneyView-nodeRibbon {
  stroke: $stepAccentColor;
  fill: $stepAccentColor;
}

.JourneyView-antennaIcon {
  fill: $buttonContentColor;
}

.JourneyView-goalIcon {
  fill: $goalAccentColor;
}

.JourneyView-journeyIcon {
  fill: $journeyAccentColor;
}

.JourneyView-lineBetweenNodes--experiment {
  stroke-dasharray: 10 6;
  stroke-dashoffset: 5;
}

.JourneyView-lineBetweenNodes--transition {
  stroke-dasharray: 10 6;
  stroke-dashoffset: 10;
}

.JourneyView-nodeCircle {
  stroke: $lineBetweenNodesColor;
  stroke-width: 2;
  fill: none;
}

.JourneyView-nodeCircle--experiment {
  stroke: $lineBetweenNodesColor--experiment;
}

.JourneyView-experimentStepLabel,
.JourneyView-experimentStepWeightValue {
  font-size: 9px;
}

.JourneyView-experimentStepLabel {
  margin-left: 6px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  flex: 1;
}

.JourneyView-experimentStepWeightValue {
  background: $stepAccentColor;
  color: #fff;
  border-radius: 7px;
  padding: 0 6px;
}

.JourneyView-experimentStepWeightValue--notValid {
  background: $errorColor;
}

.JourneyView-stepProfilesCount {
  fill: #eaeaea;
}

.JourneyView-nodeTitleField {
  width: 320px;
  margin-bottom: 24px;
}

.JourneyView-nodeTitle {
  display: -webkit-box;
  font-weight: 500;
  overflow: hidden;
  -webkit-line-clamp: 3;
  /*
    Disabling-enabling autoprefixer to prevent it from removing a necessary line of CSS
    and breaking multiline clampling during prod build
  */
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
  /*! autoprefixer: on */
}

.JourneyView-stepNode:not(.JourneyView-stepNode--isOnlyVariant) .JourneyView-nodeTitle {
  -webkit-line-clamp: 2;
}

.JourneyView-nodeTooltipContainer {
  width: 110px;
  height: 65px;
}

.JourneyView-nodeTooltip {
  font-size: 16px;
  font-weight: 500;
  white-space: nowrap;
  padding: 2px;
  color: #0054b0;
}

.JourneyView-triggerNodePlaceholder {
  cursor: pointer;
}

.JourneyView-triggerNodePlaceholder .JourneyView-cardBase {
  stroke-dasharray: 5 5;
}

.JourneyView-node--hasError.JourneyView-triggerNodePlaceholder .JourneyView-cardBase {
  stroke: $errorColor;
}

.JourneyView-triggerNodePlaceholder .JourneyView-nodeTitle {
  font-size: 14px;
  fill: #888888;
  font-weight: normal;
}

.JourneyView-nodeLink {
  stroke: grey;

  &--hasError {
    stroke: $errorColor;
  }
}

.JourneyView-rectangularButtonBase {
  rx: 2;

  &--hasError {
    stroke-dasharray: 4 2;
  }
}

.JourneyView-rectangularButton {
  cursor: pointer;
}

.JourneyView-rectangularButtonText {
  font-size: 14px;
  font-weight: 500;
  fill: $buttonContentColor;
}

.JourneyView-experimentIcon {
  stroke: $buttonContentColor;
  fill: $buttonContentColor;
  stroke-width: 0.2px;
  stroke-linecap: round;
}
.JourneyView-expandCollapseButton {
  cursor: pointer;
}

.JourneyView-expandCollapseButtonBackground {
  fill: #fff;
  height: 30px;
  stroke-width: 1px;
  stroke-dasharray: 2px;

  &--normal {
    width: 32px;
    stroke: grey;
  }

  &--collapsed {
    width: 144px;
    stroke: #0054b0;
  }
}

.JourneyView-largeModal {
  width: 880px;
  min-height: 500px;
  max-height: 80vh;
}

.JourneyView-largeModal .Modal-body {
  display: flex;
  flex-direction: column;
}

.JourneyView-largeModal .JourneyView-modalSpinnerContainer {
  margin: auto;
}

#main .mainContent .ZoomButtons {
  flex-direction: row;
}

// ButtonsPanel.tsx

.JourneyView-hoverRegionUnderCard {
  display: none;
}

.JourneyView-node:hover .JourneyView-hoverRegionUnderCard {
  display: block;
}

.JourneyView-transitionNode:hover .JourneyView-hoverRegionUnderCard {
  display: block;
}

.JourneyView-nodeButtonsPanel,
.JourneyItem:hover .JourneyView-nodeButtonsPanel {
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

.JourneyView-node:hover .JourneyView-nodeButtonsPanel,
.JourneyView-transitionNode:hover .JourneyView-nodeButtonsPanel,
.JourneyItem:hover .JourneyView-nodeButtonsPanel {
  opacity: 1;
  visibility: visible;
}

.JourneyView-nodeButtonsPanelBackground {
  fill: #fff;
  stroke: #cccccc;
}

.JourneyView-nodeButtonsPanelTriangle {
  fill: #fff;
  stroke: #cccccc;
}

.JourneyView-nodeButtonsPanelTriangleHiddenLine {
  stroke: #fff;
}

.JourneyView-roundAddButton:hover .JourneyView-tooltip {
  display: block;
  opacity: 0.8;
  transition: opacity 100ms ease-in;
  transition-delay: 300ms;
}

.JourneyView-roundAddButton .JourneyView-tooltip {
  opacity: 0;
  display: none;
}

.JourneyView-expandCollapseButton:hover .JourneyView-tooltip {
  display: block;
  opacity: 0.8;
  transition: opacity 100ms ease-in;
  transition-delay: 300ms;
}

.JourneyView-expandCollapseButton .JourneyView-tooltip {
  opacity: 0;
  display: none;
}

.JourneyView-tooltipLabel {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

// EditExperiment.tsx
.EditExperiment {
  width: 500px;
  min-height: 250px;
  display: flex;
}
.EditExperiment-propertyRow {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  margin-top: 24px;
}

.EditExperiment-propertyRow-label {
  text-align: right;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 18px;
}

.EditExperiment-propertyRow-variantName {
  width: 72%;
}

.EditExperiment-weightErrorContainer {
  width: 360px;
  min-height: 36px;
  margin-top: 14px;
}
.EditExperiment-weightError {
  font-family: Roboto;
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0.7px;
  color: #b7000a;
  display: flex;
  align-items: center;
}

.EditExperiment-propertyRow-weight {
  width: 25%;
  margin-left: auto;
  input {
    width: 100%;
    max-width: 100%;
    border: 1px solid #c5c5c5;
    background: #fff;
    height: 32px;
    padding: 0 8px;
    transition: border-color 0.3s ease-out;
    box-sizing: border-box;
    &.is-error {
      border: 1px solid #b7000a;
    }
  }
}

.EditExperiment-propertyRow-weight .FieldWrapper-fieldWrap {
  padding-top: 28px;
}

.FieldWrapper.is-error input {
  border: 1px solid #b7000a;
}

.EditExperiment-propertyRow-property {
  padding: 10px;
  box-sizing: border-box;

  input {
    width: 100%;
    max-width: 100%;
    border: 1px solid #c5c5c5;
    background: #fff;
    height: 32px;
    padding: 0 8px;
    transition: border-color 0.3s ease-out;
    box-sizing: border-box;
    &.is-error {
      border: 1px solid #b7000a;
    }
  }

  .Form-element {
    padding: 0;
  }
}

.EditExperiment-propertyRow-error {
  color: #b7000a;
  display: block;
  height: 18px;
}

.EditExperiment-clickableArea {
  cursor: pointer;
}

.EditExperiment-spinner {
  margin: auto;
  transform: translateY(-36px);
}

.EditExperiment-form {
  width: 100%;
}

.EditExperiment-weightNotification {
  font-family: Roboto;
  font-size: 12px;
  letter-spacing: 0.6px;
  color: #888888;
}

.EditExperiment-form .ActionButtons {
  bottom: 0;
  right: 16px;
}

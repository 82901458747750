.StoredVariablesInput-Select {
  width: 100%;
}

.StoredVariablesInput-creatableOption {
  color: #0054b0;
  display: flex;
  align-items: center;
}

.StoredVariablesInput-creatableOption > svg {
  margin-right: 4px;
  margin-top: 1px;
}

.CreateNewVariableForm-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 400px;

  & > .ActionButtons {
    margin-bottom: 24px;
  }
}

.CreateNewVariableForm-content .Element.Element--default {
  width: 300px;
}

.CreateNewVariableForm-content .Form-field .Form-label {
  width: 100px;
  min-width: 100px;
}

.CreateNewVariableForm-fieldsContainer {
  flex: 1;
}

.CreateNewVariableForm-fieldsContainer .Element-error {
  max-width: 300px !important;
}

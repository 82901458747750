.DateRangePicker {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.DateRangePicker_picker {
  z-index: 3;
}

.DateRangePickerInput {
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border: 1px solid #cccccc;
  border-radius: 2px;
  padding: 0 6px;

  &_arrow {
    margin: 0 8px;
  }
}

.DateInput {
  width: 100%;
  &_input {
    font-family: Roboto !important;
    font-size: 14px !important;
    color: #000000;
    font-weight: 400;
    padding: 0 !important;
    height: 40px !important;
    border: none !important;
    background: none !important;
    text-align: center;
  }
}

@media (min-width: 768px) {
  .DateInput {
    width: 96px;
  }
}

.PresetDateRangePicker-panel {
  padding: 0 22px 24px 22px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
}

.CalendarDay__selected_span {
  background: #98c9ff;
  color: white;
  border: none;
}

.CalendarDay__selected {
  background: #0054b0;
  color: white;
  border: none;
}

.CalendarDay__selected:hover,
.CalendarDay__selected_span:hover {
  background: #888888;
  color: white;
  border: none;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: #98c9ff;
}

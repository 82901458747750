@import 'src/client/components/src/Common/variables.scss';
@import './variables';

/**
 Tabs Component
 */
.Tabs {
  padding-top: 24px;
}

.Tabs-nav {
  border-bottom: 1px solid var($colors, border);
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  overflow-x: auto;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    height: 0px;
  }
}

.Tabs-navLink {
  border-bottom: 2px solid transparent;
  cursor: pointer;
  font-size: 16px;
  padding: 1rem;
  transition: border-color var($transition, primary);
  flex: none;
  color: #888888;

  &:hover {
    border-color: var($tabs, hover, borderColor);
  }

  &.is-selected {
    border-color: var($tabs, selected, borderColor);
    color: var($tabs, selected, color);
    font-weight: 500;
  }

  &.is-disabled {
    cursor: not-allowed;
    opacity: 0.4;
    pointer-events: none;
  }
}

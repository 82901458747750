@import 'src/client/components/src/Common/variables.scss';

.RuleCardView {
  position: relative;
  box-shadow: 0 2px 4px 0 #cbd3e1;
  background-color: var($colors, white);
  width: 100%;
  border-radius: 0.25rem;

  &-info {
    padding: 24px;
  }

  &-icon {
    margin-right: 20px;
    width: 52px;
    height: 52px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  &-name {
    margin-top: 18px;
  }

  &-title {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.8px;
    color: #000000;
    display: flex;
    align-items: center;
  }

  &-description {
    opacity: 0.6;
    font-size: 14px;
  }

  &-table {
    width: 100%;
  }

  &-header {
    align-items: flex-start;
  }

  &-table-row {
    font-size: 14px;
    min-height: 40px;
    position: relative;
  }

  &-table-row--error,
  &-table-row--error:hover {
    background-color: #ff000020 !important;
  }

  &-inverseText {
    color: #b7000a;
    background-color: #ffdee0;
    line-height: 32px;
    font-size: 14px;
    font-weight: 700;
    padding: 0 32px;
  }

  &-nameAndIcon {
    display: flex;
    align-items: center;
  }

  &-timeParameter {
    display: flex;
    align-items: center;
    padding: 4px;
    font-size: 14px;
    line-height: 1;
    letter-spacing: 0.7px;
    color: #000000;
    margin-left: 8px;
    white-space: nowrap;
  }

  &-timeIcon {
    margin-right: 8px;
  }

  &-infoIcon {
    margin-left: 8px;
  }

  &-questionIcon {
    margin: 0 4px;
  }
}

.RuleCardView > p {
  margin: 0;
}

@media only screen and (max-width: 1024px) {
  .RuleCardView {
    max-width: 960px;
  }
}

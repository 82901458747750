@import 'src/less/variables';

.r42-ui {

  .CodeMirror {
    border: 1px solid #C5C5C5;
    line-height: 20px;
  }

  .Element--code.is-error .CodeMirror {
      border-color: #B7000A;
  }

  .CodeMirror-vscrollbar, .CodeMirror-hscrollbar {
    pointer-events: auto !important;
  }

  .CodeMirror-hints {
    position: absolute;
    z-index: 2000;
    overflow: hidden;
    list-style: none;

    margin: 0;
    padding: 2px;

    -webkit-box-shadow: 2px 3px 5px rgba(0, 0, 0, .2);
    -moz-box-shadow: 2px 3px 5px rgba(0, 0, 0, .2);
    box-shadow: 2px 3px 5px rgba(0, 0, 0, .2);
    border-radius: 3px;
    border: 1px solid silver;

    background: white;
    font-size: 90%;
    font-family: monospace;

    max-height: 20em;
    overflow-y: auto;
  }

  .CodeMirror-hint {
    margin: 0;
    padding: 0 4px;
    border-radius: 2px;
    max-width: 19em;
    overflow: hidden;
    white-space: pre;
    color: black;
    cursor: pointer;
  }

  .CodeMirror-hint-active {
    background: #08f;
    color: white;
  }

  .CodeMirror, .CodeMirror-gutters, .CodeMirror-scroll {
    height: 100%;
    min-height: 200px;
    margin-right: 0px;
  }

  .cm-tagPlaceholder-left, .cm-tagPlaceholder-right {
    display: inline-block;
    color: transparent;
    width: 0;
  }

  .cm-tagPlaceholder-left {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }

  .cm-tagPlaceholder-right {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }

  .CodeMirror-lint-tooltip {
    z-index: 1052;
    opacity: 1;
  }

  .cm-tagPlaceholder {
    cursor: help;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    padding: 3px;
    background-color: @r42-white;

    &.extracted, &.cm-x {
      color: @r42-orange-journey;
      border: 1px solid @r42-orange-journey;
      &:hover {
        font-weight: 700;
      }
    }

    &.property, &.cm-p {
      color: @r42-green-journey;
      border: 1px solid @r42-green-journey;
      &:hover {
        font-weight: 700;
      }
    }
  }
}

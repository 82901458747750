
.datetimepicker-wrapper {
	vertical-align: middle;
  	display: inline-block;

  	[ng-model=hours], [ng-model=minutes] {
  		width: 46px !important;
  	}
}

[data-datetimepicker] {

	&[data-show-time] {

		.datetimepicker-wrapper {
			height: 90px;

			&.datetimepicker-wrapper-date > * {
				margin-top: 30px;
			}
		}
	}

    .datetimepicker-wrapper-time {
        padding-top: 4px;
    }
}

[uib-datepicker-popup-wrap] {
	display: inline;
}

@import 'src/client/components/src/Common/variables.scss';

.NotificationCenter {
  box-sizing: border-box;
  position: fixed;
  width: 380px;
  z-index: 9999;
}

.NotificationCenter--topRight {
  right: 1rem;
  top: 2rem;
}

.NotificationCenter-toast {
  position: relative;
  max-height: 800px;
}

.NotificationCenter-progressBar {
  animation: progressBar linear 1 forwards;
}

.NotificationCenter-progressBar,
.NotificationCenter-controlledProgressBar {
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  height: 0.4rem;
  left: 0.2rem;
  opacity: 0.5 !important;
  position: absolute;
  right: 0.1rem;
  transform-origin: left;
  border-radius: 1rem;
  z-index: 9999;

  &.Toastify__progress-bar--error {
    background-color: var($colors, error);
  }

  &.Toastify__progress-bar--info {
    background-color: var($colors, info);
  }

  &.Toastify__progress-bar--success {
    background-color: var($colors, success);
  }

  &.Toastify__progress-bar--warning {
    background-color: var($colors, warning);
  }
}

@keyframes progressBar {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}

@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    transform: translate3d(10px, 0, 0);
  }
  90% {
    transform: translate3d(-5px, 0, 0);
  }
  to {
    transform: none;
  }
}

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}

.NotificationCenter-bounceInRight {
  animation-name: bounceInRight;
}

.NotificationCenter-bounceOutRight {
  animation-name: bounceOutRight;
}

@keyframes shakeNotification {
  from,
  to {
    transform: translate3d(0, 0, 0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translate3d(-10px, 0, 0);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translate3d(10px, 0, 0);
  }
}

.ToastShake {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.ToastShake--shaking {
  animation-name: shakeNotification;
}

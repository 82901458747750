@import 'src/client/components/src/Common/variables.scss';
@import './variables';

.Crumb {
  display: flex;
  align-items: center;
}

.Crumb-pointer {
  width: 8px;
  font-size: 0;
}

.Crumb-pointer > svg {
  color: #888888 !important;
}

.Crumb:last-child .Crumb-pointer {
  display: none;
}

.Crumb-link {
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 0.48px;
  color: #888888;
}

.Crumb-link.is-active {
  color: var($breadcrumb-colors, text, isClickable);
  text-decoration: none;
  cursor: pointer;
}

.Crumb-link.is-active:hover {
  text-decoration: underline;
}

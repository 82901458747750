$yellow: #ffa638;
$purple: #9f38ff;
$green: #36ba67;
$red: #d52c20;
$blue: #0054b0;
$lightBlue: #f6fbff;
$grey888: #888888;
$border: #eaeaea;
$lightgrey: #fafafa;
$white: #ffffff;

.Predictions {
  background: $white;
  padding: 16px;
  border-radius: 4px;
  margin-top: 24px;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: 1px solid $border;

    &--name {
      width: 300px !important;
    }
  }

  &-addButton {
    margin-top: 24px !important;
  }
}

.Criterias {
  position: relative;
  &-multiple {
    margin-left: 48px;
  }
  &-row {
    position: relative;

    &--last {
      position: relative;
      &::before {
        content: '';
        position: absolute;
        top: 76px;
        left: -48px;
        border-bottom: 1px solid $grey888;
        width: 48px;
      }
    }

    &::before {
      content: '';
      border-left: 1px solid $grey888;
      height: calc(100% + 48px);
      position: absolute;
      top: 48px;
      left: -48px;
      border-top: 1px solid $grey888;
      width: 48px;
    }
    &::after {
      content: 'AND';
      position: absolute;
      left: -48px;
      border: 1px solid $grey888;
      color: $yellow;
      background: $lightgrey;
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
      font-size: 12px;
      padding: 2px 4px;
      z-index: 10;
      bottom: -24px;
    }

    &--andButton {
      margin-top: 16px !important;
      height: 24px;
      border-radius: 24px !important;
      font-size: 12px !important;
      border: 1px solid;
      color: $yellow;

      &:hover,
      &:active {
        border-color: $yellow;
        background: $yellow;
        color: $white;
      }

      &:focus {
        border-color: $yellow;
      }

      .Icon {
        margin-top: -2px;
        margin-right: 2px;
      }
    }
  }

  &-header {
    margin-top: 12px;
    padding: 0 24px;
    display: grid;
    grid-template-columns: 2fr 1fr 1.5fr 1rem;
    gap: 12px;
    align-items: baseline;
  }
}

.Rules {
  background-color: $lightgrey;
  border-radius: 4px;
  padding: 12px 24px;
  margin-top: 20px;

  &-multiple {
    background-color: $lightBlue;
  }

  &-row {
    display: grid;
    grid-template-columns: 2fr 1fr 1.5fr 1rem;
    gap: 12px;
    align-items: baseline;

    &--orButton {
      height: 24px;
      border-radius: 24px !important;
      font-size: 12px !important;
      border: 1px solid;

      .Icon {
        margin-top: -2px;
        margin-right: 2px;
      }
    }
  }
}

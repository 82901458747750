.display-inlineBlock {
  display: inline-block;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-error {
  color: red;
}

.valign-top {
  vertical-align: top;
}
div[data-angular-treeview] {
  /* prevent user selection */
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  /* default */
  font-family: Tahoma;
  font-size:13px;
  color: #555;
  text-decoration: none;
}

tree ul {
  margin: 0;
  padding: 0;
  list-style: none;
  border: none;
  overflow: hidden;
}

tree li {
  position: relative;
  padding: 0 0 0 20px;
  line-height: 20px;
}

tree li .expanded {
  padding: 1px 10px;
  background-image: url("http://cfile23.uf.tistory.com/image/205B973A50C13F4B19D9BD");
  background-repeat: no-repeat;
}

tree li .collapsed {
  padding: 1px 10px;
  background-image: url("http://cfile23.uf.tistory.com/image/1459193A50C13F4B1B05FB");
  background-repeat: no-repeat;
}

tree li .normal {
  padding: 1px 10px;
  background-image: url("http://cfile23.uf.tistory.com/image/165B663A50C13F4B196CCA");
  background-repeat: no-repeat;
}

tree li i, tree li span {
  cursor: pointer;
}

tree li .selected {
  background-color: #aaddff;
  font-weight: bold;
  padding: 1px 5px;
}


@import 'src/less/variables.less';

ul.dynatree-container {

    border: none;
    background-color: inherit;
    overflow-x: hidden;
    overflow-y: hidden;
    .dynatree-node {
        position: relative;
        margin-top: 1px;
    }
    .sv-dynatree-invisible {
      display: none!important;
    }

    span.dynatree-add {
        padding-left: 25px;
        span.dynatree-add-label {
            padding-left: 6px;
        }
        input.form-control {
            margin-top: 8px;
        }
    }

    .dynatree-add-external {
        margin-left: 12px;
        color: #138dd1;

        span.dynatree-add-label {
            padding-left: 8px;
        }
        input.form-control {
            margin-top: 6px;
        }

        .typeahead {
            position: relative;
            top: 0px !important;
        }
    }

    .sv-dynatree-remove {
        position: absolute;
        right: 0;
        i {
            margin-left: 10px;
            vertical-align: middle;
        }
    }
    .dynatree-nosubmatch .dynatree-expander {
        background-image: none;
    }
    .dynatree-nosubmatch .dynatree-expander:before {
        content: "\f070";
        font-family: FontAwesome;
        font-size: 12px;
        margin-left: 1px;
    }

    .dynatree-focused a, .dynatree-focused span.sv-dynatree-href-replacement {
        background: @r42-themeColor;
        padding-left: 3px;
        padding-right: 3px;
        color: #fff;
        &:hover {
            background: @r42-themeColor;
            color: #fff;
        }
    }

    .dropdown-menu {
        padding: 5px 0;

        li a {
            margin-left: 0;
        }
    }

    a {
        border: none;
    }

    .extra-drop {
        font-size: 15px;
        height: 48px;
        width: 100%;
    }
}

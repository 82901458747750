@import 'variables.less';

::-webkit-scrollbar-track {
	background-color: #eaeaea;
}

::-webkit-scrollbar {
	width: 4px;
	height: 8px;
	background-color: #eaeaea;
	border-radius: 4px;
}

::-webkit-scrollbar-thumb {
	background-color: rgba(91, 91, 91, 0.60);
    border-radius: 6px;
}

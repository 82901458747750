@import 'src/client/components/src/Common/variables.scss';

/*
 Text Colors Utils
 */
$red: #e51400;
$green: #339933;

.u-colorText {
  color: var($colors, text) !important;
}

.u-red {
  color: $red;
}

.u-green {
  color: $green;
}

.u-greyBackground {
  background: var($colors, background);
}

.u-whiteBackground {
  background: #fff;
}

$grey888: #888888;
$border: #eaeaea;
$lightgrey: #fafafa;
$blue: #0054b0;

.ConnectorList-logo {
  max-height: 4.8rem;

  &.is-disabled {
    filter: grayscale(100%);
  }
}

.ConnectorList-table-logo {
  object-fit: contain;
  width: 2.8rem;
}

.ConnectorList {
  &-item--inactive {
    display: inline-block;
    text-decoration: none;
    font-size: 12px;
    background: $border;
    padding: 2px 4px;
    border-radius: 2px;
    color: $grey888;
  }
  &-name {
    margin-left: 16px;
    &--inactive {
      color: $grey888;
      text-decoration: line-through;
    }
  }
  &-dropzone {
    max-width: 550px;
    display: flex;
    flex-direction: column;
    min-height: 150px;
    justify-content: center;
    align-items: center;
  }
}

.ConnectorEdit-logo {
  max-height: 80px;
  margin-bottom: 24px;
}

.ConnectorsTablePanel {
  padding: 0;
}

.ConnectorsTable {
  width: 100%;
  height: calc(100vh - 320px);
  overflow-y: scroll;

  & > table {
    font-size: 14px;
    border-collapse: collapse;
    width: 100%;
  }

  & > table thead th {
    padding: 20px 0;
    position: sticky;
    top: 0;
    background: $lightgrey;
    z-index: 2;

    &:first-child {
      padding-left: 24px;
    }
    &:last-child {
      padding-right: 24px;
    }
  }

  & > table tbody td {
    padding: 12px 0;
    &:first-child {
      padding-left: 24px;
    }
    &:last-child {
      padding-right: 24px;
    }
  }

  &-head {
    font-weight: 500;
    padding: 0 0 8px 0;
    font-size: 14px;
    border-bottom: 1px solid $border;
    user-select: none;
    position: relative;
    vertical-align: baseline;

    &--sort {
      margin-left: 4px;
      color: $blue;
    }
  }

  &-cell {
    &--textEnd {
      text-align: right;
    }
  }

  &-row {
    border-bottom: 1px solid $border;

    &:last-child {
      border-bottom: none;
    }

    .Table-actions {
      float: right;
    }
  }
}

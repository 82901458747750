/* ==========================================================
 * Spinner
 * =========================================================*/
.r42-ui {

	@keyframes spin {
		to { transform: rotate(1turn); }
	}
	
	.spinnerContainer {
		width: 150px;
		margin: 40px auto;
		display: flex;
    flex-direction: column;
    align-items: center;
		
		&.small .progressSpinner {
            font-size: 6px;
		}
		
		&.large .progressSpinner {
			font-size: 24px;
		}
	}
    
    .embeddedSpinnerContainer {
        &.embedded .progressSpinner {
            font-size: 4px;
            position: absolute;
        }
    }
	
	.progressSpinner {
		position: relative;
		display: inline-block;
		width: 5em;
		height: 5em;
		margin: 0 .5em;
		font-size: 12px;
		text-indent: 999em;
		overflow: hidden;
		-webkit-animation: spin 1s infinite steps(8);
		-moz-animation: spin 1s infinite steps(8);
		animation: spin 1s infinite steps(8);
	}
    
	.progressSpinner:before,
	.progressSpinner:after,
	.progressSpinner > div:before,
	.progressSpinner > div:after {
		content: '';
		position: absolute;
		top: 0;
		left: 2.25em; /* (container width - part width)/2  */
		width: .5em;
		height: 1.5em;
		border-radius: .2em;
		background: #eee;
		box-shadow: 0 3.5em #eee; /* container height - part height */
		transform-origin: 50% 2.5em; /* container height / 2 */
	}
	
	.progressSpinner:before {
		background: #555;
	}
	
	.progressSpinner:after {
		transform: rotate(-45deg);
		background: #777;
	}
	
	.progressSpinner > div:before {
		transform: rotate(-90deg);
		background: #999;
	}
	
	.progressSpinner > div:after {
		transform: rotate(-135deg);
		background: #bbb;
	}
}

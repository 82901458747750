@import 'src/client/components/src/Common/variables.scss';
@import './variables';

.Step {
  list-style-type: none;
  flex: 1;
  position: relative;
  text-align: center;
  color: var($color, darkGray);
  font-size: 14.4px;
  line-height: 19px;
  user-select: none;
}

.Step:not(:last-child)::before {
  content: ' ';
  border: 1px dashed #d8d8d8;
  width: calc(100% - 21px);
  margin-left: calc(50% + 21px);
  display: block;
  position: absolute;
  margin-top: 21px;
  z-index: 0;
}

.Step-counter {
  width: 42px;
  height: 42px;
  line-height: 42px;
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  background-color: #d8d8d8;
  color: var($colors, white);
  transition: all 0.5s;
}

.Step-counter.is-clickable {
  cursor: pointer;
}

.Step:after {
  width: 100%;
  height: 0;
  content: '';
  position: absolute;
  background-color: white;
  top: 13px;
  left: -50%;
  z-index: -1;
  border-style: dashed;
  border-width: 2px;
  color: var($color, lightGray);
}

.Step:first-child:after {
  content: none;
}

.Step.is-active {
  color: var($color, darkGray);
}

.Step-counter.is-active {
  background-color: #3ac970;
  border-color: #3ac970;
}

.Step.is-active:after {
  background-color: var($colors, success);
  border-color: var($colors, success);
}

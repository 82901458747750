@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer utilities {
  .hid {
    display: none;
  }
  .text-app-blue-700 {
    color: #0054b0;
  }
  .text-app-blue-900 {
    color: #01346c;
  }
}

a,
a:hover,
a:active {
  text-decoration: none;
}

html {
  -webkit-text-size-adjust: 100%;
  font-family:
    Inter,
    ui-sans-serif,
    system-ui,
    -apple-system,
    Roboto;
  font-size: 14px;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: inline-block;
}

ol,
ul,
menu {
  list-style: auto;
  margin: inherit;
  padding: inherit;
}

/* override for normalize.css, remove along with bootstrap library*/
button[disabled],
html input[disabled] {
  cursor: not-allowed !important;
}

.Profiles {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.Profiles .EditAudience-backgroundWrap {
  flex: 1;
}

@media (max-width: 500px) {
  .Profiles {
    margin: 0 -20px;
  }

  .Profiles .Form-field {
    display: block;
  }

  .Profiles .Form-label.Form-label {
    text-align: left;
    padding: 0;
  }
}

.Collector-statsPeriodPicker {
  width: 300px;
}

.CollectorsForm .Form-row {
  margin-bottom: 0;
}

.CardGrid {
  display: grid;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
}

.CardGrid--2-columns {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.CardGrid--3-columns {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.CardGrid--4-columns {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.CardGridItem {
  cursor: pointer;
  transition: all 200ms ease-out;
  text-align: center;
}

.CardGridItem:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 6px 0 #cbd3e1;
}

.CardGridItem-imgContainer {
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  margin: auto;
}

.CardGridItem-title {
  margin-left: 8px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.8px;
}

.highcharts-root,
.highcharts-container {
  width: 100% !important;
}

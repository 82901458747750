.Select-control {
  border-radius: 0.25rem;
  height: 34px;
}

.Select-placeholder,
.Select--single > .Select-control .Select-value,
.Select-input {
  line-height: 34px;
}

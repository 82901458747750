@import 'src/client/components/src/Common/variables.scss';

.Modal-header {
  position: relative;
  display: flex;
}

.Modal-header .Modal-crossCloseButton .Button-icon {
  color: #cccccc;
  transition: color var($transition, primary);
}

.Modal-header .Modal-crossCloseButton:hover .Button-icon {
  color: #000000;
}

@import 'src/client/components/src/Common/variables.scss';

.Search-icon {
  color: #777;
  transition: color var($transition, primary);
}

.Search-input {
  &:focus ~ .Search-icon {
    color: var($colors, text);
  }
}

.tag-advanced-settings {
}

.uib-accordion .uib-accordion-group .uib-accordion-heading .uib-accordion-toggle {
	background: none;
	padding: 0px;
}

.uib-accordion-group {
	margin-bottom: inherit;
	border: inherit;
}

.uib-accordion-inner {
	padding: inherit;
	border-top: inherit;
}



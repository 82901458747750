.GooglePubSubForm {
  &-configureButton {
    height: auto;
    width: 100%;
  }

  &-jsonCertificateField textarea {
    min-height: 176px;
    max-height: 500px;
  }
}

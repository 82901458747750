@import 'src/client/components/src/Common/variables.scss';

.Dropzone {
  border: 1px dashed var($colors, link);
  border-radius: 5px;
  cursor: pointer;
  padding: 1rem;
  text-align: center;
}

.Dropzone-preview {
  margin: var($space, default) 0;
}

.Dropzone-title {
  display: block;
  margin: var($space, s) 0;
}

.Dropzone-preview-image {
  max-width: 100%;
  max-height: 200px;
  object-fit: contain;
}

.Dropzone--error {
  border-color: var($colors, error);
  color: var($colors, error);
}

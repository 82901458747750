@import 'src/client/components/src/Common/variables.scss';

/*
 Display Utilities
 forked from: suitcss/utils-display
*/

.u-block {
  display: block !important;
}

.u-flex {
  display: flex !important;
}

.u-flex-align {
  display: flex !important;
  align-items: center;
}

.u-flex-align-end {
  display: flex !important;
  align-items: flex-end !important;
}

.u-flex-justify-end {
  display: flex !important;
  justify-content: flex-end !important;
}

.u-flex-column {
  display: flex !important;
  flex-direction: column;
}

.u-hidden {
  display: none !important;
}

/*
  Completely remove from the flow but leave available to screen readers.
 */
.u-hiddenVisually {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

.u-inline {
  display: inline !important;
}

.u-visibilityHidden {
  visibility: hidden;
}

.u-pointerEventsNone {
  pointer-events: none;
}

/*
  1. Fix for Firefox bug: an image styled `max-width:100%` within an
  inline-block will display at its default size, and not limit its width to
  100% of an ancestral container.
 */
.u-inlineBlock {
  display: inline-block !important;
  max-width: 100%;
}

.u-table {
  display: table !important;
}

.u-tableCell {
  display: table-cell !important;
}

.u-tableRow {
  display: table-row !important;
}

.is-disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.55;
}

.is-invalid {
  color: var($colors, error);
}

.is-correct {
  color: var($colors, success);
}

/*
  Font weights
 */

.u-textBold {
  font-weight: bold;
}

@import 'src/less/variables.less';

#navigation {
    min-height: 40px;

    #logo {
        margin: 0 12px;
    }

    #brand {
        font-weight: 700;
        background: none;
        width: 120px;
        margin-top: 4px;
        padding: 0px 10px 0 0;
    }

    li a {
        font-size: 14px;
    }

    a.inactive {
        color: #686868 !important;
    }

    .dropdown {
        cursor: pointer;
    }

    li.active a,
    div.active a {
        background-color: @r42-themeSupportColor !important;
        color: #fff !important;
    }

    li.active .dropdown-menu a,
    div.active .dropdown-menu a {
        background-color: #fff !important;
        color: #000 !important;
    }
    li.active .dropdown-menu a:hover,
    div.active .dropdown-menu a:hover {
        background-color: #46a538 !important;
        color: #fff !important;
    }

    li.feature-flag {
      margin: 8px 4px;
      padding: 2px 5px;
      border-radius: 3px;
      letter-spacing: 1px;
      box-sizing: border-box;
      font-weight: bold;
      color: white;
      text-transform: capitalize;
    }

    li.feature-flag.beta-flag, li.feature-flag.pre-flag, li.feature-flag.demo-flag {
      background-color: #0054b0;
    }

    .dropdown-menu {
        .r42-searchable-selected {
            background-color: #46a538 !important;
        }
    }

    .user div.dropdown .dropdown-toggle {
        padding-top: 10px;
        padding-bottom: 12px;
        span {
            padding-right: 10px;
        }
    }

    @media screen and (max-width: 1280px) {
        .user div.dropdown .dropdown-hide {
            display: none;
        }
    }

    .search-form > .search-pane {
        border: none;

        #siteSearch {
            width: 100%;
            padding: 0px;
            box-sizing: border-box;

            input {
                box-sizing: border-box;
                left: 0px;
                height: 30px;
                width: 100%;
            }

            .add-on {
                position: absolute;
                right: 5px;
            }
        }
    }
}

#subnavigation {
    background-color: @r42-themeSupportColor;
    font-size: 11px;
    .sub-nav {
        margin-right: 0;
    }
    ul {
        margin: 0;

        li {
            float: left;
            position: relative;

            list-style-type: none;
            line-height: 17px;

            a {
                color: #fff;
                display: block;
                padding: 5px 15px;
                font-size: 14px;

                &:hover {
                    font-weight: bolder;
                    text-decoration: none;
                }
            }

            .publishing-badge {
                background-color: #138dd1;
                margin-left: 2px;
                padding: 1px;
                padding-left: 2px;
                padding-right: 2px;
                width: 17px;
                text-align: center;
                font-size: 10px;
                display: inline-block;
                line-height: 14px;
            }
        }

        li.active {
            font-weight: bold;

            &:before {
                bottom: 0%;
                margin-bottom: 0px;
                left: 50%;
                content: ' ';
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border: 6px solid transparent;
                border-bottom-color: #fff;
                margin-left: -9px;
                z-index: 1;
            }
        }
    }
}

.insufficient-permissions-warning {
    margin-bottom: 1px;
    margin-top: 5px;
    color: #666;
}

#selectSiteContainer {
    right: 30px;
    margin-top: 5px;
    z-index: 2;
    position: absolute;
}

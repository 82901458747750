.react-codemirror2 {
  .CodeMirror,
  .CodeMirror-gutters,
  .CodeMirror-scroll {
    overflow: hidden !important;
  }

  .CodeMirror-gutters {
    padding-bottom: 0;
  }
}

@import 'src/less/variables.less';

.form-wizard.wizard-vertical {
	.modal-footer {
		.btn {
			width: 109px;
			padding: 6px 27px 6px 18px;
		}
	}

	.wizard-steps {
		float: inherit;
		width: 1000px;
		height: 50px;
		min-height: inherit;
		background: white;
		margin: auto;

		*[data-ng-click] {
			cursor: inherit;
		}

		.passed {
			*[data-ng-click] {
				cursor: pointer;
			}
		}

		.active {
			*[data-ng-click] {
				cursor: pointer;
			}
		}
	}

	.wizard-steps > li, .wizard-steps > li.active {
		float: left;
		width: 300px !important;
		border-bottom: 0px;
		border-top: 0px;
	}


	.step {
		background-color: white;

		.codemirror-relative-wrapper {
			min-height: 350px;
		}

		li {
			background: white;
			border-top: 0px;

			.single-step .title {
				display: block;
				height: 23px;
				line-height: 23px;
				margin-right: 5px;
				width: 23px;

			}
		}

		li.passed .single-step .title {
			background-color: @r42-themeSupportColor;
			color: white;
			opacity: 0.6;
		}
	}

	.form-content {
		margin-left: 0px;
		border-left: 0px;
		clear: both;
	}

	.tag-summary {
		.control-group {
			padding: 0px;
		}
	}
}


.button-container {
	margin-bottom: 10px;

	button.wizardbutton {
		width: 140px;
		margin-right: 10px;
		padding: 9px 34px;

		p.text {
			text-align: center;
			height: 10px;
			line-height: 22px;
		}

		p.image {
			position: absolute;
			right: 16px;
		}
	}
}

#tagCodeForm {
    .codemirror-relative-wrapper {
        .controls {
            margin-left:5px; padding-bottom: 5px;
        }
    }
}

.template-form-step2 {
    .optional-property-selection {
        max-width: 62%;
        clear: both;
    }
}

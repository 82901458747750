@import 'src/less/variables.less';

.searchResults.suggestions {

  background: white;
  width: 390px;
  position: absolute;

  ul {
	height: 200px;
	overflow-y: auto;
  }

  li:first-child {
	background-color: rgb(245, 245, 245);
  }
}

body > .searchResults.suggestions {

  z-index: @r42-modal-zindex + 1;

}

.content-rules {
  border-left: 1px solid #ddd;

  i {
    margin-right: 10px;
  }
}

.placement-group-container {
  .header {
    font-weight: 500;
    font-size: 15px;
    margin-bottom: 25px;
  }
}

table.placement-matrix {

  .alert-error {
    padding: 2px 2px 2px 5px;
  }

  .r42-help-circled {
    color: #BD3939;
  }
}

.sortHandle {
  cursor: pointer;
}

.dragHandle {
  &.enabled {
    z-index: 9999;
  }
  &.disabled {
    pointer-events: none;
  }
}

.popover-text-normal {
  .popover {
    font-weight: normal;
  }
}

.offline-ui .offline-ui-retry:before {
  content: "Reconnect";
}

.offline-ui.offline-ui-up .offline-ui-content:before {
  content: "Your connection has been restored.";
}

.offline-ui.offline-ui-down .offline-ui-content:before {
  content: "Your internet connection seems offline.";
}

.offline-ui.offline-ui-down.offline-ui-connecting .offline-ui-content:before, .offline-ui.offline-ui-down.offline-ui-connecting-2s .offline-ui-content:before {
  content: "Attempting to reconnect...";
}

.offline-ui.offline-ui-down.offline-ui-waiting .offline-ui-content[data-retry-in-unit="second"]:before {
  content: "Connection lost. Reconnecting in " attr(data-retry-in-value) " seconds...";
}

.offline-ui.offline-ui-down.offline-ui-waiting .offline-ui-content[data-retry-in-unit="second"][data-retry-in-value="1"]:before {
  content: "Connection lost. Reconnecting in " attr(data-retry-in-value) " second...";
}

.offline-ui.offline-ui-down.offline-ui-waiting .offline-ui-content[data-retry-in-unit="minute"]:before {
  content: "Connection lost. Reconnecting in " attr(data-retry-in-value) " minutes...";
}

.offline-ui.offline-ui-down.offline-ui-waiting .offline-ui-content[data-retry-in-unit="minute"][data-retry-in-value="1"]:before {
  content: "Connection lost. Reconnecting in " attr(data-retry-in-value) " minute...";
}

.offline-ui.offline-ui-down.offline-ui-waiting .offline-ui-content[data-retry-in-unit="hour"]:before {
  content: "Connection lost. Reconnecting in " attr(data-retry-in-value) " hours...";
}

.offline-ui.offline-ui-down.offline-ui-waiting .offline-ui-content[data-retry-in-unit="hour"][data-retry-in-value="1"]:before {
  content: "Connection lost. Reconnecting in " attr(data-retry-in-value) " hour...";
}

.offline-ui.offline-ui-down.offline-ui-reconnect-failed-2s.offline-ui-waiting .offline-ui-retry {
  display: none;
}

.offline-ui.offline-ui-down.offline-ui-reconnect-failed-2s .offline-ui-content:before {
  content: "Connection attempt failed.";
}

@import 'src/less/variables.less';


.r42-ui {
    .autocomplete-dropdown {
        .wrapper-input {
            border: 1px solid @r42-lightgrey-journey;
            background: @r42-white;
            input, button {
                border: 0;
                background: transparent;
            }
            input {
                width: 73%;
            }
            button {
                color: @r42-grey-journey;
                font-size: 1em;
                font-weight: bold;
                width: 12%;
            }
            &.error {
                border: 1px solid @r42-red-error-notification-border;
            }
        }

        .error-block {
            display:block;
            color: @r42-red-validation-text
        }

        .uib-dropdown-menu {
            width: 100%;
            border: 1px solid @r42-lightgrey-journey;
            max-height: 200px;
            overflow: auto;
        }
        .no-result-values {
            color: @r42-red-validation-text;
            padding-left: 10px;
            padding-right: 10px;

        }
    }
    .custom-popup-wrapper {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 1000;
        display: none;
        background-color: #f9f9f9;
    }

    .custom-popup-wrapper > .message {
        padding: 10px 20px;
        border-bottom: 1px solid #ddd;
        color: #868686;
    }

    .custom-popup-wrapper > .dropdown-menu {
        max-height: 200px;
        overflow-y: auto;
        overflow-x: hidden;
        position: static;
        float: none;
        display: block;
        min-width: 160px;
        background-color: transparent;
        border: none;
        border-radius: 0;
        box-shadow: none;
    }
}

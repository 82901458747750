@import 'src/client/components/src/Common/variables.scss';
@import 'src/client/components/src/Common/mixins.scss';

.SelectableListItem {
  box-sizing: border-box;
  padding: 9px 8px;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 24px;
  transition: 0.3s ease-in-out;
}

.SelectableListItem:hover {
  @include hoverGlow;
}

.SelectableListItem:hover .SelectableListItem-title,
.SelectableListItem:hover .SelectableListItem-description {
  color: var($colors, indicationForeground);
}

.SelectableListItem-textContainer {
  margin-left: 24px;
  overflow: hidden;
}

.SelectableListItem-title,
.SelectableListItem-description {
  font-size: 16px;
}

.SelectableListItem-title {
  font-weight: 500;
}

.SelectableListItem-description {
  color: #444444;
}

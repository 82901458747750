.Empty {
  top: 30%;
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 2px 0 #cbd3e1;

  .Empty-body {
    line-height: 24px;
    letter-spacing: 1px;
    color: #888888;
    max-width: 360px;
    margin-bottom: 16px;
  }

  &-withoutPanel {
    box-shadow: none;
    padding: 24px;
  }

  &-icon {
    margin-bottom: 24px;
  }
}

.ipRuleCondition {
	.rule {
		padding-left: 0px;
		padding-top: 0px;
		padding-bottom: 10px;
	  	padding-top: 10px;
	  	padding-left: 0;
	}
	
	.octet, .subnetmask {
		width: 36px;
		padding-left: 10px;
	}
}

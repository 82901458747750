/**
 Steps Component
 */

.Steps {
  display: flex;
  flex-direction: row;
  width: 70%;
  margin-left: 15%;
  // width: 447px;
  justify-content: space-between;
  padding: 20px 0;
}

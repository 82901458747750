ul.used-in-actions {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        float: left;
        padding-right: 10px;
    }
}

.used-in-icon {
    height: 20px !important;
    opacity: .4;

    &.active {
        opacity: 1;
    }
}

.Mapping {
  display: flex;
  padding: 12px 0;
}

.Mapping + .Mapping {
  border-top: 1px solid #eaeaea;
}

.MappingListHeader-item {
  font-weight: 500;
}

.Mapping-item {
  display: flex;
  align-items: center;
}

.MappingListHeader-item + .MappingListHeader-item,
.Mapping-item + .Mapping-item {
  margin-left: 16px;
}

.MappingListHeader-destination,
.Mapping-destination,
.MappingListHeader-source,
.Mapping-source {
  flex: 3;
}

.MappingListHeader-transformations,
.Mapping-transformations {
  flex: 1;
}

.MappingListHeader-required,
.Mapping-required {
  flex: 1;
}

.MappingListHeader-required {
  text-align: center;
}

.Mapping-required .CheckboxElement {
  margin: 0 auto;
}

.Mapping-destinationOptionName {
  font-weight: 500;
}

.DateFormatModal {
  height: 630px;
}

.DateFormatModal-table {
  width: 100%;

  td,
  th {
    padding: 5px;
  }
}

.DateFormatModal-form {
  label {
    font-weight: 700;
    padding: 0 5px 0 0;
  }
}

.DateFormat-Container {
  display: flex;

  .DateFormat-dateInput {
    width: 56px;
  }

  .DateFormat-CalendarTrigger {
    width: 40px;
  }
}

.DateFormat-TimeFrameSelect {
  width: 60%;
  margin-right: 2px;
}

.DateFormatModal-controlButtons {
  position: absolute;
  bottom: 20px;
}

.DateFormat-dateSelect {
  flex: 1;
}

.RuleCardEdit-filterCell .DateFormat-Container > * + * {
  margin-left: 4px;
}

.RuleCardEdit-table-row .FieldWrapper-errorOrHintContainer {
  position: relative !important;
}

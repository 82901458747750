@function var($map, $name...) {
  $i: 1;
  $length: length($name);

  @while $length >= $i {
    $map: map-get($map, nth($name, $i));
    $i: $i + 1;
  }

  @return $map;
}

$borderRadius: (
  default: 2px,
);

$colors: (
  theme: #33b062,
  link: #0054b0,
  error: #b7000a,
  warning: #fde939,
  success: #33b062,
  info: #0090e1,
  headings: #3c3c3b,
  modalHeading: #0054b0,
  text: #221f20,
  textInactive: #6f6f6f,
  border: #c5c5c5,
  white: #ffffff,
  indicationBackground: #d1e7ff,
  indicationForeground: rgb(59 130 246),
  background: #fafafa,
);

$fontSize: (
  s: 1.2rem,
  m: 1.4rem,
  default: 1.4rem,
  l: 1.7rem,
  xl: 2rem,
  xxl: 2.3rem,
);

$height: (
  default: 3.4rem,
);

$outline: 2px solid rgba(118, 172, 235, 0.7);

$space: (
  xs: 0.25rem,
  s: 0.5rem,
  m: 1rem,
  default: 1rem,
  l: 1.5rem,
  xl: 2rem,
  xxl: 2.5rem,
);

$transition: (
  primary: unquote('.3s ease'),
);

$stackedWidth: 1200px;

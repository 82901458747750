.r42-ui {
	table.tagList {

        tr.disabledTag {
            .tagName {
                color: #bbb;
            }
        }
        tr.excludedTag {
            .tagName {
                text-decoration: line-through;
            }
        }

        .r42-journey {
            vertical-align: -2px;

            &.disabled {
                filter: grayscale(100%) brightness(500%) brightness(60%);
            }

            &.enabled {
                filter: grayscale(100%) brightness(-5)
            }
        }
	}
}

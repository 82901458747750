.CustomerDataSearch {
  flex: 1;

  &-SearchForm {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.CustomerDataSearch .FieldWrapper + .FieldWrapper {
  margin-left: 32px;
  margin-top: 0px;
}

.CustomerDataSearch-partnerId .FieldWrapper-fieldWrap {
  width: 300px;
  position: relative;
}

.CustomerDataSearch-partnerType .FieldWrapper-fieldWrap {
  width: 150px;
  position: relative;
}

.CustomerDataSearch-partnerId .FieldWrapper-fieldWrap .InputElement[type='text'],
.CustomerDataSearch-partnerType .FieldWrapper-fieldWrap .InputElement[type='text'] {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}

.CustomerDataSearch-SearchForm .FieldWrapper-errorOrHintContainer {
  position: absolute;
  width: 100%;
}

.r42-ui {
	.placementExists {
		text-decoration: line-through;
	}
}

.ui-scrollfix {
	position: fixed;
	top: 68px;
	z-index: 1040;
}

.button-bar {
	background-color: #dbdadc;
	padding-botom:1px;
	padding-top:1px;
	margin-bottom:3px;
	border-bottom:1px solid #ccc;
}

.r42-creative-preview{
    margin-top:-62px;
}

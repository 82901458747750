@import 'src/client/components/src/Common/variables.scss';
@import '../../../../theme/colors';

.StackedChart {
  &-header {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 2em;
  }

  &-generalData {
    width: 100%;
    display: grid;

    &--count {
      font-family: Roboto;
      font-size: 28px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: 1px;
      margin-bottom: 8px;
    }
  }

  &-generalDataTooltip {
    margin: 0 8px;
  }
}

@media screen and (max-width: 600px) {
  .StackedChart {
    &-header {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 24px;
    }
  }
}

.highcharts-root,
.highcharts-container {
  width: 100% !important;
}

@import 'src/client/components/src/Common/variables.scss';

$tick: (
  borderRadius: var($borderRadius, default),
  curve: cubic-bezier(0.65, 0, 0.45, 1),
  color: var($colors, white),
  on: (
    background: var($colors, link),
    color: var($colors, white),
    curve: cubic-bezier(0.65, 0, 0.45, 1),
  ),
  off: (
    background: var($colors, white),
    border: 1px solid var($colors, border),
  ),
);

.Tick {
  cursor: pointer;
  min-height: 2.4rem;
  position: relative;

  &.is-disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.55;
  }
}

.Tick-container {
  height: 2.4rem;
  left: 0;
  position: absolute;
  top: 0;
  width: 2.4rem;
}

.Tick-element {
  background: var($tick, off, background);
  border: var($tick, off, border);
  border-radius: var($tick, borderRadius);
  box-sizing: border-box;

  transition:
    border-color 0.3s ease-out,
    background 0.3s ease-out;

  height: 2.4rem;
  left: 0;
  position: absolute;
  top: 0;
  width: 2.4rem;

  &.is-checked {
    background: var($tick, on, background);
    border-color: var($tick, on, background);
    transition: background 0.4s ease-out;
  }
}

.Tick-svg {
  border-radius: 2px;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  stroke-width: 4;
  stroke: var($tick, color);
  stroke-miterlimit: 10;
  top: 0;

  &.is-checked {
    display: block;
  }
}

.Tick-svgTick.is-animated {
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.2s var($tick, curve) 0.4s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

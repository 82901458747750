.DocumentationLink-chartContainer {
  position: relative;
}

.DocumentationLink-linkContainer {
  position: absolute;
  top: 0;
  right: 0;
}

.DocumentationLink-linkContainer .TooltipTriggerArea a {
  color: #333333;
}

.DocumentationLink-linkContainer .TooltipTriggerArea:hover a {
  color: #0054b0;
}

.DocumentationLink-linkContainer .Tooltip--leftPlacement {
  right: 24px;
  left: auto;
}

.DocumentationLink-linkContainer .Tooltip--leftPlacement::after {
  right: -4px;
  left: auto;
}

.r42-ui {
	.box {
		.websitePathGroup-title {
			.editable-default {
				display: inline-block;
			}
		}
	}

}

/*******************************************************************************
 * Tree container
 */
ul.dynatree-container
{
  font-family: tahoma, arial, helvetica;
  font-size: 10pt; /* font size should not be too big */
  white-space: nowrap;
  padding: 3px;
  margin: 0; /* issue 201 */
  background-color: white;
  /*overflow: auto;*/
  height: 100%; /* issue 263 */
}

ul.dynatree-container ul
{
  padding: 0 0 0 16px;
  margin: 0;
}

ul.dynatree-container li
{
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
  -moz-background-clip:border;
  -moz-background-inline-policy: continuous;
  -moz-background-origin: padding;
  background-attachment: scroll;
  background-color: transparent;
  background-position: 0 0;
  background-repeat: repeat-y;
  background-image: none;  /* no v-lines */

  margin:0;
  padding:0 0 0 0;
}
/* Suppress lines for last child node */
ul.dynatree-container li.dynatree-lastsib
{
  background-image: none;
}
/* Suppress lines if level is fixed expanded (option minExpandLevel) */
ul.dynatree-no-connector > li
{
  background-image: none;
}

/* Style, when control is disabled */
.ui-dynatree-disabled ul.dynatree-container
{
  opacity: 0.5;
/*  filter: alpha(opacity=50); /* Yields a css warning */
  background-color: silver;
}


/*******************************************************************************
 * Common icon definitions
 */
span.dynatree-empty,
span.dynatree-vline,
span.dynatree-connector,
span.dynatree-expander,
span.dynatree-icon,
span.dynatree-checkbox,
span.dynatree-radio,
span.dynatree-drag-helper-img,
#dynatree-drop-marker
{
  width: 16px;
  height: 16px;
/*  display: -moz-inline-box; /* @ FF 1+2 removed for issue 221*/
/*  -moz-box-align: start; /* issue 221 */
  display: inline-block; /* Required to make a span sizeable */
  vertical-align: top;
  background-repeat: no-repeat;
  background-position: left;
  background-image: url("../img/dynatree/icons.gif");
  background-position: 0 0;
}

/** Used by 'icon' node option: */
ul.dynatree-container img
{
  width: 16px;
  height: 16px;
  margin-left: 3px;
  vertical-align: top;
  border-style: none;
}


/*******************************************************************************
 * Lines and connectors
 */

/*
span.dynatree-empty
{
}
span.dynatree-vline
{
}
*/
span.dynatree-connector
{
  background-image: none;
}
/*
.dynatree-lastsib span.dynatree-connector
{
}
*/
/*******************************************************************************
 * Expander icon
 * Note: IE6 doesn't correctly evaluate multiples class names,
 *     so we create combined class names that can be used in the CSS.
 *
 * Prefix: dynatree-exp-
 * 1st character: 'e': expanded, 'c': collapsed
 * 2nd character (optional): 'd': lazy (Delayed)
 * 3rd character (optional): 'l': Last sibling
 */

span.dynatree-expander
{
  background-position: 0px -80px;
  cursor: pointer;
}
span.dynatree-expander:hover
{
  background-position: -16px -80px;
}
.dynatree-exp-cl span.dynatree-expander /* Collapsed, not delayed, last sibling */
{
}
.dynatree-exp-cd span.dynatree-expander /* Collapsed, delayed, not last sibling */
{
}
.dynatree-exp-cdl span.dynatree-expander /* Collapsed, delayed, last sibling */
{
}
.dynatree-exp-e span.dynatree-expander,  /* Expanded, not delayed, not last sibling */
.dynatree-exp-ed span.dynatree-expander,  /* Expanded, delayed, not last sibling */
.dynatree-exp-el span.dynatree-expander,  /* Expanded, not delayed, last sibling */
.dynatree-exp-edl span.dynatree-expander  /* Expanded, delayed, last sibling */
{
  background-position: -32px -80px;
}
.dynatree-exp-e span.dynatree-expander:hover,  /* Expanded, not delayed, not last sibling */
.dynatree-exp-ed span.dynatree-expander:hover,  /* Expanded, delayed, not last sibling */
.dynatree-exp-el span.dynatree-expander:hover,  /* Expanded, not delayed, last sibling */
.dynatree-exp-edl span.dynatree-expander:hover  /* Expanded, delayed, last sibling */
{
  background-position: -48px -80px;
}
.dynatree-loading span.dynatree-expander  /* 'Loading' status overrides all others */
{
  background-position: 0 0;
  background-image: url("../img/ajax-loader.gif");
}


/*******************************************************************************
 * Checkbox icon
 */
span.dynatree-checkbox
{
  margin-left: 3px;
  background-position: 0px -32px;
}
span.dynatree-checkbox:hover
{
  background-position: -16px -32px;
}

.dynatree-partsel span.dynatree-checkbox
{
  background-position: -64px -32px;
}
.dynatree-partsel span.dynatree-checkbox:hover
{
  background-position: -80px -32px;
}

.dynatree-selected span.dynatree-checkbox
{
  background-position: -32px -32px;
}
.dynatree-selected span.dynatree-checkbox:hover
{
  background-position: -48px -32px;
}

/*******************************************************************************
 * Radiobutton icon
 * This is a customization, that may be activated by overriding the 'checkbox'
 * class name as 'dynatree-radio' in the tree options.
 */
span.dynatree-radio
{
  margin-left: 3px;
  background-position: 0px -48px;
}
span.dynatree-radio:hover
{
  background-position: -16px -48px;
}

.dynatree-partsel span.dynatree-radio
{
  background-position: -64px -48px;
}
.dynatree-partsel span.dynatree-radio:hover
{
  background-position: -80px -48px;
}

.dynatree-selected span.dynatree-radio
{
  background-position: -32px -48px;
}
.dynatree-selected span.dynatree-radio:hover
{
  background-position: -48px -48px;
}

/*******************************************************************************
 * Node type icon
 * Note: IE6 doesn't correctly evaluate multiples class names,
 *     so we create combined class names that can be used in the CSS.
 *
 * Prefix: dynatree-ico-
 * 1st character: 'e': expanded, 'c': collapsed
 * 2nd character (optional): 'f': folder
 */

span.dynatree-icon /* Default icon */
{
  margin-left: 3px;
  background-position: 0px 0px;
}

.dynatree-has-children span.dynatree-icon /* Default icon */
{
/*    background-position: 0px -16px; */
}

.dynatree-ico-cf span.dynatree-icon  /* Collapsed Folder */
{
  background-position: 0px -16px;
}

.dynatree-ico-ef span.dynatree-icon  /* Expanded Folder */
{
  background-position: -64px -16px;
}

/* Status node icons */

.dynatree-statusnode-wait span.dynatree-icon
{
  background-image: url("../img/ajax-loader.gif");
}

.dynatree-statusnode-error span.dynatree-icon
{
  background-position: 0px -112px;
/*  background-image: url("ltError.gif");*/
}

/*******************************************************************************
 * Node titles
 */

/* @Chrome: otherwise hit area of node titles is broken (issue 133)
   Removed again for issue 165; (133 couldn't be reproduced) */
span.dynatree-node
{
/*  display: -moz-inline-box; /* issue 133, 165, 172, 192. removed for issue 221 */
/*  -moz-box-align: start; /* issue 221 */
/*  display: inline-block; /* Required to make a span sizeable */
}


/* Remove blue color and underline from title links */
ul.dynatree-container a
/*, ul.dynatree-container a:visited*/
{
  color: black; /* inherit doesn't work on IE */
  text-decoration: none;
  vertical-align: top;
  margin: 0px;
  margin-left: 3px;
/*  outline: 0; /* @ Firefox, prevent dotted border after click */
  /* Set transparent border to prevent jumping when active node gets a border
     (we can do this, because this theme doesn't use vertical lines)
     */
  border: 1px solid white; /* Note: 'transparent' would not work in IE6 */

}

ul.dynatree-container a:hover
{
/*  text-decoration: underline; */
/*  background: #F2F7FD; */ /* light blue */
/*  border-color: #B8D6FB;* //* darker light blue */
}

span.dynatree-node a
{
  display: inline-block; /* Better alignment, when title contains <br> */
/*  vertical-align: top;*/
  padding-left: 3px;
  padding-right: 3px; /* Otherwise italic font will be outside bounds */
  /*  line-height: 16px; /* should be the same as img height, in case 16 px */
}
span.dynatree-folder a
{
/*  font-weight: bold; */ /* custom */
}

ul.dynatree-container a:focus,
span.dynatree-focused a:link  /* @IE */
{
  background-color: #EFEBDE; /* gray */
}

span.dynatree-has-children a
{
/*  font-style: oblique; /* custom: */
}

span.dynatree-expanded a
{
}

span.dynatree-selected a
{
/*  color: green; */
  font-style: italic;
}


/* Highlight the item in the tree if SinglePage. If PageHierarchy: Highlight the item AND all it's children */
.tagLocationType-SinglePageLocation span.dynatree-focused > a, .tagLocationType-PageHierarchyLocation span.dynatree-focused ~ ul a {
  border: 1px solid #99DEFD;
  background-color: #D8F0FA;
}

/*******************************************************************************
 * Drag'n'drop support
 */

/*** Helper object ************************************************************/
div.dynatree-drag-helper
{
}
div.dynatree-drag-helper a
{
  border: 1px solid gray;
  background-color: white;
  padding-left: 5px;
  padding-right: 5px;
  opacity: 0.8;
}
span.dynatree-drag-helper-img
{
  /*
  position: relative;
  left: -16px;
  */
}
div.dynatree-drag-helper /*.dynatree-drop-accept*/
{
/*    border-color: green;
  background-color: red;*/
}
div.dynatree-drop-accept span.dynatree-drag-helper-img
{
  background-position: -32px -112px;
}
div.dynatree-drag-helper.dynatree-drop-reject
{
  border-color: red;
}
div.dynatree-drop-reject span.dynatree-drag-helper-img
{
  background-position: -16px -112px;
}

/*** Drop marker icon *********************************************************/

#dynatree-drop-marker
{
  width: 24px;
  position: absolute;
  background-position: 0 -128px;
  margin: 0;
}
#dynatree-drop-marker.dynatree-drop-after,
#dynatree-drop-marker.dynatree-drop-before
{
  width:64px;
  background-position: 0 -144px;
}
#dynatree-drop-marker.dynatree-drop-copy
{
  background-position: -64px -128px;
}
#dynatree-drop-marker.dynatree-drop-move
{
  background-position: -64px -128px;
}

/*** Source node while dragging ***********************************************/

span.dynatree-drag-source
{
  /* border: 1px dotted gray; */
  background-color: #e0e0e0;
}
span.dynatree-drag-source a
{
  color: gray;
}

/*** Target node while dragging cursor is over it *****************************/

span.dynatree-drop-target
{
  /*border: 1px solid gray;*/
}
span.dynatree-drop-target a
{
}
span.dynatree-drop-target.dynatree-drop-accept a
{
  /*border: 1px solid green;*/
  background-color: #3169C6 !important;
  color: white !important; /* @ IE6 */
  text-decoration: none;
}
span.dynatree-drop-target.dynatree-drop-reject
{
  /*border: 1px solid red;*/
}
span.dynatree-drop-target.dynatree-drop-after a
{
}

/* NEW DYNATREE ICONS */
span.dynatree-icon {

}

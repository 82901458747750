// Colors
// Accents
$blue: #368ee0;
$orange: #f8a31f;
$red: #E51400;
$green: #339933;
$lime: #8CBF26;
$teal: #00ABA9;
$purple: #FF0097;
$brown: #A05000;
$pink: #E671B8;
$magenta: #A200FF;
$grey: #333333;
$darkBlue: #204e81;
$lightRed: #e63a3a;
$lightGrey: #666666;
$satBlue: #2c5e7b;
$satGreen: #56af45;
$grey-3: #eee;
$grey-4: #999;

// Blacks
$almostBlack: #171717;
// Greys
$dark: #2a2a2a;
$light: #FFF;
$bg: #eee;

// Fonts
$font: #e9e9e9;

// Synovite Theme color
$r42green: #33b062;

$colornames: "r42green";
$colors: $r42green;

$fillColorNames: ("blue","orange","red","green","lime","teal","purple","brown","pink","magenta","grey","darkblue","lightred","lightgrey","satblue","satgreen","grey");
$fillColors: ($blue, $orange, $red, $green, $lime, $teal, $purple, $brown, $pink, $magenta, $grey, $darkBlue, $lightRed, $lightGrey, $satBlue, $satGreen, $grey);

.theme-r42green {
	@for $i from 0 through length($fillColors) - 1 {
	  ul>li.#{unquote(nth($fillColorNames, $i+1))} {
	    background: nth($fillColors, $i+1);
	  }
	  .label-#{unquote(nth($fillColorNames, $i+1))} {
	    background: nth($fillColors, $i+1);
	  }
	  .btn.btn-#{unquote(nth($fillColorNames, $i+1))} {
	    background: nth($fillColors, $i+1);
	    color: white;
	  }
	}
}

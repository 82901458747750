@import 'variables.less';

.r42-ui .box .box-title {
  margin-top: 10px;
  padding: 7px 0 7px 0;
}

.r42-ui .box .box-title h3 {
  margin-top: 10px;
  display: inline-block;
}

.r42-ui .box .box-title h3 i {
  margin: 0;
}

#pathSearch.r42-ui-search-box {
    &.input-group {
        width: 100%;
    }
    input {
        width: 100%;
    }
    .input-group-addon {
        border-radius: 0;
        width: 3em !important;
        padding-right: 0;
    }
}
.r42-ui-search-box {
    width: 100%;
    input {
        width: 100%;
    }
    .input-group-addon {
        border-radius: 0;
        width: auto;
    }
}
//Helpers

.r42-ui .align-right {
    text-align: right;
}

.recommenderprofiles .meta {
    margin-left: 5px;
}

@font-face {
  font-family: 'r42-font';
  src: url('../font/r42-font.eot?49736682');
  src: url('../font/r42-font.eot?49736682#iefix') format('embedded-opentype'),
       url('../font/r42-font.woff2?49736682') format('woff2'),
       url('../font/r42-font.woff?49736682') format('woff'),
       url('../font/r42-font.ttf?49736682') format('truetype'),
       url('../font/r42-font.svg?49736682#r42-font') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'r42-font';
    src: url('../font/r42-font.svg?49736682#r42-font') format('svg');
  }
}
*/
 
 [class^="r42-"]:before, [class*=" r42-"]:before {
  font-family: "r42-font";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.r42-firefox:before { content: '\e800'; } /* '' */
.r42-ie:before { content: '\e801'; } /* '' */
.r42-chrome:before { content: '\e802'; } /* '' */
.r42-opera:before { content: '\e803'; } /* '' */
.r42-cog-small:before { content: '\e804'; } /* '' */
.r42-cogs:before { content: '\e805'; } /* '' */
.r42-cog:before { content: '\e806'; } /* '' */
.r42-location-small:before { content: '\e808'; } /* '' */
.r42-desktop:before { content: '\e809'; } /* '' */
.r42-laptop:before { content: '\e80a'; } /* '' */
.r42-tablet:before { content: '\e80b'; } /* '' */
.r42-mobile:before { content: '\e80c'; } /* '' */
.r42-globe:before { content: '\e80d'; } /* '' */
.r42-location:before { content: '\e80e'; } /* '' */
.r42-monitor:before { content: '\e80f'; } /* '' */
.r42-mobile-big:before { content: '\e810'; } /* '' */
.r42-event:before { content: '\e811'; } /* '' */
.r42-chat:before { content: '\e812'; } /* '' */
.r42-tag-small:before { content: '\e813'; } /* '' */
.r42-deduplication:before { content: '\e814'; } /* '' */
.r42-pricetag:before { content: '\e815'; } /* '' */
.r42-tag:before { content: '\e816'; } /* '' */
.r42-align-justify:before { content: '\e817'; } /* '' */
.r42-attach:before { content: '\e818'; } /* '' */
.r42-tree-include:before { content: '\e819'; } /* '' */
.r42-network:before { content: '\e81a'; } /* '' */
.r42-bookmark-empty:before { content: '\e81b'; } /* '' */
.r42-bookmark:before { content: '\e81c'; } /* '' */
.r42-tasks:before { content: '\e81d'; } /* '' */
.r42-tree-exclude:before { content: '\e81e'; } /* '' */
.r42-list-bullet:before { content: '\e81f'; } /* '' */
.r42-cloud:before { content: '\e820'; } /* '' */
.r42-cookiepermission:before { content: '\e821'; } /* '' */
.r42-experiment:before { content: '\e822'; } /* '' */
.r42-list:before { content: '\e823'; } /* '' */
.r42-user-1:before { content: '\e824'; } /* '' */
.r42-help-circled:before { content: '\e825'; } /* '' */
.r42-pagebased:before { content: '\e826'; } /* '' */
.r42-other:before { content: '\e827'; } /* '' */
.r42-doc-text:before { content: '\e828'; } /* '' */
.r42-link-ext:before { content: '\e829'; } /* '' */
.r42-puzzle:before { content: '\e82a'; } /* '' */
.r42-visitorbased:before { content: '\e82b'; } /* '' */
.r42-calendar:before { content: '\e82c'; } /* '' */
.r42-calendar-empty:before { content: '\e82d'; } /* '' */
.r42-link:before { content: '\e82e'; } /* '' */
.r42-clock:before { content: '\e82f'; } /* '' */
.r42-unlink:before { content: '\e830'; } /* '' */
.r42-cancel:before { content: '\e831'; } /* '' */
.r42-ok-squared:before { content: '\e832'; } /* '' */
.r42-cancel-circled:before { content: '\e833'; } /* '' */
.r42-ok:before { content: '\e834'; } /* '' */
.r42-menu:before { content: '\e835'; } /* '' */
.r42-doc:before { content: '\e836'; } /* '' */
.r42-trash:before { content: '\e837'; } /* '' */
.r42-info-circled:before { content: '\e838'; } /* '' */
.r42-info:before { content: '\e839'; } /* '' */
.r42-table:before { content: '\e83a'; } /* '' */
.r42-chart-bar:before { content: '\e83b'; } /* '' */
.r42-paths:before { content: '\e83c'; } /* '' */
.r42-group:before { content: '\e83d'; } /* '' */
.r42-search:before { content: '\e83e'; } /* '' */
.r42-list-add:before { content: '\e83f'; } /* '' */
.r42-folder-add:before { content: '\e840'; } /* '' */
.r42-doc-add:before { content: '\e841'; } /* '' */
.r42-angle-double-left:before { content: '\e842'; } /* '' */
.r42-angle-double-right:before { content: '\e843'; } /* '' */
.r42-left-bold:before { content: '\e844'; } /* '' */
.r42-right-bold:before { content: '\e845'; } /* '' */
.r42-left-1:before { content: '\e846'; } /* '' */
.r42-right-1:before { content: '\e847'; } /* '' */
.r42-ellipsis-vert:before { content: '\e848'; } /* '' */
.r42-folder-full-closed:before { content: '\e849'; } /* '' */
.r42-folder-empty-open:before { content: '\e84a'; } /* '' */
.r42-folder-empty-closed:before { content: '\e84b'; } /* '' */
.r42-folder-full-open:before { content: '\e84c'; } /* '' */
.r42-folder-full-closed-fa:before { content: '\e84d'; } /* '' */
.r42-folder-empty-closed-fa:before { content: '\e84e'; } /* '' */
.r42-folder-full-open-fa:before { content: '\e84f'; } /* '' */
.r42-folder-empty-open-fa:before { content: '\e850'; } /* '' */
.r42-play:before { content: '\e851'; } /* '' */
.r42-pause:before { content: '\e852'; } /* '' */
.r42-help:before { content: '\e853'; } /* '' */
.r42-html5:before { content: '\e854'; } /* '' */
.r42-picture:before { content: '\e855'; } /* '' */
.r42-ads:before { content: '\e856'; } /* '' */
.r42-flashvideo:before { content: '\e857'; } /* '' */
.r42-upload-cloud:before { content: '\e858'; } /* '' */
.r42-doc-inv:before { content: '\e859'; } /* '' */
.r42-plus:before { content: '\e85a'; } /* '' */
.r42-angle-down:before { content: '\e85b'; } /* '' */
.r42-preview:before { content: '\e85c'; } /* '' */
.r42-download:before { content: '\e85d'; } /* '' */
.r42-datafeed:before { content: '\e85e'; } /* '' */
.r42-exchange:before { content: '\e85f'; } /* '' */
.r42-angle-up:before { content: '\e860'; } /* '' */
.r42-attention:before { content: '\e861'; } /* '' */
.r42-random:before { content: '\e862'; } /* '' */
.r42-cancel-circled-outline:before { content: '\e863'; } /* '' */
.r42-beacon:before { content: '\e864'; } /* '' */
.r42-error-alt:before { content: '\e865'; } /* '' */
.r42-times-fired:before { content: '\e866'; } /* '' */
.r42-engagementrule:before { content: '\e867'; } /* '' */
.r42-conversionrule:before { content: '\e868'; } /* '' */
.r42-customrule:before { content: '\e869'; } /* '' */
.r42-move:before { content: '\e86a'; } /* '' */
.r42-history:before { content: '\e86b'; } /* '' */
.r42-app-admin:before { content: '\e86c'; } /* '' */
.r42-role-supportengineer:before { content: '\e86d'; } /* '' */
.r42-role-contextadmin:before { content: '\e86e'; } /* '' */
.r42-app-user:before { content: '\e86f'; } /* '' */
.r42-level-up:before { content: '\e870'; } /* '' */
.r42-level-down:before { content: '\e871'; } /* '' */
.r42-export:before { content: '\e872'; } /* '' */
.r42-mail:before { content: '\e873'; } /* '' */
.r42-selection:before { content: '\e874'; } /* '' */
.r42-spinner:before { content: '\e875'; } /* '' */
.r42-js:before { content: '\e876'; } /* '' */
.r42-predefinedvalue:before { content: '\e877'; } /* '' */
.r42-stream:before { content: '\e878'; } /* '' */
.r42-pencil:before { content: '\e879'; } /* '' */
.r42-down-bold:before { content: '\e87a'; } /* '' */
.r42-attention-alt:before { content: '\e87b'; } /* '' */
.r42-left:before { content: '\e87c'; } /* '' */
.r42-right:before { content: '\e87d'; } /* '' */
.r42-circle:before { content: '\f111'; } /* '' */
.r42-remove-users:before { content: '\f235'; } /* '' */
.conditionConfiguration {

    .rules-container {

        max-width: 1400px;
        margin: 0 auto;

        .choices {

            text-align: center;
            box-sizing: border-box;
            padding-left: 0;
            padding-right: 0;
            .box-choice {
                float: none;
            }

            .box-title {
                h3 {
                  margin-left: auto;
                  margin-right: auto;
                  text-align: center;
                  vertical-align: center;
                }
            }

        }
    }
    .environment-select {
        height: inherit;
    }
}

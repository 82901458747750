.labelSelect {
  &__control {
    border: none !important;

    &--is-focused {
      border-radius: 2px !important;
      border: 1px solid #0054b050 !important;
    }
  }

  &__multi-value {
    border-radius: 10px !important;
    background-color: #0054b020 !important;
    color: #cccccc !important;

    &__label {
      color: #0054b0 !important;
    }
  }

  &__value-container {
    padding: 8px 0 !important;
  }
}

.labelSelect__indicator-separator,
.labelSelect__dropdown-indicator {
  visibility: hidden;
}

.labelSelect__loading-indicator {
  position: absolute;
}

.labelSelect__control > .labelSelect__value-container > .labelSelect__multi-value > .labelSelect__multi-value__label {
  padding-right: 8px;
}

.labelSelect__control > .labelSelect__value-container > .labelSelect__multi-value > .labelSelect__multi-value__remove {
  display: none;
}

.labelSelect__control--is-focused
  > .labelSelect__value-container
  > .labelSelect__multi-value
  > .labelSelect__multi-value__label {
  padding-right: 4px;
}

.labelSelect__control--is-focused
  > .labelSelect__value-container
  > .labelSelect__multi-value
  > .labelSelect__multi-value__remove {
  display: inherit;
  border-radius: 10px;
}

.labelSelect__control--is-focused > .labelSelect__indicators {
  visibility: visible;
}

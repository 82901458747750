@import 'src/client/components/src/Common/variables.scss';

/*
 Spacing Utils
 */

/**
 *
 * Spacing can be applied using either margin or padding. Both margin and
 * padding share the same predefined scale. There are 5 available spacing sizes:
 * Xs, S, M, L, or Xl.
 *
 *
 * .u-[margin|padding][Top|Right|Bottom|Left|Vertical|Horizontal][xs|s|m|l|xl][None]
 *
 *
 * <div class="u-paddingS">
 *   <h1 class="u-marginBottomXs">Lorem ipsum</h1>
 *   <p class="u-marginBottomM">Lorem ipsum</p>
 * </div>
 *
 */

/* Margin
     ========================================================================== */
.u-marginNone {
  margin: 0 !important;
}
.u-marginXs {
  margin: var($space, xs) !important;
}
.u-marginS {
  margin: var($space, s) !important;
}
.u-marginM {
  margin: var($space, m) !important;
}
.u-marginL {
  margin: var($space, l) !important;
}
.u-marginXl {
  margin: var($space, xl) !important;
}
.u-marginXxl {
  margin: var($space, xxl) !important;
}

.u-marginVerticalXs {
  margin-top: var($space, xs) !important;
  margin-bottom: var($space, xs) !important;
}
.u-marginVerticalS {
  margin-top: var($space, s) !important;
  margin-bottom: var($space, s) !important;
}
.u-marginVerticalM {
  margin-top: var($space, m) !important;
  margin-bottom: var($space, m) !important;
}
.u-marginVerticalL {
  margin-top: var($space, l) !important;
  margin-bottom: var($space, l) !important;
}
.u-marginVerticalXl {
  margin-top: var($space, xl) !important;
  margin-bottom: var($space, xl) !important;
}
.u-marginVerticalXxl {
  margin-top: var($space, xxl) !important;
  margin-bottom: var($space, xxl) !important;
}

.u-marginHorizontalXs {
  margin-left: var($space, xs) !important;
  margin-right: var($space, xs) !important;
}
.u-marginHorizontalS {
  margin-left: var($space, s) !important;
  margin-right: var($space, s) !important;
}
.u-marginHorizontalM {
  margin-left: var($space, m) !important;
  margin-right: var($space, m) !important;
}
.u-marginHorizontalL {
  margin-left: var($space, l) !important;
  margin-right: var($space, l) !important;
}
.u-marginHorizontalXl {
  margin-left: var($space, xl) !important;
  margin-right: var($space, xl) !important;
}
.u-marginHorizontalXxl {
  margin-left: var($space, xxl) !important;
  margin-right: var($space, xxl) !important;
}

.u-marginTopNone {
  margin-top: 0 !important;
}
.u-marginTopXs {
  margin-top: var($space, xs) !important;
}
.u-marginTopS {
  margin-top: var($space, s) !important;
}
.u-marginTopM {
  margin-top: var($space, m) !important;
}
.u-marginTopL {
  margin-top: var($space, l) !important;
}
.u-marginTopXl {
  margin-top: var($space, xl) !important;
}
.u-marginTopXxl {
  margin-top: var($space, xxl) !important;
}
.u-marginTopAuto {
  margin-top: auto !important;
}

.u-marginRightNone {
  margin-right: 0 !important;
}
.u-marginRightXs {
  margin-right: var($space, xs) !important;
}
.u-marginRightS {
  margin-right: var($space, s) !important;
}
.u-marginRightM {
  margin-right: var($space, m) !important;
}
.u-marginRightL {
  margin-right: var($space, l) !important;
}
.u-marginRightXl {
  margin-right: var($space, xl) !important;
}
.u-marginRightXxl {
  margin-right: var($space, xxl) !important;
}

.u-marginBottomNone {
  margin-bottom: 0 !important;
}
.u-marginBottomXs {
  margin-bottom: var($space, xs) !important;
}
.u-marginBottomS {
  margin-bottom: var($space, s) !important;
}
.u-marginBottomM {
  margin-bottom: var($space, m) !important;
}
.u-marginBottomL {
  margin-bottom: var($space, l) !important;
}
.u-marginBottomXl {
  margin-bottom: var($space, xl) !important;
}
.u-marginBottomXxl {
  margin-bottom: var($space, xxl) !important;
}

.u-marginLeftNone {
  margin-left: 0 !important;
}
.u-marginLeftXs {
  margin-left: var($space, xs) !important;
}
.u-marginLeftS {
  margin-left: var($space, s) !important;
}
.u-marginLeftM {
  margin-left: var($space, m) !important;
}
.u-marginLeftL {
  margin-left: var($space, l) !important;
}
.u-marginLeftXl {
  margin-left: var($space, xl) !important;
}
.u-marginLeftXxl {
  margin-left: var($space, xxl) !important;
}

/* Padding
     ========================================================================== */

.u-paddingNone {
  padding: 0 !important;
}
.u-paddingXs {
  padding: var($space, xs) !important;
}
.u-paddingS {
  padding: var($space, s) !important;
}
.u-paddingM {
  padding: var($space, m) !important;
}
.u-paddingL {
  padding: var($space, l) !important;
}
.u-paddingXl {
  padding: var($space, xl) !important;
}
.u-paddingXxl {
  padding: var($space, xxl) !important;
}

.u-paddingVerticalXs {
  padding-top: var($space, xs) !important;
  padding-bottom: var($space, xs) !important;
}
.u-paddingVerticalS {
  padding-top: var($space, s) !important;
  padding-bottom: var($space, s) !important;
}
.u-paddingVerticalM {
  padding-top: var($space, m) !important;
  padding-bottom: var($space, m) !important;
}
.u-paddingVerticalL {
  padding-top: var($space, l) !important;
  padding-bottom: var($space, l) !important;
}
.u-paddingVerticalXl {
  padding-top: var($space, xl) !important;
  padding-bottom: var($space, xl) !important;
}
.u-paddingVerticalXxl {
  padding-top: var($space, xxl) !important;
  padding-bottom: var($space, xxl) !important;
}

.u-paddingHorizontalXs {
  padding-left: var($space, xs) !important;
  padding-right: var($space, xs) !important;
}
.u-paddingHorizontalS {
  padding-left: var($space, s) !important;
  padding-right: var($space, s) !important;
}
.u-paddingHorizontalM {
  padding-left: var($space, m) !important;
  padding-right: var($space, m) !important;
}
.u-paddingHorizontalL {
  padding-left: var($space, l) !important;
  padding-right: var($space, l) !important;
}
.u-paddingHorizontalXl {
  padding-left: var($space, xl) !important;
  padding-right: var($space, xl) !important;
}
.u-paddingHorizontalXxl {
  padding-left: var($space, xxl) !important;
  padding-right: var($space, xxl) !important;
}

.u-paddingTopNone {
  padding-top: 0 !important;
}
.u-paddingTopXs {
  padding-top: var($space, xs) !important;
}
.u-paddingTopS {
  padding-top: var($space, s) !important;
}
.u-paddingTopM {
  padding-top: var($space, m) !important;
}
.u-paddingTopL {
  padding-top: var($space, l) !important;
}
.u-paddingTopXl {
  padding-top: var($space, xl) !important;
}
.u-paddingTopXxl {
  padding-top: var($space, xxl) !important;
}

.u-paddingRightNone {
  padding-right: 0 !important;
}
.u-paddingRightXs {
  padding-right: var($space, xs) !important;
}
.u-paddingRightS {
  padding-right: var($space, s) !important;
}
.u-paddingRightM {
  padding-right: var($space, m) !important;
}
.u-paddingRightL {
  padding-right: var($space, l) !important;
}
.u-paddingRightXl {
  padding-right: var($space, xl) !important;
}
.u-paddingRightXxl {
  padding-right: var($space, xxl) !important;
}

.u-paddingBottomNone {
  padding-bottom: 0 !important;
}
.u-paddingBottomXs {
  padding-bottom: var($space, xs) !important;
}
.u-paddingBottomS {
  padding-bottom: var($space, s) !important;
}
.u-paddingBottomM {
  padding-bottom: var($space, m) !important;
}
.u-paddingBottomL {
  padding-bottom: var($space, l) !important;
}
.u-paddingBottomXl {
  padding-bottom: var($space, xl) !important;
}
.u-paddingBottomXxl {
  padding-bottom: var($space, xxl) !important;
}

.u-paddingLeftNone {
  padding-left: 0 !important;
}
.u-paddingLeftXs {
  padding-left: var($space, xs) !important;
}
.u-paddingLeftS {
  padding-left: var($space, s) !important;
}
.u-paddingLeftM {
  padding-left: var($space, m) !important;
}
.u-paddingLeftL {
  padding-left: var($space, l) !important;
}
.u-paddingLeftXl {
  padding-left: var($space, xl) !important;
}
.u-paddingLeftXxl {
  padding-left: var($space, xxl) !important;
}

.ClientForm .FieldWrapper--inline {
  margin: 0;
}

.ClientForm-fiedWithCheckbox {
  margin-bottom: 24px;
  margin-top: 24px;
}

.ClientForm-criticalRateLimitInformation {
  font-size: 12px;
}

.ClientForm-CRLInfo {
  border: 1px solid #0054b020;
  padding: 8px;
  border-radius: 4px;
  margin: 8px 0;
  background: #fafafa;
  align-items: flex-start;

  &--message {
    margin: 16px 0 !important;
    color: #666666;
  }

  &--formula {
    font-weight: 500;
  }
}

.ClientForm-CRLInfo p {
  margin: 0;
  line-height: 20px;
  font-size: 12px;
}

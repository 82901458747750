.adConditionConfiguration {

	.rules-container {

		max-width: 1400px;
		margin: 0 auto;

		.choices {

			text-align: center;

			.box-choice {
				float: none;
				display:inline-block;
				padding: 30px;
			}

		}
	}
}

.modal.modal-adRules {

		top: 5%;
		box-sizing: border-box;
		height: 60%;
		width: 45%;
		left: 40%;


		.modal-header {
			height: 45px;
			box-sizing: border-box;
		}

		.modal-body {
			height: 100%;
			overflow: hidden;
			box-sizing: border-box;
			position: absolute;
			width: 100%;
		}

		.modal-footer {
			box-sizing: border-box;
			position: absolute;
			bottom: 0px;
			height: 50px;
			width: 100%;
			padding: 9px 12px;
		}
	}

@import 'src/client/components/src/Common/variables.scss';

.CardHeader-container {
  position: relative;
  background-color: var($colors, white);
  max-width: 100%;
}

.CardHeader-nameAndIcon {
  display: flex;
  align-items: center;
  position: relative;

  .Checkbox.Checkbox-label {
    padding-bottom: 0;
    margin-left: 24px;
  }
}

.CardHeader-icon {
  margin-right: 14px;
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.CardHeader-titleContainer {
  flex: 1;
}

.CardHeader-title {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.8px;
  color: #000000;
  display: flex;
  align-items: center;
}

.CardHeader-description {
  opacity: 0.6;
  font-size: 14px;
}

.CardHeader-actions {
  margin-left: auto;
  align-items: center;
}

.CardHeader-actions > *:not(:first-child) {
  margin-left: 16px;
}

.CardHeader-infoIcon {
  margin-left: 8px;
}

.codeMirrorDialog {
	.box-title {
		border-bottom: none;
		padding: 0 !important;
	}
	.codemirror-relative-wrapper {
		position: relative;
		min-height: inherit;
	}
	.codemirror-container {
		width: 100%;
	}
	
	.box-content {
		margin-bottom: 0;
	}
	
	.placeholder-label {
		padding: 5px 10px 5px 5px;
	}
	
	.codemirror-placeholders-title{
		padding-right: 17px;
	}	
}

@import 'variables.less';

.login {
	background-color: @r42-themeColor;
	min-height: 360px;

	.backgroundSynovite {
		width: 50%;
		height: 100%;
		position: absolute;
		background-color: #3d5c99;
		left: 50%;
	}
		
	.loginChooseIntro {
		position: absolute;
		top: 50%;
		width: 50%;
		left: 25%;
		background-color: #fff;
		height: 300px;
		margin-top: -150px;
		
		#logoSmall {
			height: 32px;
		}
		
		.logic-choice {
			
			width: 50%;
			border-right: 1px solid #eee;
			position: absolute;
			text-align: center;
			margin: 0px;
			padding: 0px;
			bottom: 0;
			height: 115px;
			padding-top: 20px;
			cursor: pointer;
			left: 0;
			
			&.loginSynovite {
				left: 50%;
			}
			
			.login-button {
				position: absolute;
				bottom: 0;
				height: 32px;
				width: 100%;
				text-align: center;
				line-height: 32px;
				background-color: #eee;
				font-size: 13px;
			
		}}
	}
	
	h2 {
		margin-top : 0;
		margin-bottom: 10px;
		font-weight: 300;
		color: #777;
		font-size: 22px;
	}

	h1 {
		color: #eee;
	}
	
	.wrapper {
		width: 560px;
	}

	.login-header {
		background-color: #fff;
		padding: 30px 30px 0 30px;
	}
		
	.login-body {
		background-color: #fff;
		border: 1px solid #ccc;
		-webkit-box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.2);
		-moz-box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.2);
		box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.2);
	}
	
	.forgot {
		margin-top: 20px;
	}

	.forgot a {
		display: block;
		padding: 10px;
		line-height: 30px;
		font-size: 13px;
		background-color: #eee;
		color: #555;
		text-decoration: none;
		text-align: center;
	}
	
	.forgot a:hover {
		background: #ddd;
	}
	
	.remember {
		line-height: 30px;
		
		span {
				cursor: pointer;
		}
	}
	
	.remember input {
		vertical-align: -2px;
	}
	
	.googleButton {
		margin: 20px auto;
		width: 120px;
		height: 60px;
	}
	
	.loginWith {
		font-size: 11px;
		color: #777;
	}

	.lead {
		font-size: 15px;
		margin: 0 30px 20px 30px;
	}

	.standard-form {
		margin-top:5px;
	}

	.google-login > a > img {
		height: 48px;
	}

}

.form-group-buttons {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.login-input {
	width: 97% !important;
    height: 32px !important;
}

.btn-signin, .btn-request {
	height: 42px;
	border-radius: 2px !important;
	font-size: 16px !important;
	font-weight: 600 !important;
	width: 196px !important;
}

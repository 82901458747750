@import 'src/less/variables.less';

.r42-ui {
    .dropdown-menu {

        .no-selectable-items {
            padding-left: 15px;
            padding-right: 15px;
        }

        a.disabled {
            text-decoration: line-through;
            color: @r42-lightgrey;
        }

        li.r42-searchable-selected > a {
            background: #ddd;
            color: #333;
            filter: none;
        }

        li.r42-searchable-selected > a.disabled {
            background-color: inherit;
            color: @r42-red-validation-text;
        }

        &.r42-searchable-select-options {
	        &.appendtobody {
	            border: 1px solid #ccc;
	        }
	    }
    }

    .r42-searchable-select-options {
        max-height: 300px;
        overflow-y: auto;
        border: 1px solid #ccc;
        top:auto;
        left:auto;

        li:hover > a.disabled {
            background-color: inherit;
            color: @r42-red-validation-text;
        }
        li > a.disabled:hover {
            background: none;
            color: @r42-red-validation-text;
        }
    }

    .badge--black {
        background: #000;
        border-radius: 4px;
        color: #fff;
        display: inline-block;
        font-size: 11px;
        line-height: 1;
        margin-left: 2px;
        padding: 4px;
    }
}

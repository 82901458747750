.Audience {
  display: flex;
}

.Audience-ServiceData {
  text-align: right;
  color: #8f8f8f;
}

.Audience .Breadcrumb--withFileName {
  flex: 1;
}

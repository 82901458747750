
/* Make clicks pass-through */
#loading-bar,
#loading-bar-spinner {
  pointer-events: none;
  -webkit-pointer-events: none;
  -webkit-transition: 0.1s linear all;
  -moz-transition: 0.1s linear all;
  -o-transition: 0.1s linear all;
  transition: 0.1s linear all;
}

#loading-bar.ng-enter,
#loading-bar.ng-leave.ng-leave-active,
#loading-bar-spinner.ng-enter,
#loading-bar-spinner.ng-leave.ng-leave-active {
  opacity: 0;
}

#loading-bar.ng-enter.ng-enter-active,
#loading-bar.ng-leave,
#loading-bar-spinner.ng-enter.ng-enter-active,
#loading-bar-spinner.ng-leave {
  opacity: 1;
}

#loading-bar .bar {
  -webkit-transition: width 350ms;
  -moz-transition: width 350ms;
  -o-transition: width 350ms;
  transition: width 350ms;

  background: #368ee0; /*#29d;*/
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
}

/* Fancy blur effect */
#loading-bar .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
//  box-shadow: 0 0 10px #29d, 0 0 5px #29d;
  box-shadow: 0 0 10px @r42-themeSupportColor, 0 0 5px @r42-themeSupportColor;
  opacity: 1.0;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -moz-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  -o-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

#loading-bar-spinner {
  display: block;
  position: fixed;
  z-index: 100;
  top: 10px;
  left: 10px;
}

#loading-bar-spinner .spinner-icon {
  width: 14px;
  height: 14px;

  border:  solid 2px transparent;
//  border-top-color:  #29d;
//  border-left-color: #29d;
	border-top-color:  @r42-themeSupportColor;
	border-left-color: @r42-themeSupportColor;
	border-radius: 10px;

  -webkit-animation: loading-bar-spinner 400ms linear infinite;
  -moz-animation:    loading-bar-spinner 400ms linear infinite;
  -ms-animation:     loading-bar-spinner 400ms linear infinite;
  -o-animation:      loading-bar-spinner 400ms linear infinite;
  animation:         loading-bar-spinner 400ms linear infinite;
}

@-webkit-keyframes loading-bar-spinner {
  0%   { -webkit-transform: rotate(0deg);   transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
@-moz-keyframes loading-bar-spinner {
  0%   { -moz-transform: rotate(0deg);   transform: rotate(0deg); }
  100% { -moz-transform: rotate(360deg); transform: rotate(360deg); }
}
@-o-keyframes loading-bar-spinner {
  0%   { -o-transform: rotate(0deg);   transform: rotate(0deg); }
  100% { -o-transform: rotate(360deg); transform: rotate(360deg); }
}
@-ms-keyframes loading-bar-spinner {
  0%   { -ms-transform: rotate(0deg);   transform: rotate(0deg); }
  100% { -ms-transform: rotate(360deg); transform: rotate(360deg); }
}
@keyframes loading-bar-spinner {
  0%   { transform: rotate(0deg);   transform: rotate(0deg); }
  100% { transform: rotate(360deg); transform: rotate(360deg); }
}

.progress-loading-button {
    background-position: 0 0;
    background-image: url("../img/ajax-loader-white.gif");
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-left: 8px;
}

@import 'src/client/components/src/Common/variables.scss';

.DetailContainer {
  bottom: 56px;
  position: fixed;
  right: 0;
  width: 40%;
  min-height: 360px;
  max-width: 640px;
  background-color: var($colors, white);
  box-shadow: 0 0 2px 0 #cbd3e1;
  white-space: normal;
  z-index: 11;
  border: 2px;
  transition: width 2s linear 1s;

  &-body {
    height: calc(100% - 90px);
    overflow-x: auto;
    overflow-y: visible;
  }
}

@import 'src/client/components/src/Common/variables.scss';
@import './variables.scss';

/**
 Button Component
 */
.Button {
  height: 40px;
  background: transparent;
  border: 0;
  box-sizing: border-box;
  display: inline-block;
  padding: 0;
  /**
    Conflicting with old main.less styles.
    Remove important specifier when conflicts are removed
  */
  font-family: Roboto !important;
  font-size: 16px !important;
  line-height: 1.5 !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.6px;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;

  cursor: pointer;
  user-select: none;
  transition:
    background var($transition, primary),
    border-color var($transition, primary),
    box-shadow var($transition, primary);

  &.is-disabled,
  &[disabled] {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.55;
  }

  &--tiny {
    padding: 0.1rem 0.1rem;
  }

  /**
     Button Size Modifiers
     */
  &--small {
    padding: 0.4rem 1rem;
    height: 32px;
  }

  &--normal {
    padding: 0 1.7rem;
    min-width: 7rem;
  }

  &--minWidth {
    min-width: 5rem;
  }

  &--fullWidth {
    width: 100%;
  }
}

/**
 Outline
 */
.Button--primary:focus,
.Button--primary.is-focus,
.Button--secondary:focus,
.Button--secondary.is-focus,
.Button--remove:focus,
.Button--remove.is-focus {
  outline: 2px solid rgba(118, 172, 235, 0.7);
}

.Button--simple {
  color: #888888;
  &:hover,
  &.is-hover,
  &:focus,
  &.is-focus {
    color: #000000;
  }
}

/**
 Primary Button
 */
$primary: var($button, primary);

.Button--primary {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: var($primary, background);
  border: var($primary, border);
  /**
    Conflicting with old main.less styles.
    Remove important specifier when conflicts are removed
  */
  border-radius: 2px !important;
  box-shadow: var($button, textShadow);
  color: var($primary, color);

  &:hover,
  &.is-hover,
  &:focus,
  &.is-focus {
    background-color: var($primary, hover, background);
    border-color: var($primary, hover, background);
    box-shadow: var($button, hover, textShadow);
  }

  &:active,
  &.is-active {
    background-color: var($primary, active, background);
    border-color: var($primary, active, background);
    box-shadow: var($button, textShadow);
    outline: none;
  }

  /** Temporarily override old styles **/
  &.Button--primary {
    color: var($primary, color);
    text-decoration: none;
  }
}

/**
 Secondary Button
 */
$secondary: var($button, secondary);

.Button--secondary {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var($secondary, background);
  border: var($secondary, border);
  /**
    Conflicting with old main.less styles.
    Remove important specifier when conflicts are removed
  */
  border-radius: 2px !important;
  box-shadow: var($button, textShadow);
  color: var($secondary, color);

  &:hover,
  &.is-hover,
  &:focus,
  &.is-focus {
    border-color: var($secondary, hover, borderColor);
    box-shadow: var($button, hover, textShadow);
    color: var($secondary, hover, color);
  }

  &:active,
  &.is-active {
    background-color: var($secondary, active, background);
    border-color: var($secondary, active, borderColor);
    box-shadow: var($button, textShadow);
    color: var($secondary, active, color);
    outline: none;
  }

  &[disabled],
  &.is-disabled {
    background-color: #f9f9f9;
  }
}

/**
 Remove Button
 */
$remove: var($button, remove);

.Button--remove {
  background: var($remove, background);
  border: var($remove, border);
  border-radius: 2px;
  box-shadow: var($button, textShadow);
  color: var($remove, color);

  &:hover,
  &.is-hover,
  &:focus,
  &.is-focus {
    background: var($remove, hover, background);
    box-shadow: var($button, hover, textShadow);
  }
}

/**
 Icon Button
 */
.Button--icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: transparent;
  box-shadow: none;
  min-width: 0;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  background-color: transparent;

  svg {
    fill: currentColor;
  }
}

.Button--icon + .Button--icon {
  margin-left: 0;
}

.Button--secondary .Button-icon {
  color: var($secondary, color);
}

.Button-icon {
  display: inline-block;
  overflow: hidden;
  max-width: 3rem;
  vertical-align: text-top;
  color: #0054b0;

  .Button--normal > & {
    height: 3.4rem;
    line-height: 3.2rem;
  }

  .Button--small > & {
    height: 2.6rem;
    line-height: 2.4rem;
  }
}

.Button--icon:focus,
.Button--icon:hover {
  box-shadow: none;
  outline: none;
}

/**
 Overriding conflicts with current styles
 @TODO: Remove when possible
 */
button.Button--normal.Button--normal {
  border-radius: 2px;
}

a.Button--secondary.Button--secondary:not(:active) {
  color: var($secondary, color);
  text-decoration: none;
}

/**
 Circle buttons (for Icon Buttons)
  @TODO: Lower specificity when possible
 */
button.Button--rounded.Button--rounded {
  border-radius: 50%;
  box-shadow: 0 0 5px 0 rgba(192, 192, 192, 0.5);
}

.DataExchangeGraph {
  &-fetchFail {
    height: 326px;
    display: flex;
    text-align: center;
  }

  &-fetchFailContent {
    margin: auto;
  }

  &-tab--deactivated {
    color: #acacac !important;
    text-decoration: line-through;
  }
}

@import 'src/client/components/src/Common/variables.scss';

.Dnd-item {
  padding: 1rem 0.5rem;
  margin: -0.5rem;
}

.Dnd-source {
  opacity: 1;

  &.is-dragging {
    opacity: 0.5;
  }
}

.Dnd-target {
  position: relative;
  width: 100%;
}

.Dnd-targetLayer {
  background: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 0 0 1px var($colors, link);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  opacity: 0.6;
  top: 0;
  z-index: 1;
}

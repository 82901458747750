.Tree {
  box-sizing: border-box;
  margin-bottom: 0px !important;

  &-story {
    height: 200px !important;
  }

  .rstcustom__lineBlock {
    width: 8px !important;
  }

  .rstcustom__rowWrapper:hover {
    background-color: #fafafa;
    opacity: 1;
  }

  .rstcustom__collapseButton::after,
  .rstcustom__expandButton::after {
    content: none;
  }

  .rstcustom__rowWrapper > div {
    height: 100%;
  }

  .rstcustom__rowLabel + .rstcustom__rowToolbar {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  .rstcustom__row:hover .rstcustom__rowLabel + .rstcustom__rowToolbar {
    opacity: 1;
  }

  .rstcustom__toolbarButton {
    display: flex;
  }

  .rstcustom__toolbarButton + .rstcustom__toolbarButton {
    margin-left: 6px;
  }
}

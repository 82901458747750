@import 'src/client/components/src/Common/variables.scss';

$element: (
  background: var($colors, white),
  border: 1px solid var($colors, border),
  borderRadius: var($borderRadius, default),
  color: #221f20,
  fontSize: var($fontSize, default),
  padding: 0.7rem 1rem,
  hover: (
    borderColor: #221f20,
  ),
);

/**
    Form Elements
 */
.Element--input.Element--input {
  line-height: 1.4;
  height: var($height, default);
}

.Element--input.Element--input,
.Element--select.Element--select,
.Element--textarea.Element--textarea,
.Element--checkbox.Element--checkbox {
  background: var($element, background);
  border: var($element, border);
  border-radius: var($element, borderRadius);
  box-sizing: border-box;
  color: var($element, color);
  padding: var($element, padding);
  transition:
    border-color 0.3s ease-out,
    background 0.3s ease-out;
}

.Element--checkbox.Element--checkbox {
  padding: 0.75rem;
}

.Element.Element:hover,
.Element.Element:focus,
.Element.Element:active {
  border-color: var($element, hover, borderColor);
}

.Element--small.Element--small {
  max-width: 160px;
  min-width: 120px;
  width: 65%;
}

.Element--default.Element--default {
  max-width: 310px;
  min-width: 120px;
  width: 65%;
}

.Element--wide.Element--wide {
  max-width: 310px;
  min-width: 160px;
  width: 85%;
}

.Element--block.Element--block {
  max-width: none;
  min-width: 0;
  width: 100%;
}

.Element-textarea {
  min-height: 40px;
  vertical-align: top;
}

.Element--textarea.Element--default {
  max-width: 510px;
  min-width: 210px;
  width: 85%;
}

.Element--textarea.Element--medium {
  max-width: 310px;
  min-width: 210px;
  width: 65%;
}

.Element--textarea.Element--wide {
  max-width: 810px;
  min-width: 310px;
  width: 85%;
}

.Element.Element.is-error {
  border-color: #b7000a;
}

.Element-error {
  color: #b7000a;
  display: block;
  margin: 0.1rem 0;
}

.Element-hint {
  margin: 0.5rem 0;
}

.Element--value {
  line-height: 1.5;
  word-break: break-word;
}

.Element--label {
  cursor: pointer;
}

.SearchElement,
.DateTimeElement,
.SensitiveInputElement {
  position: relative;
}

.SearchElement-magnifyingGlassIcon,
.DateTimeElement-calendarIcon,
.SensitiveInputElement-eyeIcon {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #cccccc;
}

.DateTimeElement-calendarIcon {
  pointer-events: none;
  left: 18px;
}

.SensitiveInputElement-eyeIcon {
  cursor: pointer;
  right: -12px;
}

.SearchElement .InputElement[type='text'],
.DateTimeElement .InputElement[type='text'] {
  padding-left: 2rem;
  border-radius: 0.25rem;
}

.SearchElement-magnifyingGlassIcon,
.SensitiveInputElement-eyeIcon:hover {
  color: #888888;
}

.InputElement[type='text'],
.InputElement[type='password'],
.InputElement[type='number'],
.TextAreaElement {
  color: inherit;
  font: inherit;
  letter-spacing: inherit;

  padding: 9px;

  display: block;
  width: 100%;
  box-sizing: border-box;
  height: unset;
}

.InputElement[type='text'],
.InputElement[type='password'],
.InputElement[type='number'],
.TextAreaElement,
.SelectElement .react-select__control {
  background: #fff;
  border: 1px solid #c5c5c5;
  border-radius: 0.25rem;
  font-size: 14px;
  box-shadow: none;
  outline: none;
  transition:
    background-color 200ms ease-out,
    border-color 200ms ease-out;
}

.SelectElement .react-select__menu {
  z-index: 10;
}

.InputElement[type='text']:hover,
.InputElement[type='password']:hover,
.InputElement[type='number']:hover,
.TextAreaElement:hover,
.SelectElement:hover .react-select__control,
.CheckboxElement:not(.CheckboxElement--checked):hover .CheckboxElement-box {
  border: 1px solid #888888;
}

.SelectElement .react-select__control--menu-is-open.react-select__control--is-focused {
  border-color: #0054b0;
}

.SelectElement .react-select__clear-indicator {
  transform: translateX(14px);
}

.SelectElement .react-select__indicator-separator {
  display: none;
}

.SelectElement .react-select__value-container {
  padding: 5px 8px;
}

.InputElement[type='text']:focus,
.InputElement[type='password']:focus,
.InputElement[type='number']:focus,
.TextAreaElement:focus {
  background-color: rgba(0, 84, 176, 0.05);
  border-color: #0054b0;
}

.InputElement--hasError[type='text'],
.InputElement--hasError[type='password'],
.InputElement--hasError[type='number'],
.TextAreaElement--hasError,
.SelectElement--hasError .react-select__control,
.SelectElement--hasError:hover .react-select__control {
  background-color: rgba(162, 3, 12, 0.05);
  border-color: #a2030c;
}

.InputElement:disabled,
.TextAreaElement:disabled,
.SelectElement--disabled .react-select__control {
  border-color: #dddddd;
  color: #00000060;
  cursor: not-allowed;
}

.InputElement:disabled:hover,
.TextAreaElement:disabled:hover,
.SelectElement--disabled:hover .react-select__control {
  border-color: #dddddd;
  color: #dddddd;
}

.InputElement:disabled::placeholder,
.TextAreaElement:disabled::placeholder,
.SelectElement--disabled .react-select__placeholder,
.SearchElement--disabled .SearchElement-magnifyingGlassIcon {
  color: #dddddd;
}

.InputElement {
  display: flex;
  flex-direction: column;
  position: relative;
  &-withAction {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-error {
    position: absolute;
    bottom: -16px;
    color: #a2030c;
  }
}

.DropzoneElement {
  border: 1px dashed var($colors, link);
  border-radius: 5px;
  cursor: pointer;
  padding: 10px;
  text-align: center;
}

.DropzoneElement--hasError {
  border-color: var($colors, error);
  color: var($colors, error);
}

.CheckboxElement {
  position: relative;
  height: 24px;
  width: 24px;
}

.CheckboxElement-hiddenInput {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.CheckboxElement-box {
  display: block;
  width: 100%;
  height: 100%;
  background: #fff;
  border: 1px solid #c5c5c5;
  border-radius: 2px;
  transition:
    background-color 200ms ease-out,
    border-color 200ms ease-out;
}

.CheckboxElement--checked .CheckboxElement-box {
  background: #0054b0;
  border-color: #0054b0;
}

.CheckboxElement--disabled {
  opacity: 0.5;
}

.CheckboxElement--disabled:not(.CheckboxElement--checked):hover .CheckboxElement-box {
  border-color: #c5c5c5;
}

.CheckboxElement--disabled .CheckboxElement-hiddenInput {
  pointer-events: none;
}

.CheckboxElement--disabled .CheckboxElement-box {
  cursor: not-allowed;
}

.CheckboxElement-tick {
  stroke: #fff;
  stroke-width: 4;
  fill: none;
}

.FieldWrapper-label {
  margin: 0 0 8px 0;
  cursor: pointer;
}

.FieldWrapper-errorOrHintContainer {
  font-size: 12px;
  margin-top: 2px;
  color: transparent;
}

.FieldWrapper-errorOrHintContainer--error {
  color: #a2030c;
}

.FieldWrapper-errorOrHintContainer--hint {
  color: #888888;
}

.FieldWrapper--horizontal {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.FieldWrapper--horizontal .FieldWrapper-fieldWrap {
  flex: 1;
  position: relative;
}

.FieldWrapper--horizontal .FieldWrapper-label {
  margin: 0 16px 0 0;
}

.FieldWrapper--horizontal .FieldWrapper-errorOrHintContainer {
  position: absolute;
  width: 100%;
}

.FieldWrapper--inline {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.FieldWrapper--inline > label {
  margin-left: 10px;
  margin-top: 2px;
}

.FieldWrapper--inline .FieldWrapper-errorOrHintContainer {
  position: absolute;
}

.SelectGroup {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #0054b0;
  padding-bottom: 16px;
  border-bottom: 1px solid #00000040;
  margin-bottom: 16px;

  &-badge {
    background-color: #ebecf0;
    border-radius: 2em;
    color: #172b4d;
    display: inline-block;
    font-size: 12px;
    font-weight: normal;
    line-height: 1;
    min-width: 1;
    padding: 0.1em 0.5em;
    text-align: center;
  }
}

.SelectElement-countlabel {
  background-color: #ebecf0;
  border-radius: 2em;
  color: #172b4d;
  font-size: 12px;
  font-weight: normal;
  margin-left: auto;
  order: 99;
  padding: 0.1em 0.5em;
  text-align: center;
}

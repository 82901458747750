@import 'src/less/variables.less';

.template-form-step1 {
    .template-container {
        width: 180px;
        height: 145px;
        margin: 10px;
        text-align: center;
        border: 1px solid #ccc;

        &:hover,
        &.is-active {
            border: 1px solid @r42-themeColor;
        }

        img {
            max-height: 120px;
        }

        .template-image-container {
            height: 120px;
        }
    }

    .template-name {
        line-height: 25px;
        font-size: 12px;
        border-top: 1px solid #ccc;
        width: 100%;
    }

    .template-container-special {
        .template-name {
            background-color: @r42-primaryColor;
            color: #fff;
        }
    }

    .template-search {
        width: 100%;
        padding: 20px 10px 20px 10px;

        .template-search-group {
            margin-left: 20px;

            ul {
                display: inline;
            }
        }

        .template-search-private {
            display: inline-block;
        }
    }

    .template-filter {
        vertical-align: middle;
    }

    .template-overview {
        width: 79%;
        float: left;

        .template-container {
            float: left;
        }
    }

    .template-sidebar {
        width: 19%;
        padding-left: 1%;
        border-left: 1px solid #ccc;
        float: right;

        .box {
            .box-title {
                padding: 0px;
            }
        }

        .template-container, .template-container:hover {
            border: none;
            height: 140px;
        }

        .template-description {
            margin-bottom: 20px;
        }

        .template-variant {
            margin-left: 5px;
            margin-bottom: 10px;
            margin-top: 25px;

            .btn {
                margin-left: 15px;
                width: 160px;
            }
        }

        .template-variant-private {
            h5 {
                background-color: @r42-warningColor;
                padding: 5px;
            }
        }

        h4 {
            font-weight: bold;
        }
    }

    .r42-ui-search-box {
        width: auto;
    }
}

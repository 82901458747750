@import 'src/less/variables.less';

.tagHeader{

	position: relative;

	.templateLogo {
		height: 60px;
		margin-top: 0px;
		float: left;

		.image-container {
			max-width: 90px;
			max-height: 90px;
			overflow: hidden;
		}
	}
	.tagHeaderInfo {
		width: 100%;

		.tagName {
			font-size: 24px;
			font-weight: 600;
			font-style: normal;
		}

		.subtitle, .tagDetails {
			margin-left: 80px;
		}

		.subtitle {

			margin-bottom:30px;
			margin-top:10px;

			.control-label {
				width: auto;
				margin-right: 10px;
			}

			.controls {
				margin-left: 10px;
				padding-top: 5px;

				div {
					margin-top: 0px;
					margin-bottom: 0px;
					padding-top: 0px;
					padding-bottom: 0px;
				}
			}

			.priority input {
				width: 40px;
			}
		}
	}
}

.hasErrors {
	color: red;
}

.r42-ui #main {

	.label-errorstatistics {
		color: @r42-white;
		background-color: rgb(179, 5, 5);
		font-weight: 400;
		font-size: 11px;
		padding: 0 3px;
		margin: 0 4px;
	}
}

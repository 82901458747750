@import 'src/client/components/src/Common/variables.scss';

.Aligner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Aligner--baseline {
  align-items: baseline;
  justify-content: center;
}

.Aligner--spaceBetween {
  justify-content: space-between;
}

.Aligner-item {
  margin-left: var($space, default);
  margin-right: var($space, default);
}

.Aligner-item--top {
  align-self: flex-start;
}

.Aligner-item--bottom {
  align-self: flex-end;
}

.Identities-tipBox {
  padding: 10px 15px;
  align-items: center;
}

.Identities-tipBox > .Notification--icon {
  position: static;
}

.Identities-addPartnerNumberRow {
  padding-right: 7px;
  padding-bottom: 0;
  padding-top: 0;
}

.Identities-partnerNumberForm {
  display: flex;
  align-items: center;
  width: 100%;
}

.Identities-partnerNumberForm > .Identities-partnerNumberInput {
  min-width: auto;
  max-width: none;
  width: 420px;
}

.Identities-partnerNumberForm > .Identities-partnerNumberInput:focus:invalid {
  padding: 7px 10px;
}

.Identities-validationErrorMessage {
  flex: 1;
  margin-left: 20px;
}

.Identities-partnerNumberForm .EditableRowControls {
  position: static;
  padding-right: 0 !important;
}

.Identities-partnerNumberForm .EditableRowControls > .Button {
  height: 30px;
  width: 30px;
  padding: 0;
}

.Identities-partnerNumberForm .EditableRowControls > .Button:disabled {
  background: transparent;
  opacity: 0.35;
}

.Identities-partnerNumberForm .EditableRowControls > .Button > .Button-icon {
  line-height: normal;
  height: auto;
}

.t-identitiesConfigurationTable {
  margin-bottom: 0 !important;
}

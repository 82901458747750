.AudiencesList {
  &-RowLabel {
    width: 100%;
    .Table-row {
      padding: 8px 0;
      border-bottom: 1px solid #eaeaea !important;
    }
    .Table-row:hover {
      background: #fafafa !important;
    }
  }

  &-RowStatus {
    text-align: right;
    font-style: italic;
    animation: blinker 3s linear infinite;
    color: #ff7300;
  }

  @keyframes blinker {
    50% {
      opacity: 0.3;
    }
  }
}

.HistoricProcessingButtonGroup {
  margin-top: 48px;
}

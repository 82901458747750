@import 'src/client/components/src/Common/variables.scss';

.Table-actions {
  transition: opacity var($transition, primary);
  text-align: right;
  white-space: nowrap;
  position: relative;

  &--active {
    opacity: 1;
  }

  > .Button,
  > .Tooltip {
    margin-left: 0.2rem;
    margin-right: 0.2rem;
  }
}

div.Table-actions {
  margin-left: auto;
  overflow: visible;
}
.Table-cell.Table-actions {
  text-align: right;
}

.Table--shrinkContent {
  width: -moz-min-content;
  width: -webkit-min-content;
  width: min-content;
}

.Table-cell--min65 {
  min-width: 65px;
}

@media only screen and (min-width: 1500px) {
  .Table--restrictedOnXlScreen {
    max-width: 1500px;
  }
}

.OverflowXScrollContainer {
  overflow-x: auto;
}

.OverflowYHiddenContainer {
  overflow-y: hidden;
}

.Table--addlBottomSpacing {
  margin-bottom: 30px;
}

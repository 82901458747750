@import 'src/client/components/src/Common/variables.scss';

.Form {
  margin: 1rem 0;

  &-field {
    align-items: center;
    display: flex;
    margin: 1rem 0 -1.5rem;
    padding: 0.1px 0;

    &.is-selected {
      box-shadow: 0 0 8px 0 rgba(155, 155, 155, 0.5);
      margin-bottom: -11px;
      left: -1rem;
      padding-bottom: 5.1px;
      padding-left: 1rem;
      padding-top: 6.1px;
      position: relative;
      top: -6px;
    }
  }

  &-field--detail {
    margin: 0.5rem 0;
  }

  &-element,
  &-detail {
    width: 84.9%;
  }

  &-element {
    padding: 1rem 0 0 0;
  }

  &-row {
    margin-top: 48px;
    display: flex;
    justify-content: flex-end;
  }

  &-actions {
    display: inline-block;
    margin-left: 15%;
  }
}

.Form-label.Form-label {
  align-self: flex-start;
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  line-height: 1.5;
  padding: 1.7rem 2rem 1rem 0;
  text-align: right;
  vertical-align: top;
  min-width: 15rem;
  width: 240px;
}

.Form-error {
  min-height: 2.4rem;
  overflow: visible;
  padding: 0.1px 0;
}

.Form-label--detail.Form-label--detail {
  padding-top: 1rem;
  font-weight: 500;
}

.Form-field--clean.Form-field--clean {
  display: block;
}

.Form-field--multiselect {
  margin-top: 10px;
  margin-bottom: 12px;
}

.Form-label--clean.Form-label--clean {
  display: block;
  text-align: left;
}

label.Form-label {
  cursor: pointer;
}

.Form-cancel.Form-cancel {
  font-size: 1.4rem;
  line-height: 1.2;
  margin: 0 1.5rem 0 0.5rem;
  padding: 0;
  vertical-align: middle;
}

//temporary solution to battle nested selectors
//should be refactored once we get rid of legacy css
.Form-hint.Form-hint {
  color: #999;
  font-size: var($fontSize, s);
  max-width: 550px;
  white-space: pre-line;
}

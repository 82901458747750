@import './variables.scss';

$actionButtonsHeight: 66px;

#reactRoot .ReactModalPortal {
  flex: none;
}

body .ReactModalPortal .ReactModal__Overlay {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Modal-content {
  display: flex;
  min-width: 400px;
  max-height: 99vh;
  flex-direction: column;
  position: relative;
  background-clip: padding-box;
  outline: 0;
  overflow-y: auto;
}

.Modal-body {
  overflow-x: hidden;
  overflow-y: auto;
}

.Modal-body--noScroll {
  overflow: unset;
}

.DeleteModal-content {
  max-width: 500px;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto Regular'), local('Roboto-Regular'), url('/frontend/font/Roboto-Regular.woff2') format('woff2');
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: local('Roboto Medium'), local('Roboto-Medium'), url('/frontend/font/Roboto-Medium.woff2') format('woff2');
}
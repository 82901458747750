.NotificationSpoiler-Header {
  display: inline-block;
  width: 100%;
}

.NotificationSpoiler-Header-SwitchButton {
  transition: all 0.5s;
  float: left;
  margin-right: 5px;
}

.NotificationSpoiler-Header-SwitchButton svg {
  transition: all 0.5s;
  width: 10px;
}

.NotificationSpoiler-Header-SwitchButton--rotated svg {
  transform: rotate(90deg);
}

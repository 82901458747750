.variant-select {
	padding: 20px;
	margin: 30px auto;
	font-size: 25px;
	text-align: left;
	border: 1px solid #ccc;
}
.variant-select input[type="radio"] {
	margin-top: -3px;
	margin-right: 20px;
}

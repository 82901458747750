@import 'src/client/components/src/Common/variables.scss';

.Dropdown {
  margin: 4px;
  right: 0px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-box-shadow: 0 0 5px #999;
  -webkit-box-shadow: 0 0 5px #999;
  box-shadow: 0 0 5px #999;
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 5;
  background-color: var($colors, white);

  &--top {
    bottom: 40px;
  }

  &-link {
    min-width: 140px;
    padding: 4px 10px 4px 14px;
    display: block;
    text-decoration: none !important;
    line-height: 32px;
    cursor: pointer;
    text-transform: capitalize;
    text-align: left;
    color: #000000 !important;

    &:hover {
      background-color: #eeeeee;
    }

    &--disabled {
      color: currentColor;
      cursor: not-allowed;
      opacity: 0.5;
      text-decoration: none;
      pointer-events: none;
    }

    &--delete {
      margin-top: 10px;
      padding-top: 4px;
      border-top: 1px solid #cccccc;
      color: #ff0000 !important;
    }
  }
}

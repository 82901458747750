/* Toast style override */
#toast-container > :hover {
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

#toast-container > div {
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: transparent !important;
    width: 380px;
    opacity: 1;
    border-radius: 0.5rem;
    padding: 0px !important;
}

.success-toast-message  .progress:before {
    background-color: rgb(22 163 74);
}

.info-toast-message  .progress:before {
    background-color: rgb(37 99 235);
}

.error-toast-message  .progress:before {
    background-color: rgb(220 38 38);
}

.warning-toast-message  .progress:before {
    background-color: rgb(234 88 12);
}

#custom-toast-container .progress {
    background-color: transparent;
    position: absolute;
    bottom: -20px;
    left: 0px;
    width: 100%;
    opacity: 0.7;
    height: 0.4rem;
    border-radius: 0.5rem;
}

#custom-toast-container .progress:before {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
}
.progress:before {
    animation: progress 5s linear forwards;
}

@keyframes progress {
    100% {
    right: 100%;
    }
}

.toast-close-button{
    z-index: 99999;
    right: 0.5em;
    top: 0.5em;
    font-weight: bold;
    font-size: 20px;
    border-radius: 5px;
    width: 25px;
    height: 28px;
    color:rgb(55 65 81) ;
    background:rgb(229 231 235) !important;
     &:hover, &:focus {
        opacity: 1;
      }
}

.toast-top-right{
    top: 30px;
}

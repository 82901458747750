.editable-checkbox {
	.icheckbox_square.inArea-true {
		border: 1px dashed black;
	}
	
	.icheckbox_square {
		border: 1px dashed white;
	}
}

.editable-checkbox.editable-disabled {
	input {
		cursor: inherit;
	}	
}

.form-horizontal .controls .editable-checkbox {
	padding-top: 5px;
}

/*
 Display Utilities
 Applies to medium size screens only
 */

/*
  Completely remove from the flow but leave available to screen readers.
 */
@media (min-width: 640px) and (max-width: 1200px) {
  .u-md-hiddenVisually {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    height: 1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
  }
}

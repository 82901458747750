.common {
    background-size: contain;
    display: inline-block;
    height: 15px;
    width: 15px;

    &.disabled {
        filter: grayscale(100%) brightness(-5)
    }

    &.r42-icon-xxlarge {
        height: 42px;
        width: 42px;
    }
}
.r42-journey {
    background: url('../svg/journey/ICONjourneys.svg') center center no-repeat;
    .common();
}

.r42-journey-normal {
    background: url('../svg/journey/ICONjourneysNormal.svg') center center no-repeat;
    .common();
}
@import 'src/client/components/src/Common/variables.scss';

.Pagination {
  margin: 1rem 0;
  text-align: center;

  &.is-disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.65;
  }
}

// Bump specificity to override old styles
// TODO: Remove when possible
.Pagination-step.Pagination-step {
  background: transparent;
  border: none;
  border-radius: 3rem;
  color: #0054b0;
  cursor: pointer;
  height: 3rem;
  margin: 0.25rem;
  width: 3rem;

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    box-shadow: 0 0 7px 1px rgba(118, 172, 235, 0.7);
  }

  &.is-active {
    background: #0054b0;
    color: var($colors, white);
  }
}

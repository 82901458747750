.Table-sort {
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }

  &.is-active {
    color: #000;
    font-weight: 500;
  }
}

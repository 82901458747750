@import 'src/client/components/src/Common/variables.scss';

.MappingListHeader {
  display: flex;
  padding: 12px 0;
  border-bottom: 1px solid #eaeaea;
}

.MappingListHeader-deleteButtonSpace {
  width: 32px;
}

.Mapping-destinationSelect,
.Mapping-sourceSelect {
  width: 100%;
}

.Event-mappingsContainer {
  margin-top: 16px;
}

.Mapping-configureTransformationsButton {
  border: none;
  font-size: 14px !important;
  color: #333333;
  box-shadow: none;
  padding: 0 8px;
}

.Mapping-configureTransformationsButton--invalid {
  color: var($colors, error);
}

.Mapping-configureTransformationsButton:hover {
  color: var($colors, link);
  box-shadow: none;
}

.Mapping-configureTransformationsButtonIcon {
  margin-left: 8px;
}

.ModalWindow-background {
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.2);
}

.ModalWindow-window {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  margin-top: 48px;
  border-radius: 2px;
  box-shadow: 0 2px 6px 0 rgba(194, 194, 194, 0.5);
  background-color: #ffffff;
  z-index: 3;
  position: relative;
}

.ModalWindow-content {
  padding: 0 24px;
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
}

.ValueTransformation {
  display: flex;
  align-items: center;
  padding: 12px 0;
}

.ValueTransformation + .ValueTransformation {
  border-top: 1px solid #eaeaea;
}

.ValueTransformation-cell {
  display: flex;
  align-items: center;
}

.ValueTransformation-cell + .ValueTransformation-cell {
  margin-left: 16px;
}

.ValueTransformation-cell--CalendarTrigger {
  width: 36px;
}

.ValueTransformation-selectCell {
  width: 190px;
}

.ValueTransformation-transformationTypeSelect {
  width: 100%;
}

.ValueTransformation-orderCell {
  width: 40px;
}

.ValueTransformation-orderValueCell {
  align-self: center;
  padding-right: 8px;
  box-sizing: border-box;
  text-align: center;
}

.ValueTransformationsHeader .ValueTransformation-transformationTypeCell {
  margin-left: 6px;
}

.ValueTransformation-transformationTypeCell {
  flex: 1;
}

.ValueTransformation-ValueCell {
  flex: 2;
}

.ValueTransformation-reasonTooltip {
  display: flex !important;
  align-self: center;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}

.ValueTransformation-reasonTooltip .Tooltip {
  white-space: pre-wrap;
  width: 340px;
  top: -8px;
  /* Fixes a bug with drag preview height in react-dnd-html5-backend. Only occurs in Chrome-based browsers. */
  display: none;
}

.ValueTransformation-reasonTooltip:hover .Tooltip {
  display: block;
}

.ValueTransformation-reasonTooltip .Icon {
  font-size: 0;
}

.ValueTransformation-reasonTooltip .Tooltip--bottomRightPlacement:after {
  top: 11px;
  right: -8px;
  transform: rotate(90deg);
}

.ValueTransformationsHeader {
  display: flex;
  padding: 12px 0;
}

.ValueTransformationsHeader-item {
  font-weight: 500;
}

.ValueTransformationsModal-saveButton {
  position: absolute;
  right: 16px;
  bottom: 16px;
}

.ValueMapping {
  display: flex;
}

.ValueMapping + .ValueMapping {
  margin-top: 24px;
}

.transformer-EventConnectorTransformationsPage-addEventButtonContainer {
  margin-top: 24px;
}

.HashingSelect {
  width: 100%;
}

.ValueTransformationsModal-transformationsContainer {
  overflow: auto;
  flex: 1;
}

.r42-ui {
	.editable-container {
	
		.editable-container-default {
			padding: 0px;
			box-shadow: 0 0 0 1px white;
			display: inline-block;
		}
		
		.noValue {
			color: #bbb;
		}
	}
	
	table.table {
		td .editable-default {
			a.btn, input, form, .control-group {
				margin-bottom: 0px;
			}
			
			.control-group {
				padding: 0;
				margin-left: 0;
			}
			
			.control-group.error {
				.error-block {
					color: #b94a48;
				}
			}
		}
	}
}

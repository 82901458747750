.AudienceEdit-rulePickers {
  display: flex;
  justify-content: left;
  width: 350px;
  flex-direction: row;
  padding: 20px 0px;
}

.AudienceEdit-rulePickers > div {
  margin-right: 15px;
}

.ModalBG {
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.2);
}

.picker {
  margin-left: auto;
  margin-right: auto;
  width: 840px;
  height: 82%;
  margin-top: 48px;
  box-shadow: 0 2px 6px 0 rgba(194, 194, 194, 0.5);
  z-index: 3;
}

.picker-content {
  padding: 0 24px;
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
}

@use 'sass:math';
@import 'src/client/components/src/Common/variables.scss';
@import './variables.scss';

.Media {
  display: flex;
  align-items: flex-start;

  &-object {
    margin-right: var($space, default);
  }

  &-content {
    flex: 1;
  }
}

/*
 Form Icon Modifier
 */
.Media--formIcon {
  position: relative;
}

.Media-icon {
  font-size: var($media, icon, size);
  margin-left: var($space, s);
  margin-top: -#{math.div(var($media, icon, size), 1.8)};
  position: absolute;
  text-align: center;
  top: 50%;
  width: 2rem;
  z-index: 3;
}

.Media-icon--left {
  left: 0;
  line-height: var($media, icon, size);
}

.Media-icon--right {
  right: 0;
  margin-right: var($space, s);
}

.Media-input--left.Media-input--left {
  padding-left: var($space, xxl);
}

.Media-input--right.Media-input--right {
  padding-right: var($space, xxl);
}

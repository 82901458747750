.PermissionFields + .PermissionFields {
  margin-top: 24px;
}

.PermissionFields-groupLabel {
  font-size: 16px;
  margin-bottom: 12px;
}

.PermissionFields .FieldWrapper {
  margin-bottom: 0;
}

.PermissionsForm-apiPermissionsField {
  margin-bottom: 0;
  display: flex;
  align-items: center;
}

.PermissionsForm-apiPermissionsField > label {
  margin-top: 0;
  margin-bottom: 0;
}

.PermissionsForm-apiPermissionsField + .PermissionFields {
  margin-top: 24px;
}

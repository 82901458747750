/*
 Text Alignment Utils
 */

.u-alignLeft {
  text-align: left !important;
}

.u-alignRight {
  text-align: right !important;
  justify-content: flex-end;
}

.u-alignCenter {
  text-align: center !important;
}

.u-alignCenterFlex {
  align-self: center;
}

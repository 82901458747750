@import 'src/client/components/src/Common/variables.scss';

.JourneyRightPanel {
  &-addGoal {
    border: 1px solid #0054b0;
    height: 36px;
    width: 100%;

    &--hasError {
      border: 1px dashed var($colors, error);
    }
  }

  &-addGoal .Button-icon {
    margin-right: 4px;
  }

  &-addGoalTooltip {
    width: 240px;
  }

  &-addGoalTooltipArea {
    display: block !important;
  }
}

.JourneyItem {
  &--exited {
    &:before {
      background: #888888;
    }

    &-footer {
      color: #000000;
      display: flex;
      flex-direction: column;
      border-radius: 10px;
      background-color: white;
      margin-bottom: 4px;
      align-items: baseline;
      padding: 4px 8px;
    }

    &-variantName {
      display: inline-block;
      max-width: 120px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #000000;
      font-size: 12px;
    }

    &-footerStats {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }

    &-type {
      display: inline-block;
      max-width: 120px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #888888;
    }
  }

  &-hoverRegionUnderCard {
    position: absolute;
    width: 100%;
    left: 0;
  }

  .JourneyItem-buttonPanel {
    position: absolute;
    overflow: visible;
    z-index: 1;
    height: 50px;
    width: 216px;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 4px;
  }

  &:hover {
    .JourneyItem-buttonPanel,
    .JourneyItem-hoverRegionUnderCard {
      visibility: visible;
    }
  }

  &-content {
    padding: 14px;
    background: white;
    padding-bottom: 30px;
  }

  &-name {
    font-weight: 500;
    margin-left: 16px;
  }

  &-icon {
    img {
      width: 16px;
      height: auto;
    }
  }

  &-contentHeader {
    display: flex;
    align-items: baseline;
  }

  &-footer {
    background-color: #eaeaea;
    padding: 4px 8px;
  }
}

.JourneyItem-buttonPanel,
.JourneyItem-hoverRegionUnderCard {
  visibility: hidden;
}

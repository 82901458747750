@font-face {
  font-family: 'Source Sans Pro';
  src: url('../font/source-sans-pro.eot?49736682');
  src: url('../font/source-sans-pro.eot?49736682#iefix') format('embedded-opentype'),
       url('../font/source-sans-pro.woff2?49736682') format('woff2'),
       url('../font/source-sans-pro.woff?49736682') format('woff'),
       url('../font/source-sans-pro.ttf?49736682') format('truetype'),
       url('../font/source-sans-pro.svg?49736682#SourceSansPro') format('svg');
  font-weight: 900;
  font-style: normal;
}

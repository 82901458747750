@import 'src/less/variables.less';

.styled-checkbox-false {
    *[data-ng-click] {
        cursor: inherit;
    }
}

.checkbox {
    padding-left: 0;
}

.style-checkbox {
    width: 25px;
    padding-left: 0;
    position: relative;
    display: inline-block;
    margin-bottom: 13px;
    vertical-align: top;
    label {
        position: absolute;
        top: 0px;
        left: 0;
        width: 20px;
        height: 20px;
        cursor: pointer;
        background: #FFF;
        border: 2px solid #DBDBDB;
        padding-left: 0;
        &:hover {
            border-color: #A6A6A6 ;
        }
    }
    label:after {
        opacity: 0.2;
        content: '';
        position: absolute;
        width: 11px;
        height: 5px;
        background: transparent;
        top: 7px;
        left: 5px;
        border: 2.1px solid #4D4D4D;
        border-top: none;
        border-right: none;

        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }

    label:hover::after {
        opacity: 0.5;
    }
    input[type=checkbox]:checked + label {
        background: @r42-themeColor;
        border-color: @r42-themeColor;

        &:hover {
            border-color: @r42-themeColor ;
        }
    }
    input[type=checkbox]:checked + label:after {
        border-color: #FFFFFF;
        opacity: 1;
    }
    input[type=checkbox] {
        visibility: hidden;
    }
}

.modal-close {
    float: right;
    margin-right: 10px;
    margin-top: -30px;
}

.r42-ui .modal {
    .modal-dialog {
        margin-top: 5%;
        .form-horizontal .checkbox {
            padding-top: 0;
        }
    }

    .cj-close-button {
        padding-right: 0;
        .close {
            border: 1px solid @r42-mediumgrey-journey;
            margin-top: 0;
            color: @r42-mediumgrey-journey;
            padding: 10px 10px;
            font-size: 3em;
            opacity: 1;
            font-weight: 500;
            opacity: 1;
            &:hover {
                color: @r42-blue-journey;
            }
        }
    }

    .modal-header h3 {
        color: #2f2f2f;
        margin: 0;
        font-size: 22px;
        small {
            font-size: 14px;
            color: #999;
        }
    }

    .modal-footer {
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        border-top: none;
    }

    &.fade {
        .modal-dialog {
            /*CSS transitions*/
            -o-transition-property: none;
            -moz-transition-property: none;
            -ms-transition-property: none;
            -webkit-transition-property: none;
            transition-property: none;
            /*CSS transforms*/
            -o-transform: none;
            -moz-transform: none;
            -ms-transform: none;
            -webkit-transform: none;
            transform: none;
            /*CSS animations*/
            -webkit-animation: none;
            -moz-animation: none;
            -o-animation: none;
            -ms-animation: none;
            animation: none;
            animation: none;
        }
        &.modal-fullscreen {
            height: 100%;
            position: fixed;
            top: 0;
            left: 0;
            overflow-x: hidden;
            overflow-y: auto;
            .modal-dialog {
                background-color: white;
                width: 100%;
                height: 100%;
                overflow-y: auto;
                overflow-x: hidden;
                position: relative;
                text-align: center;
                margin-top: 0px;
                margin-bottom: 0px;

                .modal-body {
                    padding: 10px;
                    .box-content{
                        height: 100%;
                    }
                }
                .modal-content {
                    height: 100%;
                    border: 0;
                    -webkit-box-shadow: none;
                    box-shadow: none;
                    border: none;
                    box-shadow: none;
                }
            }
        }

        &.modal-huge .modal-dialog {
            width: 90%;
        }

        &.modal-large .modal-dialog {
            width: 80%;
        }

        &.modal-medium .modal-dialog {
            width: 60%;
        }

        &.modal-small .modal-dialog {
            width: 40%;
        }

        .modal-body {
            overflow-x: hidden;
            overflow-y: auto;
            min-height: 100px;
            max-height: inherit;

            .modal-body-outer {
                padding: 10px;
            }
            .modal-body-inner {
                margin: auto;
                width: 90%;
                height: 90%;
            }
        }
    }
}

.modal-huge .modal-header, .modal-huge .modal-footer {
  height: 5%;
}

.modal-huge > form {
  width: 100%;
  height: 100%;
}

.r42-ui .modal-medium {

  .form-horizontal {
	.control-group {
	  margin-bottom: 5px;
	}

	.box .box-content {
	  padding-bottom: 5px;
	}
  }

}
.modal-content {
    border-radius: 0;
}

//Avoid issues with the overflow in internal dropdowns
.r42-ui .modal.fade .modal-body-visible {
    overflow: visible;
}

//Modal with grey header, use in Customer Journey
.r42-ui .modal-grey-header {
    .journey-header-placeholder {
        text-align: left;
        display: block;
        overflow: hidden;
        color: @r42-grey-journey;
        background: @r42-lightgrey-journey;
        border-bottom: 1px solid @r42-mediumgrey-journey;
        padding: 13px 10px 13px 25px;


        .logo-container .logo {
            width: 45%;
        }

        .header-text {
            padding-left: 20px;
            display: inline-block;
            vertical-align: top;
            margin-top: 15px;
            text-transform: uppercase;
            font-size: 0.9em;
            font-weight: bold;
        }

        .close-button {
            padding-right: 0;
            .close {
                border: 1px solid @r42-mediumgrey-journey;
                margin-top: 0;
                color: @r42-mediumgrey-journey;
                padding: 10px 10px;
                font-size: 3em;
                opacity: 1;
                font-weight: 500;
                opacity: 1;
                &:hover {
                    color: @r42-blue-journey;
                }
            }

            .save {
                background-color: transparent;
                border: 1px solid @r42-mediumgrey-journey;
                margin-top: 0;
                color: @r42-green-journey;
                padding: 7px 3px;
                font-size: 2em;
                opacity: 1;
                float: right;
                opacity: 1;
                &:hover {
                    color: @r42-blue-journey;
                }
            }
        }

        .no-padding {
            padding: 0px;
        }
    }
}


//Media Query FullScreen

/* .modal-fullscreen size: we use Bootstrap media query breakpoints */

@media (min-width: 768px) {
    .modal-fullscreen .modal-dialog {
        width: 750px;
    }
}
@media (min-width: 992px) {
    .modal-fullscreen .modal-dialog {
        width: 970px;
    }
}
@media (min-width: 1200px) {
    .modal-fullscreen .modal-dialog {
        width: 1170px;
    }
}

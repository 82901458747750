.colorpicker.dropdown  {
    .dropdown-menu {
        border: 1px solid rgba(0,0,0,0.15);
    }
}

.sv-colorpicker-palette {
    display:inline-block;
    height:1.5em;
    width:1.5em;
    vertical-align: middle;
    margin-right: 6px;
}

//temporary until moving all to r42-font
@import 'font-awesome.css.less';

// r42 font (see fontello in downloaded)
@import 'r42-font.css.less';
@import 'source-sans-pro-font.css.less';
@import 'roboto-font.css.less';

// r42 icons
@import 'r42-icons.less';

[class^='r42-']:before,
[class*=' r42-']:before {
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale;
    text-transform: none;
    line-height: 1;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    speak: none;
}

.r42-ui .btn {
    [class^='r42-']:before,
    [class*=' r42-']:before {
        font-size: 14px;
    }
}

.r42-ui .r42-icon-small {
    &[class^='r42-']:before,
    &[class*=' r42-']:before {
        font-size: 12px;
    }
}

.r42-ui .r42-icon-large {
    &[class^='r42-']:before,
    &[class*=' r42-']:before {
        font-size: 17px;
    }
}

.r42-ui .r42-icon-xlarge {
    &[class^='r42-']:before,
    &[class*=' r42-']:before {
        font-size: 19px;
    }
}

.r42-ui .r42-icon-xxlarge {
    &[class^='r42-']:before,
    &[class*=' r42-']:before {
        /* ;) */
        font-size: 42px;
    }
}

.r42-disabled-until-hover {
    color: @r42-lightgrey;
    &:hover {
        color: #000;
    }
}

.r42-slim-icon:before {
    margin-right: 0;
    margin-left: 0;
}

@import 'font.less';
@import 'loading-bar.less';
/**
 * Main variables
 */
@import 'variables.less';
@import 'login.less';
@import 'offline-js.less';
@import 'dialog.less';
@import 'mixins.less';

html, body {
  height: 100%;
  width: 100%;
  min-height: 100%;
  margin: 0;
}

body[data-ng-click] {
  cursor: inherit;
}

b, strong {
  font-weight: 500;
}
/** r42 theme overides */
@import 'theme-overides.less';

/** scrollbar */
@import 'scrollbar.less';

/** Dynatree overrides */
@import 'tree.less';

/** CSS3 Spinners @todo: marius review and unify */
@import 'spinner.less';
@import 'icon-spin.less';

/** r42 box model styles */
@import 'box.less';

/**  helpers */
@import 'helpers.less';

/**
 *   Main blocks
 */

.container-fluid-none-padding {
  padding-left: 0;
  padding-right: 0;
}

#left {

  .ui-resizable-e {
    position: absolute;
    height: 100%;
    width: 5px;
    right: 0px;
    top: 0px;
    cursor: e-resize;
  }
}

.fill {
  min-height: 100%;
  height: 100%;
}

#content {
  flex-direction: column;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
}

#glassOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
  display: none;
  opacity: 0.4;
  z-index: 1999;
  background-color: black;
}

.pane {
  display: block;
  position: absolute;
  overflow: auto;
  top: 0;
  bottom: 0;
  right: 0;
}

.sidebar.collapsible .pane.left {
  border-right: 1px solid #d5d5d5;
}

.main_panes.flush_top > .pane {
  top: 65px;
}

.main_panes.flush_top > .pane-min {
  top: 40px;
}

.sidebar .pane.right {
  left: 330px;
}

.main_panes.noSidebar > .pane.right.section {
  left: 0 !important;
}

.sidebar-with-gutter {
  padding-left: 30px;
}

#main.dark-page {
  background-color: #fafafa;
}

#navigation {
  .site-selection {
    .site-name {
      float: left;
      width: 180px;
      overflow: hidden;
    }
    .site-number {
      float: right;
    }
  }
}

#subnavigation {
  z-index: 100;
}

.navbar {
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
  padding: 0;
}

#sidebar-navigation {
  background-color: #686868;
  display: block;
  position: fixed;
  overflow: none;
  top: 0;
  bottom: 0;
  left: -1px;
  width: 30px;
  z-index: 9;
  padding-top: 90px;

  a {
    cursor: pointer;
    display: block;
    height: 40px;
    width: 100%;
    margin: 3px 0 3px 3px;
    outline: none;
    color: #fff;
    text-decoration: none;

    &:hover + span {
      display: inline;
    }
  }

  span {
    display: none;
    color: #fff;
    position: absolute;
    font-size: 14px;
    background-color: #686868;
    padding: 2px;
    padding-left: 7px;
    width: 80px;
    text-align: center;
    margin-top: -42px;
    margin-left: 30px;
  }
}

.meta {
  display: block;
  margin: 0;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #666;

}

.meta-item {
  margin-right: 15px;

  &.indented {
    padding-left: 30px;
  }
}

/**
 *   Main components
 */

.r42-ui {

  .btn {
    margin-right: 3px;
    &:hover {
      background-color: @r42-lightgrey-journey;
    }

    &.btn-red {
      &:hover {
        background-color: @r42-lightred;
      }
    }

    &.btn-primary {
      background-color: @r42-primary-button-color;
      color: #fff;
      &:hover {
        background-color: @r42-primary-button-hover;
      }
    }

    &.btn-default, &.btn-secondary {
      background-color: @r42-mediumgrey-journey;
      color: @r42-darkgrey-journey;
      &:hover {
        background-color: @r42-grey-journey;
      }
    }

    &.btn-disabled {
      background-color: @r42-navgrey;
      color: #ccc;
    }

    &.btn-success-green {
      background: @r42-themeColor-button-background;
      color: white;
      font-size: 13px;
      font-weight: 500;
      &:hover {
        background: @r42-themeColor-clearness;
      }
    }

    &.btn-info {
      background-color: @r42-lightgrey-journey;
    }

    &.btn-single {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .click-disabled {
    pointer-events: none;
  }

  .input-append {
    .btn {
      margin-right: 8px;
    }
  }

  .btn-group {
    .input-append {
      .btn {
        margin-right: 0px;
      }
    }
  }

  .btn-float {
    position: absolute;
    margin: 0 0 0 251px;
    padding: 0;
    background: #fff;
    color: #000;
    border-left: 1px solid @r42-panel-border;
    border-bottom: 1px solid @r42-panel-border;

    i {
      font-size: 18px;
    }
  }
}

.r42-ui .btn-switch {

  .btn-switch-container {
    background: @r42-mediumgrey-journey;
    color: @r42-grey-journey;
    display: inline-block;
    padding: 5px;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    text-transform: uppercase;
    margin-bottom: 2px;
    margin-right: 25px;
  }

  .btn {
    padding: 6px;
    border-radius: 2px;
    -webkit-border-radius: 2px;

    &.active {
      -webkit-box-shadow: none;
      box-shadow: none;
    }
    &:hover {
      background-color: @r42-grey-background-journey;
      font-weight: 700;
    }
  }

  .btn-switch-option {

    padding: 6px;
    border-radius: 2px;
    -webkit-border-radius: 2px;

    .option {
      color: @r42-blue-journey;

      &.static {
        color: @r42-blue-journey !important;
      }

      &.property {
        color: @r42-green-journey !important;
      }

      &.extracted {
        color: @r42-orange-journey !important;
      }

      &.variable {
        color: @r42-purple-step-journey !important;
      }
    }

    &.view {
      padding: 5px;
      .active;
    }

    &:hover {
      background-color: @r42-grey-background-journey;
      font-weight: 700;
      .option;
    }

    &.all-choosable {
      background-color: @r42-white;
      .option;
    }

    &.active {
      background-color: @r42-white;
      font-weight: 700;
      .option;
    }
  }

  .btn-switch-input {
    margin-top: 3px;
  }
}

.r42-ui .metainfo-bar {
  background: @r42-lightgrey-journey;
  padding: 15px 20px;
  margin-bottom: 10px;
  border-top: 1px solid #ddd;

  p {
    margin-bottom: 0;
  }

  .highlight {
    font-weight: 500;
    font-size: 1.1em;

    a {
      font-weight: 500;
    }
  }
}

.r42-ui .actions-container {
  text-align: right;
  padding-top: 5px;
  float: right;
}

.r42-ui button.close {
  font-size: 18px;
  opacity: .5;

  &:hover, &:focus {
    text-shadow: none;
  }
}

body.theme-r42green.error {
  background: #fff;

  .wrapper {

    .code {
      color: @r42-primaryColor;
    }

    .desc {
      color: @r42-primaryColor;
    }
  }
}

#busyIndicator {
  position: fixed;
  top: 0;
  left: 50%;

  margin-left: -70px;
  width: 120px;

  height: 20px;
  line-height: 20px;
  padding: 2px 10px;

  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  border-top: none;
  color: #222;

  z-index: 110;

  font-size: 13px;
  font-weight: 500;

  img {
    vertical-align: -3px;
    margin-right: 3px;
  }
}

.control-group {
  .controls {
    .controlsStatic {
      padding-top: 5px;
    }
  }
}

.control-group.not-visited {
  .control-label {
    color: inherit;
  }
  .controls {
    .help-after-visit, .error-after-visit {
      display: none;
    }

    input[type='text'], input[type='radio'], input[type='file'], input[type='checkbox'], select {
      color: inherit;
    }

    .input-prepend .add-on, .input-append .add-on {
      color: inherit;
      background-color: inherit;
    }
  }
}

/**
 *   Custom dataTable style
 */

.r42-ui {

  .table {
    thead tr th {
      background: none;
      border-top: none;
      border-bottom: 1px dashed @r42-grey-background-journey;
      text-align: left;
      text-transform: uppercase;
      color: @r42-grey-journey;
      font-weight: 500;
      line-height: 16px;
    }

    // All descendants should be center aligned
    thead, .text-center * {
      text-align: center
    }
    thead, .text-left {
      text-align: left;
    }

    tbody {
      tr {
        font-size: 14px;
        color: #171717;

        &:nth-child(odd) {
          background-color: @r42-lightergrey-journey;
        }

        &:hover > td {
          background-color: @r42-grey-background-journey;
        }
      }

      td {
        &.break-all-words {
          word-break: break-all;
        }
      }

      &.text-center {
        text-align: center
      }

      &.bordered {
        td {
          border: 1px solid @r42-grey-background-journey;
        }
      }
    }
  }

  .table-bordered thead th {
    border: none;
  }

  .dataTables_wrapper {
    margin-bottom: 20px;

    .dataTables_paginate {

      a {
        padding: 0;
        text-decoration: none;
      }

      .btn-table.btn-table-disabled {
        cursor: default;
        filter: alpha(opacity=50);
        opacity: 0.5;
      }

      .btn-table.btn-table-active {
        background-color: @r42-themeColor;
        color: #fff;
      }

      .btn-table {
        padding: 5px 9px;
        &:hover {
          background-color: @r42-themeColor;
          color: #fff;
        }
      }
    }
  }

  .table-pagination {
    .exportAction {
      float: right;
      margin-right: 5px;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .page-size {

      margin-left: 5px;
      margin-right: 5px;

      select {
        width: 60px;
      }
    }
  }

  .box-title .dataTables_options {
    float: right;

    .dataTables_filter {
      float: left;
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  .box-title.large-widget {
    // keep in mind that error message should still fit
    height: 50px;
    padding-bottom: 6px;

    span {
      font-size: 14px;
      font-weight: 500;
    }
  }
}

.box-spacing {
  margin-top: 15px;
  margin-bottom: 15px;
}

/**
 *   Custom Checkbox style
 */
.icheckbox_square {
  display: inline-block;
  margin: 0;
  padding: 0;
  width: 22px;
  height: 22px;
  background: url('../public/green.png') no-repeat;
  border: none;
  cursor: pointer;
  margin-bottom: -6px;
}

.icheckbox_square input[type='checkbox'] {
  display: none;
}

.icheckbox_square, .icheckbox_square.static:hover {
  background-position: 0 0;
}

.icheckbox_square:hover {
  background-position: -24px 0;
}

.icheckbox_square.checked-true {
  background-position: -48px 0;
}

.icheckbox_square.checked-false {
  background-position: -72px 0;
}

.icheckbox_square.disabled-true {
  background-position: -96px 0;
}

.box-right-button {
  float: right;
  margin-right: 10px;
}

*[data-ng-click] {
  cursor: pointer;
}

.box.box-bordered.box-color.lightgrey {
  .box-title {
    border-color: #666 !important;
    background: #666 !important;
  }
  .box-content {
    border-color: #666 !important;
  }
}

.ui-match {
  background-color: yellow;
}

.choices {

  .box-choice {
    padding: 15px;
    float: left;
    margin: 10px 10px 10px 0px;
    width: 180px;
    height: auto;
    font-size: 16px;
    text-align: center;
    border: 1px solid #ccc;

    &:hover {
      border: 1px solid #33b062;
    }

    .box-choice-image {
      text-align: center;
      height: 40px;
      vertical-align: middle;
      font-size: 30px;
      padding-top: 10px;
      padding-bottom: 5px;
    }

    .box-choice-tagline {
      font-size: 11px;
      color: #999;
    }

    i.disabled {
      color: black;
    }

    i.enabled {
      color: @r42-themeColor;
    }
  }
}

.big-choice {
  padding: 15px;
  margin: 16px auto;
  width: 710px;
  font-size: 25px;
  text-align: left;
  border: 1px solid #ccc;
  line-height: 28px;

  input[type="radio"] {
    margin-top: -3px;
    margin-right: 20px;
  }
}

.medium-choice {
  padding: 15px;
  margin: 16px auto;
  width: 450px;
  font-size: 25px;
  text-align: left;
  border: 1px solid #ccc;
  line-height: 28px;

  input[type="radio"] {
    margin-top: -3px;
    margin-right: 20px;
  }
}

.nav.nav-stacked {
  li {

    background-color: #fff;

    &.active {
      background: @r42-lightgrey-journey;

      &:after {
        top: 35%;
        left: 100%;
        content: " ";
        position: absolute;
        pointer-events: none;
        border: 6px solid transparent;
        z-index: 2;
        border-left: 8px solid @r42-lightgrey-journey;
      }
    }
  }
}

/**
 *   Generic checkbox with background in file @{index}.png (can be changed). The number of custom classes is defined at the end (loop of 3)
 */
.loop (@index) when (@index > 0) {
  .icheckbox_@{index} {
    background: url("../img/icheckbox/@{index}.png") no-repeat;
    display: inline-block;
    margin: 0;
    padding: 0;
    width: 22px;
    height: 22px;
    border: none;
    cursor: pointer;
    margin-bottom: -6px;
  }

  .icheckbox_@{index} input[type='checkbox'] {
    display: none;
  }

  .icheckbox_@{index}, .icheckbox_@{index}.static:hover {
    background-position: 0 0;
  }
  .icheckbox_@{index} {
    background-position: -24px 0;
  }
  .icheckbox_@{index}.checked-true {
    background-position: -48px 0;
  }
  .icheckbox_@{index}.checked-false {
    background-position: -72px 0;
  }
  .icheckbox_@{index}.disabled-true {
    background-position: -96px 0;
  }

  .loop (@index - 1);
}

.loop (0) {
}

.loop (3);

h3 i[class*="fa-"], h3 i[class*="r42-"] {
  color: @r42-themeColor;
}

.box .box-title .input-append {
  margin-bottom: 7px;
}

.r42-help-text {
  cursor: help;
}

//@todo marius: move to separate file when ready
.r42-ui .introjs-overlay {
  opacity: .4 !important;
}

.r42-ui .introjs-button {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.r42-ui *[data-flow-btn] input {
  display: none;
  top: -999px;
  right: -999px;
}

.r42-ui .ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
}

.r42-ui .ellipsis-50 {
  width: 50px;
}

.r42-ui .ellipsis-200 {
  width: 200px;
}

.r42-ui .ellipsis-400 {
  width: 400px;
}

.r42-ui .ellipsis-100percent {
  width: 100%;
}

.r42-ui .form-horizontal .control-label {
  padding-top: 5px;
  width: 160px;
  font-weight: 400;
}

.r42-ui .control-label-inline {
  display: inline-block;
}

.r42-ui .form-horizontal .controls {
  margin-left: 172px;
}

.r42-ui .label-default {
  border-radius: 2px;
  font-size: 11px;
  padding: 5px;
  cursor: pointer;
  color: @r42-charcoal-grey;
  background-color: @r42-grey-background-journey;
  border: 1px solid @r42-lightgrey;

  a {
    color: @r42-charcoal-grey;
  }

  &.disabled {
    background-color: @r42-lightgrey;
    color: @r42-darkgrey;
  }

}

.r42-ui .codemirror-relative-wrapper .dropdown-menu {
  left: 0;
  min-width: 153px;

  li {
    margin-left: 10px;
  }

  &.dropdown-primary {
    border-color: #e4e4e4;
    background-color: #e4e4e4
  }
}

.r42-ui .form-horizontal .form-actions {
  padding-left: 0;
}

.r42-ui input:focus:invalid:not(.InputElement), textarea:focus:invalid:not(.TextAreaElement), select:focus:invalid {
  border: 1px solid @r42-lightgrey;
  color: @r42-darkgrey;
  padding: 4px 6px;
}

.r42-ui .form-horizontal .help-block {
  font-style: italic;
}

.r42-ui [uib-datepicker-popup-wrap] {
  z-index: @r42-modal-zindex + 1;
}

.r42-ui .dropdown-menu {
  z-index: @r42-modal-zindex + 1;
}

.r42-ui .monospace {
  font-size: 1em;
  font-family: Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace;
}

.r42-ui .b {
  font-weight: 500;
}

.r42-ui .s {
  font-size: 0.95em;
}

.r42-icon-disabled {
  color: @r42-lightgrey;
}

@-moz-document url-prefix() {
  select {
    overflow: hidden;
  }
}

.r42-ui .password-input {
  input {
    width: 217px;
  }
  button.btn {
    width: 54px;
    background: @r42-themeColor-button-background;
    color: white;
    font-size: 14px;
    font-weight: 500;
    vertical-align: top;
    &:hover {
      background: @r42-themeColor-clearness;
    }
  }
}

.r42-ui select .default-select-opt {
  display: none;
}

.r42-ui .not-apply {
  color: @r42-lightgrey;
}

//Helpers classes
.r42-ui {
  .strong-disabled input {
    opacity: 0.9;
    color: @r42-medium-gray;
  }
  .icon-medium {
    font-size: 15px;
  }
}

.r42-ui .action-internal-button {
  background: @r42-action-button-color;
  color: white;
  font-size: 14px;
  font-weight: 500;
  &:hover {
    background: @r42-action-button-color-hover;
  }
}

.modal-customer-journey-quickview, .modal-customer-journey-edit {

  .modal-header {
    border: none;
  }

  .modal-footer {
    border-top: 1px dashed #e5e5e5;
    padding: 15px 0px 15px 0px;
  }

  .container-fluid {
    padding-left: 25px;
    padding-right: 25px;
  }

  .journey-quick-view {
    .header-container {
      padding-right: 0;
    }
  }
}

.journey-edit-view {
    .rule-time-block {
        border: 1px solid @r42-mediumgrey-journey;
        border-radius: 2px;
        padding: 3px 3px 3px 10px;
        margin-top: 6px;
        background-color: @r42-mediumgrey-journey;
        max-width: 160px;
        text-transform: uppercase;
        color: @r42-grey-journey;
        font-weight:500;
        &:hover {
            background-color: lighten(@r42-mediumgrey-journey,20);
        }
        &.inversed-rule {
            background-color: @r42-orange-journey;
            border-color: @r42-orange-journey;
            color: @r42-white;
            &:hover {
                background-color: lighten(@r42-orange-journey,20);
            }
        }

    }
    .top-border {
        &::before {
            content: '';
            display: block;
            width: 5%;
            border-top: 1px dashed #909497;
            margin-bottom: 20px;
        }
    }
    .bottom-border {
        &::after {
            content: '';
            display: block;
            width: 5%;
            border-top: 1px dashed #909497;
            margin-top: 20px;
        }
    }
}

//Same buttons style quick view and edit mode

.btn-large-container {
    .btn{
        padding: 10px 20px;
        font-size: 15px;

        &.btn-primary {
            font-weight: 500;
            margin-left: 5px;
            margin-right: 3px;
        }

        &.btn-secondary {
            background-color: transparent;
            color: #171717;

            &:hover {
                background-color: @r42-navgrey;
            }
        }
    }
}



#no-site-access {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0px;
  right: 0px;
  top: 0px;
}

.r42-ui .btn.btn-secondary.back-iplock {
  border: 1px solid @r42-mediumgrey-journey;
  background-color: @r42-white;
  margin-top: 0;
  margin-right: 0;
  padding: 10px 40px;
  font-size: 1.1em;
  font-weight: 500;
  opacity: 1;
  color: @r42-green-journey;
  float: right;
  font-weight: 500;
  &:hover {
    background-color: @r42-lightergrey-journey;
    color: @r42-themeColor-clearness;
  }
}

.r42-ui .smsCode {
  .col-md-6 {
    padding: 0;
  }
  input {
    padding: 5px;
  }
  button.btn {
    padding: 6px;
    margin-right: 0;
    &:first-child {
      margin-right: 10px;
    }
  }
}

.r42-ui .bold {
    font-weight: 500;
}


//Generic Media Queries

@media (max-width: 996px) {
    .journey{
        .header-journey {
            .right-menu {
                padding-right: 0;
                padding-left: 0;
                .publish {
                    padding-right: 0;
                    padding-left: 0;
                }
            }
        }
    }
}


@media (min-width: 768px) {
    .journey{
        .publish {
            padding-right: 0;
            padding-left: 0;
        }
        .right-menu {
            padding-right: 0;
        }
    }
    #navigation .main-nav>li>a {
        font-size: 14px;
        padding: 10px 8px !important;
    }
}
@media (min-width: 997px) {
    #navigation .main-nav>li>a {
        font-size: 14px;
    }
}

.Adform {
  &-segmentModal > .Modal-body {
    overflow: unset !important;
  }

  &-segmentField {
    padding-bottom: 12px;
  }

  &-SegmentContent .Element.Element--default {
    width: 300px;
  }
}

@import 'src/less/variables.less';

/* Datepicker style */

.r42-ui {

    .editable-datepicker {

        input {
            padding: 4px;
            width: 205px;
        }
    }

    .form-horizontal {
        .editable-datepicker {
            form {
                margin: 0px;
            }

            *[data-datetimepicker] {
                display: inline-block;
            }
        }
    }
}

.r42-ui *[uib-datepicker] {

    padding: 4px 4px;

    .btn-default {
        background: #fff;
        padding: 4px 2px 4px 2px;

        &.active {
            background: @r42-primaryColor;

            span {
                color: #fff;
            }
        }
    }

    table {
        min-width: 100%;

        thead {
            .pull-left, .pull-right {
                height: 27px;
                padding: 4px 11px 5px;
                text-align: center;
                vertical-align: middle;
                background-color:  @r42-primary-button-color;
                color: #fff;
                font-size: 13px;

                i {
                    vertical-align: baseline;
                }
            }

            .btn {
                border: none;
            }
        }

        th, td {
            padding: 4px 4px;
            width: 41px;
        }

        th {
            font-weight: bold;
            font-size: 14px;
            color: @r42-primaryColor;
            cursor: pointer;
        }

        td .btn {
            cursor: pointer;
            font-size: 12px;
            border: 1px solid #efefef;
            background-color: #fff;
            color: @r42-darkgrey;
            box-sizing: border-box;

            &:hover {
                background-color: @r42-element-hover;
            }

            span {
                text-align: center;
                font-weight: 500;

                &.text-muted {
                    font-weight: 100;
                    color: #ababab;
                }

                &.text-info {
                    color: @r42-themeColor;
                }
            }

            &.active {
                background-color: #fff;
                box-shadow: none;
                -webkit-box-shadow: none;
                span {
                    color: @r42-darkgrey;
                }
            }

            &.btn-info {
                background-color: @r42-primary-button-color;
                span {
                    color: #fff;
                }
            }
        }
    }

    .timepicker {
        margin-top: 10px;
        .timepicker-hour,.timepicker-minute {
            margin-left: 7px;
            text-align: center;
            font-weight: 600;
        }
    }
}

.r42-ui .dropdown-menu[uib-datepicker-popup-wrap] {
    z-index: 9999;
    border: 1px solid @r42-primaryColor;
    padding: 0;
}

.dropdown-menu {

    .btn-group {
        margin: 0px 7px 7px;
    }

    .btn {
        &.btn-info, &.btn-danger {
            background-color: @r42-secondary-button-color;
            color: @r42-darkgrey;
            &:hover {
                background-color: @r42-secondary-button-hover;
            }
        }

        &.btn-success{
            color: #fff;
            background-color: @r42-primary-button-color;
            margin-right: 7px;
        }
    }
}

@import 'src/less/variables.less';

.r42-ui {

	#pathSearch, #groupSearch {
        box-sizing: border-box;
        margin: 11px 0px 11px 0px;
        position: relative;
	}
}

.r42-ui h4 {
	display: inline-block;
}

.node-global {
	span.dynatree-icon {
		background-image: none;
	}
	span.dynatree-icon:before {
		content: "\f0ac";
		font-family: FontAwesome;
		font-size: 16px;
		margin-left: 1px;
	}
}

#tagTreeButtons {
	margin-bottom: 0;
}

.r42-ui .r42-box-navigation {

	&.box {

		padding: 0 10px 0 10px;

		.box-title {

			h3 > i[class*="r42-"] {
				color: #000;
			}

			.actions {
				margin: 5px 0 0 0;
			}
		}

		.box-content {

			margin-top: 0;
			padding: 0;
			padding-top: 5px;
		}
	}
}

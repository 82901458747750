@import 'src/less/variables.less';

.r42-ui .error-block {
	display: block;
}

.r42-ui .editable {

  .error-block {
	font-size: 14px;
  }

  &.editable-error-inline {
	.error-block {
	  display: inline-block;
	}
  }

}

.r42-ui .r42-click-to-edit {
	color: @r42-click-to-edit;
	text-decoration: none;
	font-weight: 400;
	font-style: italic;
	padding-top: 2px;
	padding-left: 2px;
	padding-right: 2px;
	padding-bottom: 1px;
	border-bottom: 1px dashed @r42-click-to-edit-border;

	&:hover {
		color: @r42-click-to-edit-hover;
		border-bottom: 1px dashed @r42-click-to-edit-hover;
	}

	&.r42-click-to-edit-n {
		font-style: normal;
	}

	&.r42-click-to-edit-l {
		color: @r42-lightgrey;
	}
}

.r42-ui .editable, .r42-ui .r42-form-element {

	cursor: pointer;

	.editable-submit, .editable-cancel {
        vertical-align: middle;
		margin-left: -1px;
		margin-right: 0px;
		border-radius: 2px;
		-webkit-border-radius: 2px;
	}

	&.editable-disabled {
		*[data-ng-click] {
			cursor: inherit;
		}
	}

	.editable-cancel {
		background-color: #eee;
	}

	&.editable-disabled[data-ng-click] {
		cursor: inherit;
	}

	&.view {

	}

	&.edit {

		input, textarea {
			font-weight: normal;
		}

		.control-group {
			margin: 1px 0 5px 0;
		}

		.form-actions {
			margin: 0;
			padding: 0;
		}
	}

	&.editable-inline {
		display: inline-block;
	}
}

.r42-ui .editable.editable-default {

	&.view {
		.editable-container {
			display: inline-block;
			text-align: left;
			padding-top: 2px;
			padding-bottom:2px;
			margin-left:0;

            .editable, .noValue {
				border-bottom: dashed 1px #ccc;
				&:hover {
					border-bottom: dashed 1px #666;
				}
			}
		}

		&.editable-small {
			min-width: 16px;

			.editable-container > span {
				min-width: 16px;
				display: inline-block;
			}
		}
	}

	&.edit {

		form.editableForm {
			margin: 0;
		}

		input {
			width: 250px;
			vertical-align: middle;
		}

		&.editable-small {
			input {
				width: 25px;
			}
		}

		&.editable-medium {
			input {
				width: 175px;
			}
		}

		&.editable-mini {
			input {
				width: 60px;
			}
		}

		&.editable-inline {
			span.error-block {
				display: inline-block;

				&.error-block-small {
					font-size: 13px;
				}
			}
		}
	}
}

.r42-ui .editable.editable-textarea {

	&.view {

		.editable-container {
			padding-bottom: 4px;

			span.editable, span.noValue {
				font-size: @r42-forms-font-size;

			}
		}
	}

	&.edit {

		textarea {
			height: 85px;
			color: rgb(119, 119, 119);
			padding: 0 2px 0 2px;
			margin:1px 0 0 0;
			border: 1px dashed #e4e4e4;
		}

		.form-actions {
			padding-left: 4px;
		}
	}
}

//
.r42-ui .editable.editable-input-group {

	.controls {
		font-size: @r42-forms-font-size;
		padding-top: 5px;
		padding-bottom: 2px;
		margin-left: 160px;
	}

	&.view {

		.editable-container .form-horizontal .control-group {
			margin: 1px 0 5px 0;
		}

		.editable {
			border: 1px solid white;
		}

		.controls {
		  	span {
                font-weight: 500;
                border-bottom: dashed 1px #ccc;
                text-align: left;
                color: @r42-grey-journey-goal;
                font-size: @r42-forms-font-size;
			  &:hover {
				border-bottom: dashed 1px #666;
			  }
			}
		}
	}

	&.edit {
		input {
			width: 220px;

			&.input-large {
				width: 300px;
			}

			&.input-xlarge {
				width: 350px;
			}
		}

		.form-actions {
			padding-left: 10px;
		}
	}
}

//
.r42-ui .editable.editable-select {

	&.view {

		.controls {
			display: inline-block;
			border-bottom: dashed 1px #ccc;
			font-size: @r42-forms-font-size;
			text-align: left;
			padding-top: 5px;
			padding-bottom:2px;
			margin-left: 5px;

		  	.editable-container {
			  display: inline-block;
			}

			&:hover {
				border-bottom: dashed 1px #666;
			}
		}
	}

	&.edit {
		input {
			min-width: 150px;
		}
	}
}

.r42-ui .editable.editable-datepicker {

	&.view {
		.editable-container-datepicker {
			display: inline-block;
			border-bottom: dashed 1px #ccc;
			text-align: left;
			padding-top: 5px;
			padding-bottom:2px;
			margin-left:0;
			&:hover {
				border-bottom: dashed 1px #666;
			}
		}
	}

	&.edit {

		.editable-submit, .editable-cancel {
			margin-left: 0px;
		}

	}
}

//@todo marius: needs correction from button (.btn) later on when we map all styles
.r42-ui .editable-refresh {
	margin-left: -9px;
}

.r42-ui .editable-form.ng-invalid .editable-submit {
	background-color: @r42-red-validation-text;
}

.r42-ui .editable-form.ng-valid.ng-dirty .editable-submit {
  background-color: @r42-primary-button-color;
}

@-webkit-keyframes bluePulse {
	from { background-color: @r42-primary-button-color; }
	50% { background-color: @r42-themeSupportColor;}
	to { background-color: @r42-primary-button-color;}
}

.r42-ui form.ng-dirty .editable-submit {
	animation-name: bluePulse;
	-webkit-animation-name: bluePulse;
	animation-duration: 0.3s;
	-webkit-animation-duration: 0.3s;
}

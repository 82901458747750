.ConnectorSection {
  &-heading {
    padding: 0;

    &--search {
      .SearchElement-input {
        padding: 6px;
        width: 260px;
      }
    }
  }
}

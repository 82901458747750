.DoubleClickOffline-floodlightModal.modal-content .Modal-body.modal-body {
  overflow: visible;
}

.DoubleClickOffline-floodlightFormContent {
  min-height: 500px;
  display: flex;
  flex-direction: column;
}

.DoubleClickOffline-floodlightFormContent .Element.Element--default {
  width: 300px;
}

.DoubleClickOffline-floodlightFormContent .DoubleClickOffline-addFloodlightActivityFormButtons {
  margin-top: auto;
}

.DoubleClickOffline-infoIcon {
  color: #a9a9a9;
  margin-left: 8px;
  transform: translateY(-2px);
}

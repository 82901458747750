@import 'src/less/variables.less';

.r42-ui {

    .optional-property-selection {
        &.panel {
            margin-bottom: 0px;
            background-color: #fff;
            border: 0px solid transparent;
            box-shadow: none;
        }

        .optional-property-header {
            padding-bottom: 18px;
            font-style: italic;
            font-weight: bold;
        }

        .optional-property-row {
            padding-bottom: 10px;
            display: flex; /* or inline-flex */
            flex-direction: row;
            align-items: flex-start;
            .property-name {
                flex-basis: 16.6%;
            }
            .remove-property {
                padding-top: 5px;
                flex-basis: 3.4%;
                margin-left: 25px;
            }
        }

        .optional-property-actions {
            padding-top: 20px;
            padding-bottom: 20px;

            .add-type-select {
                padding-right: 5px;
            }
        }

    }

    .editable-input-group {

        .control-group.optional-property-header {
            .controls {
                padding-top: 0px;
            }
        }

        .optional-property-selection {
            .add-property {
                margin-left: 5px;
            }
        }
    }
}

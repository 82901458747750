@import 'src/client/components/src/Common/variables.scss';
@import '../../../../theme/colors';

.AudienceActivity {
  &-heading {
    display: flex;
    width: 100%;

    &--data {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
    }
  }
  &-select {
    margin-left: auto;
    width: 200px;
  }

  &-tooltip {
    margin-right: auto;
    font-size: 0;
  }

  &-statsPanel {
    margin: 0;
  }

  &-dataExchange {
    &--header {
      font-size: var($fontSize, xl);
      font-weight: bold;
    }

    &--tabs {
      margin-bottom: 24px;
    }
  }

  &-generalData {
    width: 100%;
    display: grid;

    &--count {
      font-family: Roboto;
      font-size: 28px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: 1px;
      margin-bottom: 8px;
    }
  }
}

.AudienceActivity-tooltip .Icon {
  font-size: 0;
}

.graph-label {
  font-weight: bold;
}

.DataExchangeHeader {
  margin-top: 24px;
  margin-bottom: -24px;
}

.TotalSizeColor {
  color: #f5a623;
}

.AddedTodayColor {
  color: #199c49;
}

.RemovedTodayColor {
  color: #0054b0;
}

@media screen and (max-width: 600px) {
  .AudienceActivity {
    &-heading {
      &--data {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 24px;
      }
    }
  }
}

/**
 Breadcrumb Component
 */

.Breadcrumb {
  display: flex;
  align-items: center;
}

.Breadcrumb-list {
  list-style-type: none;
  display: flex;
  padding: 0;
  margin: 0;
}

.Breadcrumb-pointer {
  width: 10px;
  margin-right: 10px;
}

.Breadcrumb-fileName {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.8px;
  color: #444444;
}

.Breadcrumb-backToDirBtn {
  transition: 0.3s;
}

.Breadcrumb-backToDirBtn:hover {
  transform: scale(1.05);
}

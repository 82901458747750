@import 'src/less/variables.less';

//Style applied to dependency overview list for variables and interactions
.dependency-overview-list {

    display: flex;
    flex-direction: row;
    .wrapper-overview-table {
        flex-grow: 1;
    }

    .short-table {
        position: relative;
        z-index: 1;
        margin-right: 40px;
    }

    tbody tr td {
        border-top: 1px dashed @r42-medium-gray;
        cursor: pointer;
    }

    tbody tr.table-overview-content {
        height: 46px;

    }
    tbody tr.table-overview-content td.properties{
        padding-left: 50px;
    }
    tbody tr.selected td:not(.empty) {
        border-top: 1px solid #000;
        border-bottom: 1px solid #000;
        background-color: @r42-white;
    }

    td.empty {
        background-color: @r42-white;
    }

    .align-left {
        text-align: right;
    }

    .expand-overview {
        position: relative;
        width: 450px;
        display: flex;
        margin-left: 20px;
        margin-right: 20px;
    }

    .interaction-properties .usage-overview {
        width: 400px;
    }
}

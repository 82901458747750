.RuleCard {
  &-list {
    margin-top: 24px;
    display: grid;
    cursor: pointer;
    grid-template-columns: 1fr;
    grid-row-gap: 16px;
  }

  &-grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-column-gap: 24px;
    grid-row-gap: 24px;
  }

  &-search {
    margin: 14px 0 36px 0;
  }
}

.RuleCard-list,
.RuleCard-initialViewSearchResults {
  overflow-y: auto;
  padding-right: 2px;
  margin-right: -2px;
}

.RuleCard-initialViewSearchResults {
  flex: 1;
}

@import 'src/client/components/src/Common/variables.scss';

$checkbox: (
  background: var($colors, link),
  color: var($colors, white),
  curve: cubic-bezier(0.65, 0, 0.45, 1),
);

.Checkbox {
  cursor: pointer;
  position: relative;

  &.is-disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.55;
  }
}

.Checkbox-container {
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 24px;
}

.Checkbox-input {
  cursor: pointer;
  margin: 0;
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;

  &:hover ~ .Checkbox-element {
    border-color: #221f20;
  }

  &:focus ~ .Checkbox-element {
    outline: $outline;
  }

  &:hover:focus ~ .Checkbox-element {
    outline: none;
  }

  &:checked ~ .Checkbox-element {
    background: var($checkbox, background);
    border-color: var($checkbox, background);
    transition: background 0.4s ease-out;
  }

  &:checked ~ .Checkbox-svg {
    display: block;
  }
}

.Checkbox-element {
  left: 0;
  position: absolute;
  top: 0;
}

.Checkbox-svg {
  border-radius: 0.25rem;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  stroke-width: 4;
  stroke: var($checkbox, color);
  stroke-miterlimit: 10;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
}

.Checkbox-label {
  padding: 0 0 0 2.5rem;
}

.Checkbox-label-text {
  cursor: pointer;
}

.Checkbox-tick {
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.2s var($checkbox, curve) 0.4s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@import "src/less/variables.less";

.usage-overview {
    border: 1px solid #000;
    padding: 15px;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 164px;
    bottom: 20px;
    width: 450px;
    overflow: auto;
    .center-wrapper-content {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .nav-tabs {
        border-bottom: 0;

        li a {
            border: 0;

            &:hover {
                border: 0;
            }
        }

        li.active a {
            border: 0;
            border-bottom: 3px solid @r42-themeColor;
        }
    }
}

@import 'src/client/components/src/Common/variables.scss';

.Card {
  width: 100%;
  box-sizing: border-box;
  box-shadow: 0 0 2px 0 #cbd3e1;
  transition: all 0.3s ease-in-out;
  margin-bottom: 24px;

  &.is-active,
  &:hover {
    box-shadow: 0 2px 6px 0 #cbd3e1;
  }
}

@import 'src/client/components/src/Common/variables.scss';
@import './variables';

.Link {
  color: var($link, color);
  border: none;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    outline: $outline;
  }

  &--disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &--text {
    color: inherit !important;
  }
}

// Override old styles, remove when possible
.Link.Link {
  color: var($link, color);
}

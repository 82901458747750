.deduplicationCondition {
  margin-bottom: 20px;
  .markerNames {
	margin-left: 20px;
	width: 360px;
  }
}

.marker {

}

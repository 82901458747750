.ConnectorProperties-header {
  display: flex;
  padding-right: 36px;
  padding-bottom: 8px;
  border-bottom: 1px solid #eaeaea;
  font-weight: 500;
  margin-top: 28px;
}

.ConnectorProperties-propertyColumn {
  margin-right: 24px;
}

.ConnectorProperties-propertyColumn,
.ConnectorProperties-valueColumn {
  flex: 1;
}

.ConnectorProperties-addButton {
  font-size: 14px !important;
  max-width: 300px;
  margin-top: 16px;
  margin-right: 16px;
  font-weight: 500;
}

.ConnectorProperties-select {
  width: 100%;
}

.ConnectorProperties-addButton .Tooltip {
  font-size: 0;
}

.ConnectorProperties-plusIcon {
  margin-right: 16px;
  font-size: 0;
}

button.ConnectorProperties-addButton {
  margin-right: 24px;
}

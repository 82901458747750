@import 'src/client/components/src/Common/variables.scss';

.ConnectorCard {
  position: relative;
  background-color: white;
  padding: 20px;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 #cbd3e1;

  &-logo {
    width: 48px;
    min-width: 48px;
    margin-right: 16px;

    &--deactivated {
      -webkit-filter: grayscale(1);
      filter: grayscale(1);
    }
  }

  &-info {
    flex-grow: 1;

    &--name {
      font-size: 14px;
      line-height: 24px;
      font-weight: 500;
      letter-spacing: 0.8px;
      margin: 0;
    }

    &--number {
      font-size: 12px;
      line-height: 16px;
      color: #888888;
      margin: 0;
    }

    &--deactivated {
      & > p:first-child {
        color: #acacac !important;
        text-decoration: line-through;
      }
    }
  }

  &-supportIcon {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
}

.ConnectorCard-header {
  width: 100%;
  display: flex;
  align-items: center;
  flex: 1;
}

.ConnectorCard-properties {
  margin-top: 12px;
  padding-top: 20px;
  width: 100%;
  border-top: 1px solid #f4f4f4;
}

.ConnectorCard-table {
  width: 100%;
  border-top: none;
  border-collapse: collapse;
  margin: 0;
}

.ConnectorCard-tableCell {
  padding-bottom: 12px;
  padding-left: 36px;
  width: 50%;
  border-top: 1px solid #c5c5c5;
  padding-top: 12px;
}

.ConnectorCard-tableRow:first-child .ConnectorCard-tableCell {
  border: none;
  padding-top: 0;
}

.ConnectorCard-tableCell--name {
  font-weight: 500;
  padding-left: 0px;
}

.ConnectorCard-showMore {
  font-size: var($fontSize, default);
}

.ConnectorCard-actions {
  visibility: visible;
  transition: all 0.3s ease-in-out;
  position: absolute;
  right: 16px;
  top: 8px;
  & > *:not(:first-child) {
    margin-left: 8px;
  }
}

.ConnectorCard-actionIcon {
  color: #cccccc;
}

.ConnectorCard-removeCardButton:hover .ConnectorCard-actionIcon {
  color: #b7000a;
}

.DefaultPsuedoConnector {
  border: 1px solid #3ac97050;
  box-shadow: none;
  &:hover {
    box-shadow: none;
  }

  & > .ConnectorCard-header .ConnectorCard-info .ConnectorCard-name {
    color: #33b062;
    font-weight: 500;
  }
}

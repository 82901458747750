/*
 Position Utils
 */

.u-posAbsolute {
  position: absolute !important;
}

.u-posFixed {
  position: fixed !important;
}

.u-posRelative {
  position: relative !important;
}

.u-posStatic {
  position: static !important;
}

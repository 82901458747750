@import 'src/client/components/src/Common/variables.scss';

.Crumbs {
  display: flex;
  align-items: center;
}

.Crumbs-list {
  list-style: none;
  display: none;
  position: absolute;
  border-radius: 2px;
  background-color: var($colors, white);
  box-shadow: 1px 1px 4px 0 rgba(165, 165, 165, 0.5);
  padding: 15px;
  min-width: 165px;
  top: 30px;
}

.Crumbs-item {
  display: flex;
  align-items: flex-end;
  margin-bottom: 5px;
}

.Crumbs-link {
  color: #221f20;
  font-size: 14px;
  white-space: nowrap;
  text-decoration: none;
  line-height: 22px;
}

.Crumbs-link:hover {
  text-decoration: underline;
}

.Crumbs-triggerBtn {
  border: none;
  padding: 0 5px;
  cursor: pointer;
  margin-left: 2px;
  font-size: 19px;
}

.VariablesForm {
  display: flex;
  align-items: center;

  & > .FieldWrapper {
    width: 240px;
    margin-right: 8px;
    margin-top: 16px;

    & + .FieldWrapper {
      margin-top: 16px;
    }
  }
}

.t-variablesListTable {
  margin-bottom: 0 !important;
}

@media only screen and (max-width: 960px) {
  .VariablesForm {
    & > .FieldWrapper {
      width: 180px;
    }
  }
}

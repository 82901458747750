.JourneyDependancy-link {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  text-align: left;
}

.JourneyDependancy-list {
  margin: 0;
  padding: 0;
}

.r42-ui .JourneyDependancy-list {
  list-style-type: disc;
}

.JourneyDependancy-listItem + .JourneyDependancy-listItem {
  margin-top: 8px;
}

.JourneyDependancy-listItem:hover {
  text-decoration: underline;
  cursor: pointer;
}
